import { action, when } from 'mobx'
import { O } from '../common'
import * as mdl from '../model'
import { ItemStatus } from '../model'

interface Props {
	inst: mdl.Installation
	session: mdl.Session
}

export const setup = {

	create: (props: Props) => {
		O.onInit(mdl.Item, item => {
			item.initNew.reactOnce(() => { setLog(item.create, props) })
		})
	},

	update: (props: Props) => {
		O.onInit(mdl.Item, item => {
			item.recordUpdate.react(() => { setLog(item.update, props) })
		})
	},

}

function setLog(log: mdl.Log, { inst, session }: Props) {
	action(() => {
		log.date = new Date(Date.now())
		log.user = session.user
		log.installation = inst.item
	})()
}


