import { O } from '../../common'
import * as mdl from '../../model'
import { newItemsFromText } from '../import'
import { requestRecentItems } from './recentItems'

export const setup = {

	createItem: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.CreateItemView, view => {
			view.createItem.react(async (text, title) => {
				const newItems = await newItemsFromText(text, items)
				const item = newItems.length === 1 ? newItems[0] : items.createItem()
				if (newItems.length === 1) {
					if (title)
						item.props.set('title', title)
				} else {
					if (title)
						item.props.set('title', title)
					else
						item.props.set('text', 'text')
					await item.links.addNew(newItems)
				}
				await item.initNew()
				item.status = mdl.ItemStatus.level1
				await item.complete()
				view.item = item
			})
		})
	},

	recentItems: (props: { items: mdl.ItemManager, nav: mdl.Navigation }) => {
		O.onInit(mdl.CreateItemView, view => {
			view.recentItems = requestRecentItems(props)
		})
	},

}


