import { Item } from '../Item'
import { computed, observable, signal } from './common'
import { ItemView } from './ItemView'
import { combineSearchResults, searchItems, SearchResult } from './SearchView'

export class AddItemView extends ItemView {
	readonly key = 'add'
	@observable searchText = ''
	@observable searchResults: SearchResult[]
	@observable recentItems: Item[]
	@computed get linkCandidates() {
		const recentResults = searchItems(this.searchText, this.recentItems)
		return combineSearchResults(recentResults, this.searchResults)
			.filter(itm => itm !== this.item)
			.filter(itm => !this.item.links.available ||
				!this.item.links.available.find(ln => ln.item === itm))
	}
	onPaste = signal()
	addContact = signal()
	addNewItem = {
		start: signal<
			(templateId?: string, file?: File) => (void | Promise<void>)>(),
		end: signal<(newItemId: string) => void>()
	}
}
