import { O, reaction, when } from '../common'
import * as mdl from '../model'

export const setup = {

	boxes: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.Box, box => {
			when(() => box.item.isReady, () => {
				if (!box.item.isGenerated)
					boxes.setBox(box.item)
			})
		})
	},

	storages: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.BoxStorage, storage => {
			when(() => storage.item.isReady, () => {
				if (!storage.item.isGenerated)
					boxes.setStorage(storage)
			})
		})
	},

	newItems: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.Item, item => {
			item.initNew.reactOnce(() => {
				when(() => !!item.id, () => { items.items.set(item.id, item) })
			})
		})
	},

	cleanup: ({ items }: { items: mdl.ItemManager }) => {
		O.onDispose(mdl.Item, item => { items.items.delete(item.id) })
	},

	limit: ({ items, nav }: { items: mdl.ItemManager, nav: mdl.Navigation }) => {
		// limit cached items count (expect an average of 10 links per item)
		reaction(() => items.items.size > mdl.maxRecentItemCount * 10,
			cleanup => {
				if (!cleanup) return
				// determine the recent IDs from navigation
				const recentIds = {}
				for (const id of nav.recentIds) {
					recentIds[id] = 1
					if (items.items.has(id)) {
						const item = items.items.get(id)
						// include linked items
						if (item?.links)
							for (const ln of item.links)
								if (ln.item) recentIds[ln.item.id] = 1
					}
				}
				// remove not so recent items from cache
				const ids = []
				for (const id of items.items.keys())
					if (!(id in recentIds)) ids.push(id)
				for (const id of ids) items.items.delete(id)
			})
	},

	installation: ({ items, inst }:
		{ items: mdl.ItemManager, inst: mdl.Installation }) => {
		// add installation item to items cache
		when(() => !!inst.item, () => {
			items.items.set('installation', inst.item)
		})
	},

}

