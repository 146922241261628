import * as mdl from '../../model'
import { val, ValueTypes } from './common'

export const logTmpl = (log: mdl.Log) => ({
	date: val(log, 'date', ValueTypes.date),
	position: val(log, 'position'),
	userId: val(log, 'userId'),
	installationId: val(log, 'installationId'),
})

export const linkTmpl = (link: mdl.Link) => ({
	url: val(link, 'url'),
	name: val(link, 'name'),
	preview: val(link, 'preview'),
})
