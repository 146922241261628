import { O, when } from './common'
import { Item, ItemStatus } from './Item'
import { ItemFactory } from './ItemFactory'

export class ItemManager extends ItemFactory {

	/** 
	 * items cache 
	 * (directly accessed only in data/cache.ts)
	*/
	items = new Map<string, Item>()

	get missingItems() {
		return [...this.items.values()].filter(i => i.status === ItemStatus.missing)
	}

	/** item ID aliases */
	itemIdAliases: { [alias: string]: () => string } = {}

	/** get an item */
	getItem(id: string, cacheOnly = false): Item {
		if (id in this.itemIdAliases)
			id = this.itemIdAliases[id]()
		if (!id) {
			return null
		} else if (this.items.has(id)) {
			return this.items.get(id) as Item
		} else if (cacheOnly) {
			return null
		} else {
			const item = O.new(Item)
			item.id = id
			this.items.set(id, item)
			return item as Item
		}
	}

	/** request an item */
	requestItem(id: string): Item {
		const item = this.getItem(id)
		item?.request()
		return item
	}

	/** require a ready item */
	async requireItem(id: string): Promise<Item> {
		const item = this.requestItem(id)
		if (item)
			await when(() => item.isReady || item.isMissing)
		if (!item || item.isMissing)
			throw new Error(`Item ${id} not found!`)
		return item
	}

}
