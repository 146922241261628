import { O, Utils, when } from '../../common'
import * as mdl from '../../model'

export const setup = {

	fromLinks: ({ boxes, items }:
		{ boxes: mdl.BoxManager, items: mdl.ItemManager }) => {
		O.onInit(mdl.ViewItemView, async view => {
			await when(() => view.item?.isReady)
			const boxIdMap = Utils.arrayToObject(boxes.activeBoxes, Utils.toId)
			for (const storage of boxes.availableStorages) {
				storage.access.readFromLinks(view.item.id,
					storage.allBoxes.filter(b => b.id in boxIdMap).map(Utils.toId))
					.then(ids => {
						if (ids)
							view.addFromLinks(ids.map(id => items.requestItem(id)))
					})
			}
		})
	}

}
