import { httpClient, O, Utils } from '../../common'
import * as mdl from '../../model'
import { fontIcons } from './materialIcons.json'

export const setup = {

	open: () => {
		O.onInit(mdl.ImageCapture, editor => {
			// TODO: load on demand
			editor.fontIcons = fontIcons
		})
	},

}

export async function loadImage(url: string, asIcon: boolean,
	config: mdl.Config['api']): Promise<mdl.ImageValue> {
	if (!url)
		return
	if (url.startsWith('data:'))
		return asIcon ? { icon: url } : { image: url }
	const b = await httpClient().getBlob(url.startsWith('blob:') ? url :
		Utils.addUrlParams(config.services.proxy, { url }))
	const v: any = asIcon ? { icon: await Utils.blobToDataUrl(b) } : { image: b }
	if (!url.startsWith('blob:'))
		v.source = url
	return v
}
