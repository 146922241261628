import { Observer, observer } from 'mobx-react'
import * as React from 'react'
import { observable } from '../common'
import * as mdl from '../model'
import { Component, css, Icon } from './common'
import { Sortable } from './common/Sortable'
import { NavItemLink } from './NavItemLink'
import { ViewItemLink } from './ViewItemLink'

interface Props {
	view: mdl.ViewItemView
	context: {
		config: mdl.Config
		nav: mdl.Navigation
		ui: mdl.UserInterface
	}
}

@observer
export class ViewItemLinks extends Component<Props> {

	ref = React.createRef<HTMLDivElement>()
	sortable: Sortable
	@observable sorting = false
	@observable showFrom = false

	render() {
		const { view, view: { item, mode, boxMarks }, context } = this.props
		// TODO: build Sortable component
		if (this.sortable)
			this.sortable.disable(view.hasSelected || item.isReadOnly)
		const selecting = view.hasSelected && mode === 'move'
		return <div ref={this.ref}
			className={css('links items', { sorting: this.sorting, selecting })}>
			{item.links.available && item.links.available.map((lnk, idx) =>
				<Observer key={lnk.item.id}>{() => lnk.item.isReady ?
					<ViewItemLink link={lnk} previous={item.links.available[idx - 1]}
						view={view} context={context} /> :
					<div>
						<div className="loading">loading...</div>
					</div>
				}</Observer>
			)}
			{view.fromLinks && <Observer>{() => <>
				<div className="linkedFrom" onClick={this.toggleFrom}>
					<Icon uri={this.showFrom ? 'expand' : 'chevron_right'}
					/> linked from...</div>
				{this.showFrom && view.fromLinks.map(item => item &&
					<NavItemLink item={item} context={context} key={item.id}
						disabled={view.hasSelected} boxMarks={boxMarks} />
				)}
			</>}</Observer>}
		</div>
	}

	toggleFrom = () => { this.showFrom = !this.showFrom }

	componentDidMount() {
		this.sortable = new Sortable(this.ref.current, {
			setData: (data, idx) => {
				const { view: { item }, view } = this.props
				const len = item.links.available.length
				// TODO: allow to suppress sorting for certain elements
				if (idx === len) return
				const id = idx > len ? view.fromLinks[idx - len - 1].id :
					item.links.available[idx].item.id
				data.setData('text/plain', `Link in item ${item.id} to ${id}.`)
				data.setData('allsbe/link', JSON.stringify(
					{ itemId: item.id, linkItemId: id }))
			},
			onStart: () => {
				this.sorting = true
			},
			onEnd: (moved, startIdx, endIdx) => {
				const { view: { item }, view } = this.props
				const len = item.links.available.length
				if (startIdx < len) {
					if (moved) item.links.move(startIdx, endIdx)
					else view.select(item.links.available[startIdx])
				}
				else if (startIdx > len && moved && endIdx <= len) {
					item.links.add(view.fromLinks[startIdx - len - 1], endIdx)
				}
				this.sorting = false
			},
			disabled: this.props.view.hasSelected || this.props.view.item.isReadOnly
		})
	}

	componentWillUnmount() {
		if (this.sortable) {
			this.sortable.dispose()
			this.sortable = null
		}
	}
}

