import { action } from '../../../common'
import * as mdl from '../../../model'

export interface UrlInfo {
	title?: string
	url?: string
	description?: string
	icon?: string | { icon: string, source?: string }
	image?: string | { image: Blob, source?: string }
	type?: string
}

export namespace UrlInfo {
	export const parser: {
		[contentType: string]: (txt: string, ct: string, url: string) => UrlInfo
	} = {}
	export const interpret: ((obj: any) => UrlInfo | null)[] = []
}

export async function setUrlInfo(item: mdl.Item, info: UrlInfo,
	items: mdl.ItemManager, urlProp?: mdl.Property) {
	if (!urlProp)
		urlProp = item.props.findByType('url')
	action(() => {
		if (info.url)
			if (urlProp) urlProp.value = info.url
			else item.props.set('url', info.url, 'url')
		if (info.title) item.props.set('title', info.title)
		if (info.description)
			item.props.set('description', info.description, 'text')
		if (info.icon) item.props.set('icon', info.icon, 'icon')
		item.tmpls.add(items.getItem('url.tmpl'))
	})()
	if (info.image && !item.links
		.map(ln => ln.item.props.findByType<mdl.ImageValue>('image'))
		.find(p => p?.value?.['source'] === info.image['source'])) {
		const imgItem = await items.createNewItem()
		imgItem.props.set('image', info.image, 'image')
		imgItem.layoutId = 'image.layout'
		imgItem.tmpls.add(items.getItem('image.tmpl'))
		imgItem.status = mdl.ItemStatus.level1
		await imgItem.complete()
		const link = item.links.add(imgItem, 0)
		link.preview = 'full'
		imgItem.addToBoxesOf(item)
	}
}
