import { isObservableArray } from 'mobx'
import { Constructor } from './common'

type Handler<T = {}, A = any> = (obj: T, ...args: A[]) => void
type OptionalPromiseHandler<T = {}, A = any> =
	(obj: T, ...args: A[]) => void | Promise<void>
type HandlersMap = Map<Constructor<{}>, OptionalPromiseHandler[]>

function add<T>(handlersMap: HandlersMap, type: Constructor<T>,
	fn: Handler<T>) {
	let handlers: OptionalPromiseHandler<T>[]
	if (handlersMap.has(type)) {
		handlers = handlersMap.get(type)
	}
	else {
		handlers = []
		handlersMap.set(type, handlers)
	}
	handlers.push(fn)
	return () => {
		const idx = handlers.indexOf(fn)
		if (idx >= 0) handlers.splice(idx, 1)
	}
}

function call<T, A>(handlersMap: HandlersMap, obj: T, refType?: Constructor<T>,
	args?: A[]) {
	if (!obj) return
	// fire all the handlers along the inheritance axis
	const promises: Promise<void>[] = []
	let type = obj.constructor as Constructor<T>
	while (type) {
		if ((!refType || type === refType) && handlersMap.has(type)) {
			const handlers = handlersMap.get(type)
			for (const fn of handlers) {
				try {
					const p = args ? fn(obj, ...args) : fn(obj)
					if (p) promises.push(p.catch(err => {
						console.error(err)
						if (err.cause) console.info('Cause:', err.cause)
					}))
				}
				catch (err) {
					console.error(err)
					if (err.cause) console.info('Cause:', err.cause)
				}
			}
		}
		type = Object.getPrototypeOf(type)
	}
	return promises
}

export type ObjectState = 'init' | 'build' | 'built' | 'complete' | 'completed'
	| 'dispose' | 'delete'
const stateSym = Symbol('object state')
const stateOrder = ['init', 'build', 'built', 'complete', 'completed',
	'dispose', 'delete'] as ObjectState[]
const handlers = {
	init: new Map(), build: new Map(), built: new Map(),
	complete: new Map(), completed: new Map(),
	dispose: new Map(), delete: new Map(),
} as { [k in ObjectState]: HandlersMap }

export class O {
	private constructor() { }

	static is(obj: any, state: ObjectState) {
		return stateOrder.indexOf(obj[stateSym]) >= stateOrder.indexOf(state)
	}

	/** Init phase right after the object has been constructed.
	 * An entity object might have a temporary ID.
	 */

	static new<T>(type: Constructor<T>, ...args: any[]) {
		const obj = new type(...args)
		call(handlers.init, obj, null, args)
		obj[stateSym] = 'init'
		return obj
	}

	static onInit<T>(type: Constructor<T>, fn: Handler<T>) {
		return add(handlers.init, type, fn)
	}

	static init<T>(obj: T, refType?: Constructor<T>, ...args: any[]) {
		if (!obj || obj[stateSym]) return
		call(handlers.init, obj, refType, args)
		obj[stateSym] = 'init'
		return obj
	}

	/** Build phase with the object being initialized with the data
	 * directly available. New objects might still missing an ID.
	 */
	static onBuild<T>(type: Constructor<T>, fn: OptionalPromiseHandler<T>) {
		return add(handlers.build, type, fn)
	}

	/** After the build phase the object is initialized with the data
	 * directly available. New objects should have a valid ID.
	 */
	static afterBuilt<T>(type: Constructor<T>, fn: OptionalPromiseHandler<T>) {
		return add(handlers.built, type, fn)
	}

	static async build<T>(obj: T, refType?: Constructor<T>) {
		await fire('build', obj, refType)
		await fire('built', obj, refType)
		return obj
	}

	static async buildAll(obj: any) {
		await fireAll('build', obj)
		await fireAll('built', obj)
		return obj
	}

	/** Complete event after an existing or new object's data has been
		* completed (loaded or created). A valid ID is available.
		*/
	static onComplete<T>(type: Constructor<T>, fn: OptionalPromiseHandler<T>) {
		return add(handlers.complete, type, fn)
	}
	static afterCompleted<T>(type: Constructor<T>,
		fn: OptionalPromiseHandler<T>) {
		return add(handlers.completed, type, fn)
	}

	static async complete<T>(obj: T, refType?: Constructor<T>) {
		await fire('complete', obj, refType)
		await fire('completed', obj, refType)
		return obj
	}

	static async completeAll(obj: any) {
		await fireAll('complete', obj)
		await fireAll('completed', obj)
		return obj
	}

	/** Dispose event when an object is no longer needed in memory. The
		* data it represents might still be valid.
		*/
	static onDispose<T>(type: Constructor<T> | T, fn: Handler<T>) {
		if (typeof type !== 'function') {
			const obj = type
			const origFn = fn
			fn = (o, a) => { if (o === obj) origFn(o, a) }
			type = type.constructor as Constructor<T>
		}
		return add(handlers.dispose, type as Constructor<T>, fn)
	}

	static async dispose<T>(obj: T, refType?: Constructor<T>) {
		await fire('dispose', obj, refType)
		return obj
	}

	static async disposeAll(obj: any) {
		await fireAll('dispose', obj)
		return obj
	}

	/** Delete event when an object is deleted. The data it represents
		* should also be removed from storage (or marked deleted). A delete also
		* disposes an object from memory.
		*/
	static onDelete<T>(type: Constructor<T>, fn: Handler<T>) {
		return add(handlers.delete, type, fn)
	}

	static async delete<T>(obj: T, refType?: Constructor<T>) {
		await fire('dispose', obj, refType)
		await fire('delete', obj, refType)
		return obj
	}

	static async deleteAll(obj: any) {
		await fireAll('dispose', obj)
		await fireAll('delete', obj)
		return obj
	}

	static reset() {
		handlers.init.clear()
		handlers.build.clear()
		handlers.built.clear()
		handlers.complete.clear()
		handlers.completed.clear()
		handlers.dispose.clear()
		handlers.delete.clear()
	}
}

const disposeStateIdx = stateOrder.indexOf('dispose')

async function fire<T>(state: ObjectState, obj: T, refType?: Constructor<T>) {
	if (!obj) {
		console.trace(`Invalid fire ${state}!`)
		return
	}
	const stateIdx = stateOrder.indexOf(state)
	const objState = obj[stateSym]
	if (!objState && stateIdx < disposeStateIdx) {
		obj[stateSym] = 'init'
		call(handlers.init, obj)
	}
	const objStateIdx = objState ? stateOrder.indexOf(objState) : 0
	// allow to dispose/delete without build or complete phase
	const startStateIdx =
		stateIdx >= disposeStateIdx && objStateIdx < disposeStateIdx ?
			disposeStateIdx : objStateIdx + 1
	for (let s = startStateIdx; s <= stateIdx; ++s) {
		const st = stateOrder[s]
		obj[stateSym] = st
		const voidPromises = call(handlers[st], obj, refType)
		if (voidPromises && voidPromises.length > 0)
			await Promise.all(voidPromises)
	}
}

async function fireAll(state: ObjectState, obj: any) {
	const promises = _fireAll(state, obj, [])
	if (promises && promises.length > 0)
		await Promise.all(promises)
}

function _fireAll(state: ObjectState, obj: any, promises: Promise<void>[]) {
	if (Array.isArray(obj) || isObservableArray(obj))
		for (const e of obj) _fireAll(state, e, promises)
	else if (obj && typeof obj === 'object') {
		promises.push(fire(state, obj))
		for (const k in obj) _fireAll(state, obj[k], promises)
	}
	return promises
}

// @status class decorator instead of an explicit constructor
export function status<T extends { new(...args: any[]): {} }>(ctor: T) {
	return class extends ctor {
		constructor(...args) {
			super(...args)
			if (ctor === Object.getPrototypeOf(this.constructor))
				O.init(this)
		}
	}
}

