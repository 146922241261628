
import { Box, ItemData } from './Box'
import { action, computed, observable, searchText, signal, Utils, when }
	from './common'
import { Links } from './Links'
import { Log } from './Log'
import { Properties } from './Properties'
import { PropertyType, searchablePropertyTypes } from './Property'

export enum ItemStatus {
	initial, // initial state after construction
	request1, // request to load basic data (min. 'id', 'type')
	level1, // basic data is set (min. 'id', 'type', via create or request1)
	request2, // request to load all available data
	level2, // all available data set
	request3, // request extended data
	level3, // extended data is set
	error, // there has been an error retriving, setting data
	missing, // data has been tried to load, but has not been found
}

interface ItemRevData {
	rev?: number
	update?: { installationId: string }
	create?: { installationId: string }
}

export class Item {

	@observable id: string
	@observable rev = 0
	@observable.ref conflicts: any[]
	@observable.ref _info: any
	@observable layoutId: string
	@computed get layout() {
		return this.layoutId ?
			this.layoutId.substring(0, this.layoutId.indexOf('.')) :
			this.tmpls.map(t => t.item.layout).find(Utils.isTrue)
	}
	@computed get revFull() { return Item.toFullRev(this) }
	static toFullRev(data: ItemRevData) {
		return data.rev + '_' +
			(data.update?.installationId ?? data.create?.installationId ?? '')
	}
	/** Compare two full revisions. 
	 * 1: rev is newer than ref, 0: equal, -1 older.
	 * A null or undefined makes one older. Both null or undefined => 0. */
	static compareRev(rev: string | ItemRevData, ref: string | ItemRevData) {
		if (!rev)
			return !ref ? 0 : -1
		if (!ref)
			return 1
		const revA = typeof rev === 'string' ? parseInt(rev) : rev.rev ?? 0
		const revB = typeof ref === 'string' ? parseInt(ref) : ref.rev ?? 0
		if (revA > revB)
			return 1
		else if (revA < revB)
			return -1
		const instA = typeof rev === 'string' ?
			rev.substring(rev.indexOf('_') + 1) :
			rev.update?.installationId ?? rev.create?.installationId ?? ''
		const instB = typeof ref === 'string' ?
			ref.substring(ref.indexOf('_') + 1) :
			ref.update?.installationId ?? ref.create?.installationId ?? ''
		return instA.localeCompare(instB)
	}

	//#region properties

	props = new Properties(this)

	/** Text representing this item in a text search. */
	@computed get searchText() {
		return searchText(...this.props.asList
			.filter(p => p.type in searchablePropertyTypes)
			.map(p => p.value))
	}

	/** Text representing this item in a sorted label list or the browser 
	 * window title. */
	@computed get labelText() {
		return this.props.label?.stringValue ?? 'Item ' + this.id
	}

	//#endregion

	isGenerated = false
	create = new Log()
	update = new Log()
	recordUpdate = signal()
	@computed get lastModified() {
		return this.update && this.update.date ? this.update.date
			: this.create ? this.create.date : void 0
	}
	@computed get isReadOnly() {
		return this.isGenerated || !this.activeBoxes.find(b => b.isWriteAllowed)
	}
	/** Initialize a newly created item. */
	initNew = signal<() => void | Promise<void>>()
	delete = signal<() => void | Promise<void>>()
	/** Research additional information about this item. */
	research = signal<() => void | Promise<void>>()
	refresh = signal<() => void | Promise<void>>()

	get $debug() {
		const d = { id: this.id, rev: this.rev, status: ItemStatus[this.status] }
		for (const name of this.props.keys())
			d[name] = this.props.get(name).$debug.value
		if (!this.links.isEmpty)
			d['links'] = this.links.$debug
		if (!this.tmpls.isEmpty)
			d['tmpls'] = this.tmpls.$debug
		if (this.boxes.length > 0)
			d['boxes'] = this.boxes.reduce((d, b) => ({ ...d, [b.id]: b.label }), {})
		return d
	}


	//#region live-cycle

	@observable status = ItemStatus.initial
	@computed get isReady() {
		return this.status >= ItemStatus.level1 && this.status <= ItemStatus.level3
	}
	@computed get isCompleted() { return this.status == ItemStatus.level3 }
	@computed get isMissing() { return this.status === ItemStatus.missing }
	@computed get isFinished() { return this.status >= ItemStatus.level2 }
	request1 = signal<(item: Item) => (void | Promise<void>)>()
	async request() {
		if (this.status < ItemStatus.request1) {
			this.status = ItemStatus.request1
			await this.request1(this)
			if (this.status < ItemStatus.level1)
				this.status = ItemStatus.missing
		} else if (this.status < ItemStatus.level1) {
			await when(() => this.status >= ItemStatus.level1)
		}
	}
	request2 = signal<(item: Item) => (void | Promise<void>)>()
	observe = signal<(item: Item) => void>()
	request3 = signal<(item: Item) => (void | Promise<void>)>()
	async complete() {
		if (this.status < ItemStatus.level1)
			await this.request()
		if (this.status < ItemStatus.request2) {
			this.status = ItemStatus.request2
			await this.request2(this)
			this.status = ItemStatus.level2
		} else if (this.status < ItemStatus.level2) {
			await when(() => this.status >= ItemStatus.level2)
		}
		// TODO: impl and test idepotency
		if (this.status < ItemStatus.request3) {
			this.observe(this)
			this.status = ItemStatus.request3
			await this.request3(this)
			this.status = ItemStatus.level3
		} else if (this.status < ItemStatus.level3) {
			await when(() => this.status >= ItemStatus.level3)
		}
	}

	/** This item has changed since loaded. */
	@observable hasChanged = false
	@observable error: {
		message: string
		name?: string
		stack?: string[]
	}

	@action setError(err?: string | Error) {
		this.status = ItemStatus.error
		this.error = Utils.errorToJson(err)
	}

	build = signal<(data: ItemData, force?: boolean) => void>()
	// TODO: redesign
	/** allow to ignore changes for recordUpdate when set from source */
	settingData = false

	//#endregion

	//#region boxes

	@observable.shallow	readonly boxItems: Item[] = []
	@computed get boxes() {
		return this.boxItems.map(Box.getBox).filter(Utils.isTrue)
	}
	@computed get activeBoxes() { return this.boxes.filter(b => b.isActive) }
	isInBox(box: Box | Item) {
		return this.boxItems.includes(box instanceof Box ? box.item : box)
	}

	/** Add this item to an active box. This item should be complete! */
	@action addToBox(box: Box | Item) {
		if (box instanceof Box) box = box.item
		if (box && !this.isGenerated && !this.boxItems.includes(box))
			this.boxItems.push(box)
	}

	/** Remove this item from an active box. */
	@action removeFromBox(box: Box | Item) {
		if (this.boxItems.length <= 1)
			// this item must be at least in one box
			return false
		if (box instanceof Item) box = Box.getBox(box)
		if (!box.isActive)
			// cannot remove from inactive box
			return false
		const idx = this.boxItems.indexOf(box.item)
		if (idx >= 0)
			this.boxItems.splice(idx, 1)
		return idx >= 0
	}

	/** Add this item to all the boxes of a given item.
	 * 
	 * Optionally add linked items as well (deep). No circular links supported!
	 */
	@action addToBoxesOf(srcItem: Item, deep = false) {
		for (const boxItem of srcItem.boxItems)
			this.addToBox(boxItem)
		if (deep && this.links)
			for (const ln of this.links)
				ln.item.addToBoxesOf(srcItem, true)
	}

	//#endregion

	links = new Links(this)
	tmpls = new Links(this)

	selectRev = signal<(rev: string) => void>()

	// find related
	// TODO: 2nd level linked items? 3rd,...?
	// TODO: from links?
	// TODO: own props?
	// TODO: find multiple typed props?
	/** Find the first related item with a property of a given type and return
	 * it's value.
	 */
	findRelatedPropertyValue<T = any>(type: PropertyType): T | null {
		if (!this.links)
			return null
		const ln = this.links.find(ln => !!ln.item.props.findByType(type))
		return ln?.item.props.findByType(type).value ?? null
	}

	/** Find related items with a property of a given type and return 
	 * their values.
	 */
	findRelatedPropertyValues<T = any>(type: PropertyType): T[] {
		if (!this.links)
			return []
		return this.links.map(ln => ln.item.props.findByType(type)?.value)
			.filter(Utils.isTrue)
	}
}
