import { O } from '../common'
import * as mdl from '../model'
import { ItemStatus } from '../model'

export const setup = {

	selectDate: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.SelectDateAction, act => {
			act.start.react(async (date: Date) => {
				const item = mdl.CalendarLayout.findDateItem(act.item, date)
				if (item) {
					act.end(item.id)
				} else {
					const newItem = await items.createNewItem(['date', date, 'date'])
					newItem.status = ItemStatus.level2
					await newItem.complete()
					act.item.links.add(newItem)
					newItem.addToBoxesOf(act.item, true)
					act.end(newItem.id)
				}
			})
		})
	}
}
