import * as mdl from '../../../model'

export const textImporters:
	((txt: string, items: mdl.ItemManager) => mdl.Item[])[] = []

export function newItemsFromText(txt: string, items: mdl.ItemManager,
	type?: string) {
	txt = txt.trim()
	for (const importer of textImporters) {
		const newItems = importer(txt, items)
		if (newItems)
			return newItems
	}
	// default text item
	const item = items.createItem()
	if (txt.length > 32) {
		item.props.set('text', txt, 'text')
		item.tmpls.add(items.getItem('text.tmpl'))
	} else {
		item.props.set('title', txt)
		item.tmpls.add(items.getItem('list.tmpl'))
	}
	return [item]
}
