import * as React from 'react'
import { Icon } from '../common'
import { IconEditor } from '../common/ImageEditor/ImageEditor'
import * as mdl from '../../model'
import { PropertyView } from './common'

export const icon: PropertyView<mdl.ImageValue> = {
	label: () => <>icon</>,
	icon: ({ value }) => <Icon uri={mdl.ImageValue.url(value)}
		className={mdl.ImageValue.className(value)} />,
	editor: (prop, form, { config }) =>
		<IconEditor label={prop.label} url={form.values[prop.name]}
			query={prop.item.props.label?.value} config={config} />
}

