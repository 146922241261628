import * as React from 'react'
import * as mdl from '../../model'
import { CancelClick, Icon, Observer } from '../common'
import { ItemLabel } from '../LayoutViews'
import { PropLabel } from '../PropertyViews'
import { Layout } from './common'

export const todo: Layout = {
	icon: item =>
		<Icon uri={mdl.TodoLayout.getIconUri(item)}
			onClick={mdl.TodoLayout.getToggle(item)} />,
	links: {
		small: (item, context) =>
			<div><ItemLabel item={item} context={context} /></div>,
		compact: (item, context) => <>
			<CancelClick>
				<Icon uri={mdl.TodoLayout.getIconUri(item)}
					onClick={mdl.TodoLayout.getToggle(item)} />
			</CancelClick>
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
			</div>
		</>,
		normal: (item, context) => <>
			<CancelClick>
				<Observer>{() =>
					<Icon uri={mdl.TodoLayout.getIconUri(item)}
						onClick={mdl.TodoLayout.getToggle(item)} />
				}</Observer>
			</CancelClick>
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
				{mdl.TodoLayout.getContentProps(item).slice(0, 1).map(p =>
					<PropLabel key={p.name} prop={p} context={context} />)}
			</div>
		</>,
		extended: (item, context) => <>
			<CancelClick>
				<Icon uri={mdl.TodoLayout.getIconUri(item)}
					onClick={mdl.TodoLayout.getToggle(item)} />
			</CancelClick>
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
				{mdl.TodoLayout.getContentProps(item).slice(0, 3).map(p =>
					<PropLabel key={p.name} prop={p} context={context} />)}
			</div>
		</>,
		full: (item, context) => <>
			<CancelClick>
				<Icon uri={mdl.TodoLayout.getIconUri(item)}
					onClick={mdl.TodoLayout.getToggle(item)} />
			</CancelClick>
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
				{mdl.TodoLayout.getContentProps(item).map(p =>
					<PropLabel key={p.name} prop={p} context={context} />)}
			</div>
		</>,
	},
}
