import { setInterval } from 'timers'
import { Dom } from './base'

export interface SortableOptions {
	setData(data: DataTransfer, itemIdx: number): void
	onStart(): void
	onEnd(moved: boolean, startIdx: number, endIdx: number): void
	disabled?: boolean
}

export class Sortable {

	moved: boolean
	touchStart: number[]
	longTouch: boolean
	touchTimer: NodeJS.Timeout
	item: HTMLElement
	ghost: HTMLElement
	startIdx: number
	// move and auto-scroll
	posY: number
	autoScrollTimer: NodeJS.Timeout
	isDisabled: boolean

	constructor(public container: HTMLElement, private opts: SortableOptions) {
		this.disable(opts.disabled)
	}

	onMouseDown = (evn: MouseEvent) => {
		if (evn.button !== 0) return
		const e = getChild(this.container, evn.target) as HTMLElement
		e.draggable = true
		this.moved = false
		e.addEventListener('dragstart', this.onDragStart)
		e.addEventListener('dragend', this.onDragEnd)
		this.container.addEventListener('dragover', this.onDragOver)
		this.container.addEventListener('click', this.onMouseUp)
		this.item = getChild(this.container, evn.target)
		this.longTouch = false
		clearTimeout(this.touchTimer)
		this.touchTimer = setTimeout(this.onLongPress, 500)
	}

	onMouseUp = (evn: MouseEvent) => {
		//	console.log('mouse up', this.longTouch)
		if (this.longTouch) {
			this.endDrag(this.item)
			evn.preventDefault()
			evn.stopImmediatePropagation()
		}
		clearTimeout(this.touchTimer)
		this.touchTimer = null
		this.longTouch = false
		this.moved = false
		this.container.removeEventListener('click', this.onMouseUp)
	}

	onLongPress = () => {
		//console.log('touch long', this.moved)
		this.longTouch = true
		this.startDrag(this.item)
	}

	onDragStart = (evn: DragEvent) => {
		//	console.log('drag start')
		this.startDrag(this.item, evn.dataTransfer)
	}

	onDragOver = (evn: DragEvent) => {
		this.moved = true
		clearTimeout(this.touchTimer)
		this.touchTimer = null
		//		//console.log('drag over', evn.clientY)
		this.posY = evn.clientY
		if (!this.autoScrollTimer) this.move()
		evn.preventDefault()
	}

	onDragEnd = (evn: DragEvent) => {
		//console.log('drag end', evn)
		const e = getChild(this.container, evn.target) as HTMLElement
		if (evn.dataTransfer.dropEffect === 'none') {
			// drag canceled
			const p = e.parentNode
			const idx = [...p.children].indexOf(e)
			if (idx !== this.startIdx) {
				const refIdx = this.startIdx + (idx < this.startIdx ? 1 : 0)
				p.insertBefore(e, p.children.item(refIdx))
			}
		}
		this.endDrag(e)
		this.moved = false
		e.removeEventListener('dragstart', this.onDragStart)
		e.removeEventListener('dragend', this.onDragEnd)
		this.container.removeEventListener('dragover', this.onDragOver)
	}

	onTouchStart = (evn: TouchEvent) => {
		const t = evn.touches[0]
		this.moved = false
		this.touchStart = [t.clientX, t.clientY]
		this.longTouch = false
		this.item = getChild(this.container, evn.target) as HTMLElement
		clearTimeout(this.touchTimer)
		this.touchTimer = setTimeout(this.onLongTouch, 500)
		//console.log('touch start')
	}

	onTouchMove = (evn: TouchEvent) => {
		const t = evn.changedTouches[0]
		if (this.longTouch) {
			if (evn.cancelable)
				evn.preventDefault()
			this.posY = t.clientY
			if (!this.autoScrollTimer) this.move()
			const x = t.clientX
			const y = t.clientY
			this.ghost.style.transform = `translate3d(${x}px, ${y}px, 0)`
		}
		if (!this.moved) {
			//console.log('touch move', this.touchStart, [t.clientX, t.clientY])
			const x = t.clientX - this.touchStart[0]
			const y = t.clientY - this.touchStart[1]
			if (Math.abs(x) > 2 || Math.abs(y) > 2) {
				this.moved = true
				clearTimeout(this.touchTimer)
				this.touchTimer = null
			}
		}
	}

	onTouchEnd = (evn: TouchEvent) => {
		//console.log('touch end')
		if (this.ghost) {
			this.ghost.parentNode.removeChild(this.ghost)
			this.ghost = null
			const e = this.item
			this.endDrag(e)
			evn.preventDefault()
		}
		this.moved = false
		clearTimeout(this.touchTimer)
		this.touchTimer = null
		this.longTouch = false
		delete this.touchStart
	}

	onLongTouch = () => {
		//console.log('touch long', this.moved)
		this.longTouch = true
		const e = this.item
		this.ghost = e.cloneNode(true) as HTMLElement
		const { style } = this.ghost
		style.position = 'fixed'
		style.top = (Dom.top(e) - window.scrollY - this.touchStart[1]) + 'px'
		style.left = (Dom.left(e) - window.scrollX - this.touchStart[0]) + 'px'
		style.width = e.offsetWidth + 'px'
		style.height = e.offsetHeight + 'px'
		style.backgroundColor = '#d3e9f9'
		e.parentNode.appendChild(this.ghost)
		this.startDrag(e)
	}

	onContextMenu = (evn: MouseEvent) => {
		if (this.touchStart) evn.preventDefault()
	}

	private startDrag(e: HTMLElement, dataTransfer?: DataTransfer) {
		e.classList.add('sortable-chosen')
		e.style.backgroundColor = '#d3e9f9'
		this.startIdx = [...e.parentNode.children].indexOf(e)
		if (dataTransfer) this.opts.setData(dataTransfer, this.startIdx)
		this.opts.onStart()
	}

	private endDrag(e: HTMLElement) {
		e.classList.remove('sortable-chosen')
		e.style.backgroundColor = null
		this.opts.onEnd(this.moved, this.startIdx,
			[...e.parentNode.children].indexOf(e))
	}

	private move = () => {
		this.autoScrollTimer = null
		const step = this.posY < 100 ? this.posY - 100
			: this.posY > window.innerHeight - 100
				? this.posY - window.innerHeight + 100 : 0
		if (step !== 0) {
			// TODO: 1px steps with animationFrames...
			window.scrollBy(0, step / 10)
			if ((step < 0 && window.scrollY > 0) ||
				(step > 0 && window.scrollY < document.documentElement.scrollHeight))
				this.autoScrollTimer = setTimeout(this.move, 10)
		}
		const p = this.item.parentNode
		const ps = this.item.previousElementSibling as HTMLElement
		if (ps) {
			const m = Dom.top(ps) - window.scrollY + ps.offsetHeight / 2
			if (this.posY < m) {
				p.insertBefore(this.item, ps)
				if (this.touchStart) this.touchStart[1] -= ps.offsetHeight
			}
		}
		const ns = this.item.nextElementSibling as HTMLElement
		if (ns && ns !== this.ghost) {
			const m = Dom.top(ns) - window.scrollY + ns.offsetHeight / 2
			if (this.posY > m) {
				p.insertBefore(ns, this.item)
				if (this.touchStart) this.touchStart[1] += ns.offsetHeight
			}
		}
	}

	disable(v = true) {
		if (this.isDisabled === v) return
		this.isDisabled = v
		if (v) {
			this.container.removeEventListener('mousedown', this.onMouseDown)
			this.container.removeEventListener('contextmenu', this.onContextMenu)
			this.container.removeEventListener('touchstart', this.onTouchStart)
			this.container.removeEventListener('touchmove', this.onTouchMove)
			this.container.removeEventListener('touchend', this.onTouchEnd)
		}
		else {
			this.container.addEventListener('mousedown', this.onMouseDown)
			this.container.addEventListener('contextmenu', this.onContextMenu)
			this.container.addEventListener('touchstart', this.onTouchStart,
				{ passive: true })
			this.container.addEventListener('touchmove', this.onTouchMove,
				{ passive: false })
			this.container.addEventListener('touchend', this.onTouchEnd)
		}
	}

	dispose() {
		this.disable(true)
	}

}


function getChild(container: Node, descendant: any) {
	let child = descendant
	if (descendant instanceof Node)
		while (child && child.parentNode !== container) child = child.parentNode
	return child
}
