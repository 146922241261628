import { IGNORE_ERROR } from '../../common'
import * as mdl from '../../model'

export function requestRecentItems({ items, nav }:
	{ items: mdl.ItemManager; nav: mdl.Navigation; }) {
	const resources = nav.recentIds.map(id => items.requestItem(id))
	for (const r of resources)
		r.complete().catch(IGNORE_ERROR)
	return resources
}
