import { Utils } from '../../common'
import { Item, ItemStatus } from '../Item'
import { computed, observable, ViewBase } from './common'

export interface SearchResult {
	item: Item
	score: number
}

export class SearchView extends ViewBase {
	readonly key = 'search'
	@computed get isReady() { return !!this.recentItems }
	@computed get label() { return 'Search' }
	@observable searchText = ''
	@observable searchResults: SearchResult[]
	@observable recentItems: Item[]
	@computed get results() {
		const recentResults = searchItems(this.searchText, this.recentItems)
		return combineSearchResults(recentResults, this.searchResults)
	}
}

export function searchItems(q: string, items: Item[]) {
	if (!items) return []
	if (!q) return items
	const txt = q.toLowerCase()
	return items
		.filter(itm => itm.searchText.toLowerCase().includes(txt))
}

export function combineSearchResults(recentResults: Item[],
	searchResults: SearchResult[]) {
	return searchResults && recentResults ?
		recentResults.concat(searchResults.map(r => r.item))
			.filter(Utils.distinct)
		: recentResults
}
