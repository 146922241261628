import { action, O, observable, signal, Utils } from './common'
import { getViewType, View, ViewKey } from './View'

// TODO: config
export const maxRecentItemCount = 100

export interface NavData {
	key?: ViewKey
	id?: string
	params?: {
		boxUrl?: string
		[k: string]: string
	}
}

const plainItemIdPattern = /^\w+$/
const idAliases = ['installation', 'home']
export const nonItemViewKeys = ['create', 'search', 'login', 'accounts']

export class Navigation {
	location: NavData = observable({ id: null, key: null, params: null })
	@observable view: View
	back = signal<(steps?: any) => void>()
	forward = signal<(steps?: any) => void>()
	reset = signal<(steps?: any) => void>()

	@observable homeItemId: string

	@observable hasHistory = true

	@observable recentIds: string[] = []
	@action addRecentId(id: string) {
		if (id && plainItemIdPattern.test(id) && idAliases.indexOf(id) < 0)
			Utils.addMoveFirst(this.recentIds, id, maxRecentItemCount)
	}
	@action removeRecentId(id: string) {
		const idx = this.recentIds.indexOf(id)
		if (idx >= 0) this.recentIds.splice(idx, 1)
	}

	goNext: NavData

	go(key: ViewKey, id?: string): void
	go(loc: NavData): void
	go(arg0: ViewKey | NavData, id?: string): void {
		let key: ViewKey = typeof arg0 === 'object' ? arg0.key : arg0
		if (!key) key = 'view'
		if (!id && typeof arg0 === 'object') id = arg0.id
		if (!id) id = this.location.id
		if (!id || id === 'home') id = this.homeItemId
		if (!id && this.recentIds.length > 0) id = this.recentIds[0]
		if (nonItemViewKeys.indexOf(key) >= 0) id = null
		if (this.location && key === this.location.key && id === this.location.id)
			return
		if (!id && key === 'view')
			key = 'search'
		const viewType = getViewType(key)
		// reuse ViewItem view for move key, create new ones for others
		const view = (key === 'view' || key === 'move') &&
			this.view && this.view.key === 'view' && id === this.location.id ?
			this.view : O.new(viewType, id)
		// TODO: after replaces (add, move, create (see BrowserHistory.ts)), check
		// the previous entry and signal back instead of go, when identical.
		action(`${key} ${id}`, () => {
			this.location.key = key
			this.location.id = id
			this.location.params = typeof arg0 === 'object' ? arg0.params : void 0
			this.view = view
			if ('configure' in view) view.configure(this.location)
			// remember recent item IDs
			this.addRecentId(id)
			this.hasHistory = true
		})()
	}
}
