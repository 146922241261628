import {
	action, computed, isObservableProp, observable, signal, Utils
} from '../common'

// Forms with validation, submit or reset features.

export const convert = {
	number: {
		in: (v: number) => v !== void 0 && v !== null && v !== NaN ? '' + v : '',
		out: parseFloat
	},
	object: {
		in: Utils.enumerateMembers, out: (str: string) => Utils.parseMembers(str)
	},
}

function convertIn(value: any): string {
	return value !== void 0 && value !== null
		? Utils.isDate(value) ? value.toISOString().substring(0, 19)
			: value.toString() : ''
}

function convertOut(str: string, baseValue: any): any {
	const t = typeof baseValue
	if (t === 'number' && Number.isInteger(baseValue as any))
		return str ? parseInt(str) as any : void 0
	if (t === 'number')
		return str ? parseFloat(str) as any : void 0
	if (t === 'boolean')
		return (str == '1' || str === 'true' ||
			str === 'yes' || str === 'on') as any
	if (baseValue instanceof Date)
		return new Date(str)
	return str
}

const defaultConvert = { in: convertIn, out: convertOut }

export class FormValue<M = any, K extends keyof M = keyof M> {
	constructor(private model: M, private key: K,
		public convert = defaultConvert) { }

	@observable.ref private current: string | Object
	@observable.ref private previous: string | Object
	private validators: ((val: M[K], prev: M[K]) => string)[] = []
	private _autoSubmit = false
	private _submitted = false

	/** The current string value. */
	@computed get value() {
		if (this.current !== void 0) return '' + this.current
		const v = this.model[this.key]
		return this.convert.in(v)
	}
	set value(val) {
		const v = this.model[this.key]
		const original = this.convert.in(v)
		this.previous = this.current
		this.current = val == original ? void 0 : val
		this._doAutoSubmit()
	}

	/** The current value in the type of the model value. */
	@computed get val(): M[K] {
		const original = this.model[this.key]
		return this.current === void 0 ? original
			: this.current instanceof Object ? this.current
				: this.convert.out(this.value, original)
	}
	set val(v) {
		this.previous = this.current
		const original = this.model[this.key]
		this.current = v instanceof Object ?
			v === original ? void 0 : v :
			v == original ? void 0 : this.convert.in(v)
		this._doAutoSubmit()
	}

	@computed get prev(): M[K] {
		const original = this.model[this.key]
		return this.previous !== void 0 && !(this.previous instanceof Object) ?
			this.convert.out(this.previous, original) : this.previous
	}

	@computed get isDirty() {
		return this.current !== void 0
	}

	validate(fn: (val: M[K], prev: M[K]) => string) {
		this.validators.push(fn)
	}

	@computed get errorMessages() {
		return this._submitted ? this.errors : []
	}

	@computed private get errors() {
		return this.validators.map(fn => fn(this.val, this.prev))
			.filter(Utils.isTrue)
	}

	@computed get hasErrors() {
		return this.errors.length > 0
	}

	@action submit() {
		this.onSubmit()
		if (this.current !== void 0) this.model[this.key] = this.val
		this.previous = void 0
		this.current = void 0
		this._submitted = true
	}
	onSubmit = signal()

	autoSubmit(v: boolean): this
	autoSubmit(): boolean
	autoSubmit(v?: boolean) {
		if (v === void 0) return this._autoSubmit
		this._autoSubmit = v
		return this
	}
	private _timer: any
	private _doAutoSubmit() {
		if (!this._autoSubmit) return
		clearTimeout(this._timer)
		this._timer = setTimeout(() => { this.submit() }, 200)
	}

	@action reset() {
		this.previous = this.current
		this.current = void 0
		this._submitted = false
	}

	isSource: boolean
}

function filterDeep(obj: any, fn: (member) => boolean) {
	return _filterDeep(obj, fn, [])
}

function _filterDeep(obj, fn: (member) => boolean, members: any[]) {
	for (let k in obj) {
		const v = obj[k]
		if (fn(v)) members.push(v)
		else _filterDeep(v, fn, members)
	}
	return members
}

export type FormValues<M = any, K extends keyof M = keyof M> =
	{ [n: string]: FormValue<M, K> | FormValues<M, K> }

export class Form<T = FormValues> {

	private flatValues = filterDeep(this.values,
		val => val instanceof FormValue) as FormValue[]

	constructor(public values: T) { }

	@computed get isDirty() {
		return this.flatValues.find(v => v.isDirty) !== void 0
	}

	@computed get errorMessages() {
		return this.flatValues.reduce((p, v) => p.concat(v.errorMessages), [])
	}

	@computed get hasErrors() {
		return this.flatValues.find(v => v.hasErrors) !== void 0
	}

	submit = signal<() => void>(action(() => {
		this.flatValues.forEach(v => v.submit())
	}))

	@action reset = () => {
		this.flatValues.forEach(v => v.reset())
	}

	@action setValues = (data: { [k: string]: string }) => {
		for (const k of Object.keys(data))
			if (k in this.values) this.values[k].value = data[k]
	}

	autoSubmit(v: boolean): this
	autoSubmit(): boolean
	autoSubmit(v?: boolean) {
		if (v === void 0) return !!this.flatValues.find(v => v.autoSubmit())
		this.flatValues.forEach(val => val.autoSubmit(v))
		return this
	}
}

// single form value
export function formValue<M, K extends keyof M>(model: M, key: K,
	convert = defaultConvert) {
	return new FormValue(model, key, convert)
}

// turn all or some observable members into form values
export function formValues<M, K extends keyof M>(
	model: M, keys?: { [k in K]?: typeof defaultConvert | 1 }) {
	const vals = {}
	if (keys)
		for (const key of Object.keys(keys)) {
			if (!isObservableProp(model, key)) continue
			const convert = keys[key]
			vals[key] = new FormValue(model, key as keyof M,
				typeof convert === 'object' ? convert : void 0)
		}
	else
		for (const key in model) {
			if (!isObservableProp(model, key)) continue
			vals[key as string] = new FormValue(model, key)
		}
	return vals as Required<{ [k in K]: FormValue<M, k> }>
}

// experiment with typing...
// const v1 = formValues({ a: true, b: '' }, { a: 1 })
// const a1 = v1.a
// const va1 = a1.val

// const v2 = formValues({ a: true, b: '' })
// const a2 = v2.a
// const va2 = a2.val

// const keys = ['a']
// const k1: ('a'|'b')[] = ['a']
