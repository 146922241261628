import { action, computed, observable } from './common'
import { Item } from './Item'


export type LinkSource = Link | Item
export type PreviewLevel = 'small' | 'compact' | 'normal' | 'extended' | 'full'
export const previewLevels: PreviewLevel[] =
	['small', 'compact', 'normal', 'extended', 'full']
const nextLevel: {
	[k in PreviewLevel]: PreviewLevel;
} = {
	small: 'compact', compact: 'normal', normal: 'extended',
	extended: 'full', full: 'small',
}

export class Link {

	@observable item: Item

	@observable name: string

	@observable url: string
	@computed get refId() { return Link.parseItemId(this.url) }

	constructor(url: string, item?: Item) {
		this.url = url
		this.item = item
	}

	static parseItemId(url: string) {
		if (!url)
			return null
		const s = url.indexOf('#')
		if (s < 0)
			return url
		const id = url.substr(s + 1)
		return id || null
	}

	@observable preview: PreviewLevel = 'normal'
	@action togglePreview() {
		this.preview = nextLevel[this.preview]
	}
}
