import { observable } from '../common'
import * as mdl from './base'

export class UserInterface {
	nav = new mdl.Navigation()
	@observable dialogs: mdl.Dialog[] = []
	// clipboard
	@observable clipboard: (mdl.Link | mdl.Item)[]
	// config
	@observable longTouchSelect = true
}
