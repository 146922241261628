import { observer } from 'mobx-react'
import * as React from 'react'
import { action, IObservableValue, observable } from '../../common'
import * as mdl from '../../model'
import { Component } from './Component'

interface Props {
}

// currently open menu
const openMenu = observable.box(null)

@observer
export class Menu extends Component<Props> {

	render() {
		const { children } = this.props
		return (
			<button className={'menu' + (openMenu.get() === this ? ' open' : '')}
				onClick={this.onOpen}>
				<div>{children}</div>
			</button>
		)
	}

	onOpen = (evn: React.MouseEvent<HTMLButtonElement>) => {
		if (openMenu.get() !== this) {
			action('open menu', () => { openMenu.set(this) })()
			evn.stopPropagation()
		}
	}
}

// close menu
const close = (evn: Event) => {
	if (openMenu.get()) {
		action('close menu', () => { openMenu.set(null) })()
		// remove focus from buttons and alike
		void (document.activeElement as HTMLElement).blur()
	}
}
document.addEventListener('click', evn => {
	const previous = openMenu.get()
	setTimeout(() => {
		if (openMenu.get() === previous) close(evn)
	})
})
window.addEventListener('blur', close)
