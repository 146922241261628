import { Utils } from '../../../common'
import * as mdl from '../../../model'
import { IndexedDb } from '../../common/IndexedDb'
import { createDbs } from './createDbs'

export class IndexedDbAccess implements mdl.BoxStorageAccess {

	constructor(public dbs:
		{ data: IndexedDb; index: IndexedDb; preview?: IndexedDb; }) {
		if (!dbs)
			this.dbs = createDbs()
	}

	async readData(fromBoxId: string): Promise<mdl.ItemData[]> {
		return await this.dbs.data.bulk(
			{ stores: ['box', 'item'], mode: 'readonly' },
			{
				primaryKeysFor: fromBoxId, store: 'box', index: 'items',
				next: (itemIds: string[]) => itemIds.length ?
					{ get: itemIds, store: 'item' } : null
			}) ?? []
	}

	async readIds(fromBoxId: string) {
		const data = await this.readData(fromBoxId)
		return data.map(d => ({ id: d.id, rev: mdl.Item.toFullRev(d) }))
	}

	async readItem(id: string, fromBoxIds: string[]) {
		const keys = Utils.arrayToObject(fromBoxIds)
		const boxIdVals = await this.dbs.data.getVal<string[]>('box', id)
		const boxIds = boxIdVals?.filter(id => id in keys)
		if (boxIds?.length > 0) {
			const d = await this.dbs.data.getVal<mdl.ItemData>('item', id)
			if (d)
				d.boxes = boxIds
			return d
		}
		return null
	}

	async readItems(ids: string[], fromBoxIds: string[]) {
		return Promise.all(ids.map(id => this.readItem(id, fromBoxIds)))
	}

	async readFromLinks(id: string) {
		return await this.dbs.index.getVal('from', id) ?? []
	}

	async readSearchTexts() {
		return await this.dbs.index.getAll('stem') ?? []
	}

	async writeItem(data: mdl.ItemData, intoBoxId: string): Promise<void> {
		const { id } = data
		const orig = await this.dbs.data.getVal('item', id)
		await this.dbs.data.bulk(
			{ put: id, store: 'item', val: data },
			{
				get: id, store: 'box', next: (boxKeys: string[]) => ({
					put: id, store: 'box',
					val: Utils.addUnique(boxKeys, intoBoxId)
				})
			})
		if (data.links?.length > 0 || orig?.links?.length > 0) {
			const fromIndexes: {
				[id: string]: string[];
			} = {}
			setLinkIds(data, fromIndexes)
			const newIndexes = { ...fromIndexes }
			setLinkIds(orig, fromIndexes)
			await this.dbs.index.bulk({
				get: fromIndexes, store: 'from',
				next: (kv: { [x: string]: any; }) => updateFromIndex(kv, id, newIndexes)
			})
		}
		// TODO: word stem based and adapt data access interface
		const searchText = getSearchTextVals(data).join(' ')
		if (searchText)
			await this.dbs.index.putVal('stem', id,
				{ id, rev: mdl.Item.toFullRev(data), text: searchText })
	}

	async removeItem(id: string, fromBoxId: string) {
		const itemData = await this.dbs.data.bulk({ stores: ['box', 'item'] }, {
			get: id, store: 'box', next: (boxKeys: string[]) => {
				const idx = boxKeys ? boxKeys.indexOf(fromBoxId) : -1
				if (idx >= 0) {
					boxKeys.splice(idx, 1)
					return boxKeys.length > 0 ?
						{ put: id, store: 'box', val: boxKeys } : [
							{ del: id, store: 'box' },
							{ get: id, store: 'item' },
							{ del: id, store: 'item' }
						]
				}
			}
		})
		if (itemData) {
			if (itemData.links?.length > 0) {
				const fromIndexes: { [id: string]: string[]; } = {}
				setLinkIds(itemData, fromIndexes)
				await this.dbs.index.bulk({
					get: fromIndexes, store: 'from',
					next: (kv: { [x: string]: string[]; }) => updateFromIndex(kv, id)
				})
			}
			await this.dbs.index.delVal('stem', id)
		}
	}

	async getBoxes(knownBoxIds: string[]) {
		const excludeBoxIds = Utils.arrayToObject(knownBoxIds)
		const boxIds = await this.dbs.data.getIndexKeys('box', 'items')
		return boxIds.filter(id => !(id in excludeBoxIds))
			.map(id => ({ id, permissions: 'rw' as mdl.BoxPermissions }))
	}

	async addBox(box: mdl.Box) {
		// OPT: cache boxIds
		const boxIds = await this.dbs.data.getIndexKeys('box', 'items')
		if (!boxIds.includes(box.id)) {
			// OPT: only read items not already in this storage
			const data = await box.readData()
			await Promise.all(data.map(d => this.writeItem(d, box.id)))
		}
	}

	async removeBox(boxId: string) {
		const itemIds = await this.dbs.data.getIndexPrimaryKeys('box', 'items',
			boxId)
		await Promise.all(itemIds.map(id => this.removeItem(id, boxId)))
	}

	async open(boxId?: string) {
		if (!this.dbs)
			this.dbs = createDbs()
	}

	async close(boxId?: string) {
		if (!boxId) {
			await Promise.all(Object.values(this.dbs).map(db => db.close()))
			this.dbs = null
		}
	}
}

export function getSearchTextVals(itemData: mdl.ItemData) {
	// implement corresponding to center/src/couch/upsertBox.ts views.searchText
	const vals = []
	for (const n in itemData.props) {
		const v = itemData.props[n]
		if (!v) continue
		const t = typeof v
		if (t === 'string') {
			if (v.indexOf(' ') > 0 || v.indexOf(':') < 4)
				vals.push(v)
		} else if (t === 'object') {
			for (const n1 in v) {
				const v1 = v[n1]
				if (v1 && typeof v1 === 'string' &&
					(v1.indexOf(' ') > 0 || v1.indexOf(':') < 4)) {
					vals.push(v1)
					break
				}
			}
		}
	}
	return vals
}

export function updateFromIndex(kv: { [x: string]: any }, id: string,
	newIndexes?: { [x: string]: string[] }) {
	return Object.keys(kv).map(k => {
		const v = kv[k]
		const idx = v?.indexOf(id)
		if (idx >= 0) {
			if (newIndexes && k in newIndexes) {
				return null
			} else {
				v.splice(idx, 1)
				return v.length > 0 ? { put: k, store: 'from', val: v } :
					{ del: k, store: 'from' }
			}
		} else if (newIndexes) {
			return { put: k, store: 'from', val: v ? [...v, id] : [id] }
		} else {
			return null
		}
	})
}

export function setLinkIds(itemData: mdl.ItemData,
	fromIndexes: { [id: string]: string[] }) {
	if (itemData?.links?.length > 0)
		for (const ln of itemData.links)
			if (ln) fromIndexes[typeof ln === 'string' ? ln : ln.url] = null
}


