import { O } from '../common'
import * as mdl from '../model'

export const setup = {

	updateStorages: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.Box, box => {
			box.updateStorages.react(async () => {
				const stats =
					await updateBoxInStorages(box.id, box.availableStorages, box)
				box.reports.add(`Storages updated (${box.availableStorages
					.map((s, i) => stats[i] + ' in ' + s.url).join(', ')})`)
			})
		})
	}

}

export interface BoxStorage {
	id: string
	access: Pick<mdl.BoxStorageAccess, 'readIds' | 'readItems' | 'writeItem'>
}

export async function updateBoxInStorages(boxId: string,
	storages: BoxStorage[], statusReporter?: { status: string }) {
	if (statusReporter)
		statusReporter.status =
			`Storages ${storages.map(s => s.id).join(', ')}: start updating items...`
	const writes = []
	const newestRevs: { [id: string]: string } = {}
	const newestPerSrc: string[][] = []
	for (const storage of storages) {
		const idRevs = await storage.access.readIds(boxId)
		if (statusReporter)
			statusReporter.status =
				`${idRevs.length} idRevs read from ${storage.id}...`
		const itemIdMap = { ...newestRevs }
		const newest = []
		for (const idRev of idRevs) {
			if (!containsNewerOrEqualRev(newestRevs, idRev)) {
				for (const w of writes)
					w[idRev.id] = 1
				newestRevs[idRev.id] = idRev.rev
				for (const n of newestPerSrc)
					delete n[idRev.id]
				newest.push(idRev.id)
				delete itemIdMap[idRev.id]
			} else if (containsNewerRev(newestRevs, idRev)) {
				itemIdMap[idRev.id] = '1'
			} else {
				delete itemIdMap[idRev.id]
			}
		}
		newestPerSrc.push(newest)
		writes.push(itemIdMap)
	}
	let count = 0
	const statistics: number[] = []
	for (let i = 0, len = storages.length; i < len; ++i) {
		const c = Object.keys(writes[i]).length
		statistics.push(c)
		count += c
	}
	if (count > 0) {
		const isNeeded = (id: string) => {
			for (const w of writes)
				if (id in w)
					return true
			return false
		}
		for (let i = 0, len = storages.length; i < len; ++i) {
			const ids = newestPerSrc[i].filter(isNeeded)
			// get newest data
			if (statusReporter)
				statusReporter.status =
					`Read ${ids.length} items from ${storages[i].id}...`
			const data = await storages[i].access.readItems(ids, [boxId], true)
			if (statusReporter)
				statusReporter.status =
					`${data.length} items read from ${storages[i].id}. Writing...`
			// write newest data to all needed
			// TODO: write in batches
			for (let j = 0, lenJ = storages.length; j < lenJ; ++j) {
				const w = writes[j]
				const s = storages[j]
				for (const d of data) {
					if (d.id in w)
						await s.access.writeItem(d, boxId)
				}
			}
		}
	}
	if (statusReporter)
		statusReporter.status = ''
	return statistics
}

function containsNewerRev(revs: { [id: string]: string },
	idRev: { id: string; rev: string }) {
	return idRev.id in revs && mdl.Item.compareRev(revs[idRev.id], idRev.rev) > 0
}

function containsNewerOrEqualRev(revs: { [id: string]: string },
	idRev: { id: string; rev: string }) {
	return idRev.id in revs && mdl.Item.compareRev(revs[idRev.id], idRev.rev) >= 0
}

