import * as mdl from '..'

// TODO: first boolean prop instead of 'completed'

export namespace TodoLayout {

	export function getContentProps(item: mdl.Item) {
		return item.props.content
			.filter(p => p !== item.props.label && p.type !== 'image' &&
				p.name != 'completed')
	}

	export function getIconUri(item: mdl.Item): string {
		return item.props.get('completed')?.value ? 'completed' : 'todo'
	}

	export function getToggle(item: mdl.Item) {
		return item.props.findByAction('toggle')?.value?.toggle ?? null
	}

}
