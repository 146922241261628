import { comparer, IObservableArray, O, observe, reaction, Utils }
	from '../common'
import * as mdl from '../model'
import { addUpdateLog, buildData } from './build'

export const setup = {

	observe: ({ changes }: { changes: mdl.ChangeManager }) => {
		O.onInit(mdl.Item, item => {
			item.observe.react(() => {
				if (item.isGenerated) return
				reaction(
					() => buildData(item, false),
					data => {
						// TODO: redesign
						if (item.settingData)
							return
						// prepare data
						data = { ...data }
						data.rev = item.rev + 1
						item.recordUpdate()
						addUpdateLog(data, item)
						// process change
						item.hasChanged = true
						changes.setChange(data, item.boxItems
							.filter(mdl.Box.hasActiveStorage).map(Utils.toId))
					},
					{ name: `update ${item.id}`, equals: comparer.structural })
				observe(item.boxItems as IObservableArray<mdl.Item>, c => {
					// TODO: redesign
					if (item.settingData)
						return
					if ('added' in c) {
						for (const boxItem of c.added)
							if (mdl.Box.hasActiveStorage(boxItem))
								changes.setAdd(buildData(item), boxItem.id)
						for (const boxItem of c.removed)
							if (mdl.Box.hasActiveStorage(boxItem))
								changes.setRemove(item.id, boxItem.id)
					}
				})
			})
		})
	},

	delete: ({ changes }: { changes: mdl.ChangeManager }) => {
		// remove on delete
		O.onInit(mdl.Item, item => {
			item.delete.react(() => {
				for (const boxItem of item.boxItems)
					if (mdl.Box.hasActiveStorage(boxItem))
						changes.setRemove(item.id, boxItem.id)
			})
		})
	},

}
