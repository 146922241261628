import * as mdl from '../model'

export function parse({ pathname, search }:
	{ pathname: string, search?: string }) {
	while (pathname[0] === '/') pathname = pathname.substring(1)
	const res: mdl.NavData = { id: pathname, key: 'view' }
	const p = pathname.indexOf('/')
	if (p > 0) {
		res.key = pathname.substring(0, p) as mdl.ViewKey
		res.id = pathname.substring(p + 1)
	}
	else if (mdl.nonItemViewKeys.indexOf(pathname) >= 0) {
		res.key = pathname as mdl.ViewKey
		res.id = null
	}
	if (search && search.startsWith('?'))
		search = search.substring(1)
	if (search) {
		const params = new URLSearchParams(search)
		res.params = {}
		for (const k of params.keys())
			res.params[k] = params.get(k)
	}
	return res
}

export function format(location: mdl.NavData) {
	if (location.id === null) return '/' + location.key
	if (!location.id) return null
	let loc = location.id
	if (location.key !== 'view')
		loc = location.key + '/' + loc
	return '/' + loc
}
