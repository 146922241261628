
export type Config = typeof defaultConfig

export const defaultConfig = {
	api: {
		services: {
			preview: '/_serv/preview', proxy: '/_serv/proxy'
		},
		auth: '/_auth',
		google: {
			key: '',
			clientId: '',
			staticMap: '',
			embedMap: '',
			geocoding: '',
			elevation: '',
			search: '',
			imageSearch: {
				url: '',
				id: '',
			}
		},
		linkPreview: {
			key: '',
			url: '',
		},
		osm: {
			search: '',
			reverse: '',
		},
	},
	nav: {
		type: 'html5' as 'html5' | 'hash'
	},
}

export interface ConfigInput {
	api: {
		services?: {
			preview?: string
			proxy?: string
		}
		auth?: string
		google: {
			key: string
			clientId: string
			staticMap: string
			embedMap: string
			geocoding: string
			elevation: string
			search: string
			imageSearch: {
				url: string
				id: string
			}
		}
		linkPreview: {
			key: string
			url: string
		}
		osm: {
			search: string
			reverse: string
		}
	}
	nav?: {
		type?: 'html5' | 'hash'
	}
}

