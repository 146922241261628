import { O } from '../common'
import * as mdl from '../model'

export const setup = {

	deleteLinks: ({ boxes, items }:
		{ boxes: mdl.BoxManager, items: mdl.ItemManager }) => {
		// delete from links with the item itself
		O.onInit(mdl.Item, item => {
			item.delete.react(async () => {
				if (item.id) {
					for (const box of boxes.activeBoxes) {
						box.readFromLinks(item.id)
							.then(ids => {
								if (ids) {
									for (const id of ids) {
										const itm = items.requestItem(id)
										itm.complete()
											.then(() => { itm.links.remove(item.id) })
									}
								}
							})
					}
				}
			})
		})
	},

	refresh: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.Item, item => {
			item.refresh.react(() => boxes.loadItem(item, true))
		})
	},

}
