import { action } from 'mobx'
import { O, Utils } from '../common'
import * as mdl from '../model'

export const setup = {

	initNew: ({ inst, items }:
		{ inst: mdl.Installation, items: mdl.ItemFactory }) => {
		O.onInit(mdl.Item, item => {
			item.initNew.reactOnce(async () => {
				if (inst.isMissing) await createInstallationItem(inst, items)
				action(() => {
					item.id = encodeId(inst.id, inst.idGen++)
				})()
			})
		})
	},

}

async function createInstallationItem(inst: mdl.Installation,
	factory: mdl.ItemFactory) {
	const item = factory.createItem()
	await inst.initNew(item)
	inst.setItem(item)
	item.status = mdl.ItemStatus.level2
	await item.complete()
}

export function encodeId(installationId: string, idNum: number) {
	return installationId + Utils.encodeBase32(idNum)
}

export function decodeId(installationId: string, id: string) {
	return Utils.decodeBase32(id.substring(installationId.length))
}

export function isLocalId(id: string, installationId: string) {
	return id.startsWith(installationId)
}
