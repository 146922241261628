import * as mdl from '../model'
import { previewLevels } from '../model'
import { Context, Form, observer } from './common'
import * as layouts from './layouts'
import { Layout } from './layouts/common'

/** Fallback layout. Needs to implement all parts! */
const defaultLayout = layouts.list

export const ItemLabel = observer(({ item, context }:
	{ item: mdl.Item; context: Context; }) => {
	const layout: Layout = item.layout ? layouts[item.layout] : defaultLayout
	return ('label' in layout ? layout : defaultLayout).label(item, context)
})

export const ItemIcon = observer(({ item, context }:
	{ item: mdl.Item; context: Context; }) => {
	const layout: Layout = item.layout ? layouts[item.layout] : defaultLayout
	return ('icon' in layout ? layout : defaultLayout).icon(item, context)
})

export const ItemContent = observer(({ item, context }:
	{ item: mdl.Item; context: Context; }) => {
	const layout: Layout = item.layout ? layouts[item.layout] : defaultLayout
	return ('content' in layout ? layout : defaultLayout).content(item, context)
})

function getLinkLayout(linkLayouts: Layout['links'], level: mdl.PreviewLevel) {
	if (level in linkLayouts)
		return linkLayouts[level]
	for (let i = previewLevels.indexOf(level); i >= 0; --i) {
		const l = previewLevels[i]
		if (l in linkLayouts)
			return linkLayouts[l]
	}
	return linkLayouts['small']
}

export const ItemLink = observer(({ item, level, context }:
	{ item: mdl.Item; level: mdl.PreviewLevel; context: Context; }) => {
	const layout: Layout = item.layout ? layouts[item.layout] : defaultLayout
	const linkLayouts = 'links' in layout ? layout.links : layouts.list.links
	return getLinkLayout(linkLayouts, level)(item, context)
})

export const ItemEditor = ({ item, context, form }:
	{ item: mdl.Item; context: Context; form: Form }) => {
	const layout: Layout = item.layout ? layouts[item.layout] : defaultLayout
	return ('editor' in layout ? layout : defaultLayout)
		.editor(item, context, form)
}

