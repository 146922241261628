import * as React from 'react'
import * as mdl from '../model'
import { Context, Form, Icon, observer, TextInput } from './common'
import * as props from './properties'
import { PropertyView } from './properties/common'

interface PropArgs {
	prop: mdl.Property
	context: Context
}

function getView(prop: mdl.Property): PropertyView {
	return props[prop.type] ?? props[prop.type + 'View']
}

export const PropLabel = observer(({ prop, context }: PropArgs) => {
	if (!prop)
		return null
	const view = getView(prop)
	if (view && 'label' in view)
		return view.label(prop, context)
	const v = prop.value
	return v === void 0 || v === null ? null :
		<>{typeof v === 'object' && v ? v.constructor.name : '' + v}</>
})

export const PropIcon = observer(({ prop, context }: PropArgs) => {
	if (!prop)
		return null
	const view = getView(prop)
	if (view && 'icon' in view)
		return view.icon(prop, context)
	const v = prop.value
	return v === void 0 || v === null ? null :
		<Icon uri={'text:' + String(v).substring(0, 1)} />
})

export const PropContent = observer(({ prop, context }: PropArgs) => {
	if (!prop)
		return null
	const view = getView(prop)
	return view && 'content' in view ? view.content(prop, context) :
		view && 'label' in view ? view.label(prop, context) :
			prop.value === void 0 || prop.value === null ? null : <>{prop.value}</>
})

export const PropEditor = observer(({ prop, context, form, autoFocus }:
	PropArgs & { form: Form<any>, autoFocus: boolean }) => {
	if (!prop)
		return null
	const view = getView(prop)
	if (view && 'editor' in view)
		return view.editor(prop, form, context, autoFocus)
	return <TextInput label={prop.label} value={form.values[prop.name]}
		autoFocus={autoFocus} />
})

export function findPropWithIconView(item: mdl.Item) {
	for (const p of item.props.values()) {
		// TODO: remove dependency on image properties. generic Property.isValid()
		if (p.type in { image: 1, icon: 1 } && !mdl.ImageValue.isValid(p.value))
			continue
		const view = getView(p)
		if (view && 'icon' in view)
			return p
	}
	return null
}

