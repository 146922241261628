export * from './Signal'
export * from './Service'
export * from './DataHelpers'
export * from './common'
export * from './ObjectStatus'
export * from 'mobx'
export * from './mobx'
export * from './Utils'

import { IObservableValue, observable } from 'mobx'

export const ref = observable.shallowBox
export type Ref<T> = IObservableValue<T>
