import { O, reaction } from '../../common'
import * as mdl from '../../model'
import { requestRecentItems } from './recentItems'
import { buildIndexes, search } from './search'

export const setup = {

	recentItems: (props: { items: mdl.ItemManager, nav: mdl.Navigation }) => {
		O.onInit(mdl.SearchView, view => {
			view.recentItems = requestRecentItems(props)
		})
	},

	search: ({ boxes, items }:
		{ boxes: mdl.BoxManager, items: mdl.ItemManager }) => {
		O.onInit(mdl.SearchView, async view => {
			const indexes = await buildIndexes(boxes)
			reaction(() => view.searchText, q => { search(q, indexes, view, items) },
				{ delay: 500 })
		})
	},

}

