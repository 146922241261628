import { observer } from 'mobx-react'
import * as React from 'react'
import * as mdl from '../../model'
import { format } from '../../navigation/BrowserUrl'

interface Props {
	itemId: string
	viewKey?: mdl.ViewKey
	nav: mdl.Navigation
}

@observer
export class Link extends React.Component<Props> {

	render() {
		const { itemId, viewKey, children } = this.props
		return <a href={format({ id: itemId, key: viewKey || 'view' })}
			onClick={this.onClick}>
			{children || itemId}</a>
	}

	onClick = (evn: React.MouseEvent<HTMLAnchorElement>) => {
		if (evn.button == 0 && !evn.ctrlKey && !evn.metaKey) {
			evn.preventDefault()
			const { nav, itemId, viewKey } = this.props
			nav.go(viewKey || 'view', itemId)
		}
	}
}
