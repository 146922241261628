import { O } from './common'
import { Item, ItemStatus } from './Item'
import { PropertyType } from './Property'

export type PropertyArgs = [string, any, PropertyType?]

export class ItemFactory {

	createItem(...props: PropertyArgs[]): Item {
		const item = O.new(Item)
		for (const arg of props)
			item.props.set(arg[0], arg[1], arg[2])
		return item
	}

	async createNewItem(...props: PropertyArgs[]): Promise<Item> {
		const item = this.createItem(...props)
		await item.initNew()
		return item
	}

	async createNewItemTree(spec: ItemSpec) {
		const newItem = await this.createNewItem(...spec.props)
		newItem.status = ItemStatus.level1
		if (spec.links) {
			const links = await Promise.all(
				spec.links.map(sp => this.createNewItemTree(sp)))
			for (const ln of links)
				newItem.links.add(ln, 0)
		}
		await newItem.complete()
		return newItem
	}

}

export interface ItemSpec {
	props: PropertyArgs[]
	links?: ItemSpec[]
}
