import { Utils } from '../common'
import * as mdl from '../model'

export const setup = {

	config: ({ config }: { config: mdl.Config }) => {
		if ('config' in window) Utils.assignDeep(config, window['config'])
		else throw new Error('No config loaded!')
	},

}

