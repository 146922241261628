import { O, Utils } from '../common'
import * as mdl from '../model'

/** Converting and comparing Property values to and from data. */

export function propertyEqualsData(p: mdl.Property, data: any) {
	if (!p)
		return false
	if (!(p.type in compareData))
		throw new Error(`Unknown type '${p.type}' for property '${p.name}'!`)
	return compareData[p.type](p.value, data)
}

const compareData:
	{ [type in mdl.PropertyType]: (v: any, d: any) => boolean } = {
	string: (v, d) => v === d || v === d.string,
	number: (v, d) => v === d || (v === d.number && !d.unit) ||
		Utils.deepEquals(v, d),
	boolean: (v, d) => v === d || v === d.boolean,
	text: (v, d) => v === d.text,
	color: (v, d) => v === d.color,
	url: (v, d) => v === d.url,
	object: (v, d) => v === d.object || v === d,
	enum: (v, d) => v === d.enum,
	icon: (v, d) => v === d.icon,
	date: (v: Date, d) => v === Utils.parseIsoDate(d.date),
	time: (v: Date, d) => v === Utils.parseIsoTime(d.time),
	dateTime: (v: Date, d) => v === Utils.parseIsoDateTime(d.dateTime),
	duration: (v, d) => (v === d.duration && !d.unit) || Utils.deepEquals(v, d),
	image: (v, d) => Utils.deepEquals(v, d),
	location: (v, d) => Utils.deepEquals(v, d),
	blob: (v: Blob, d) => v.size === d.size && v.type === d.type,
	icons: (v, d) => Utils.deepEquals(v, d),
	box: (v: mdl.Box, d: mdl.BoxValue) => v.label === d.box &&
		v.mark === d.mark && v.color === d.color && v.backColor === d.backColor,
	storage: (v: mdl.BoxStorage, d: mdl.BoxStorageValue) =>
		v.protocol === d.storage && v.url === d.url,
	account: (v: mdl.Account, d: mdl.AccountValue) =>
		v.provider === d.account && v.url === d.url,
	query: (v: mdl.Query, d) => v.select === d.query,
	installation: (v, d) => 'installation' in d,
	action: (v, d) => mdl.propertyActionValues[d.action] === v.constructor
}

export function propertyToData(p: mdl.Property): any {
	return p.type in typedToData ?
		typedToData[p.type](p.value) : p.value
}

const typedToData = {
	text: (v: string) => ({ text: v }),
	color: (v: string) => ({ color: v }),
	url: (v: string) => ({ url: v }),
	object: (v: object) => ({ object: v }),
	enum: (v: string) => ({ enum: v }),
	image: (v: mdl.ImageValue) => typeof v === 'object' && 'image' in v ? v :
		{ image: v },
	icon: (v: string) => ({ icon: v }),
	date: (v: Date) => ({ date: Utils.toIsoDateString(v) }),
	time: (v: Date) => ({ time: Utils.toIsoTimeString(v) }),
	dateTime: (v: Date) => ({ dateTime: Utils.toIsoDateTimeString(v) }),
	action: (v: object) => ({ action: v.constructor['key'] }),
	box: (v: mdl.Box) => (
		{ box: v.label, mark: v.mark, color: v.color, backColor: v.backColor }),
	storage: (v: mdl.BoxStorage) => ({ storage: v.protocol, url: v.url }),
	account: (v: mdl.Account) => ({ account: v.provider, url: v.url }),
	query: (v: mdl.Query) => ({ query: v.select }),
	installation: () => ({ installation: '--marker--' }),
}

const primitiveTypes: { [t in mdl.PropertyType]?: any } =
	{ string: 1, number: 1, boolean: 1 }
/** One-value only types */
const simpleTypeCodes: mdl.PropertyType[] =
	['text', 'url', 'object', 'string', 'enum', 'boolean', 'icon', 'color']
/** Date-value types */
const dateTypeCodes: mdl.PropertyType[] = ['date', 'time', 'dateTime']
/** Compound value types. Eg. `{number: 123, unit: 'kg' }` */
const complexTypeCodes: mdl.PropertyType[] =
	['number', 'duration', 'image']
/** Implemented value types. */
const implementationTypeCodes: { [k in mdl.PropertyType]?: any } =
{
	query: mdl.Query, box: mdl.Box, storage: mdl.BoxStorage,
	account: mdl.Account
}

export function propertySetValue(prop: mdl.Property, val: any) {
	if (val === void 0 || val === null)
		return
	const t = typeof val as mdl.PropertyType
	if (t in primitiveTypes)
		return prop.setValue(val, t)
	if (t === 'object') {
		for (const type of Object.keys(implementationTypeCodes))
			if (type in val)
				return prop.setValue(O.new(implementationTypeCodes[type],
					prop.item, val), type as mdl.PropertyType)
		if ('latitude' in val && 'longitude' in val)
			return prop.setValue(val, 'location')
		if ('action' in val)
			return prop.setValue(
				O.new(mdl.propertyActionValues[val.action], prop.item), 'action')
		// the actual installation gets set by the running installation only
		if ('installation' in val)
			return prop.setValue(null, 'installation')
		for (const type of complexTypeCodes)
			if (type in val)
				return prop.setValue(val, type)
		for (const type of simpleTypeCodes)
			if (type in val)
				return prop.setValue(val[type], type)
		for (const type of dateTypeCodes)
			if (type in val)
				return prop.setValue(new Date(val[type]), type)
		return prop.setValue(val, 'object')
	}
	throw new Error(`Invalid property data! ${prop.item.id}.${prop.name
		}: ${JSON.stringify(val)}`)
}

