import * as React from 'react'
import { Utils } from '../../common'
import { Icon, TextInput } from '../common'
import { PropertyView } from './common'

const dateOptions = {
	date: { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' },
	time: { hour: '2-digit', minute: '2-digit', second: '2-digit' },
	dateTime: {
		year: 'numeric', month: 'long', day: 'numeric', weekday: 'long',
		hour: '2-digit', minute: '2-digit', second: '2-digit'
	},
}

export const date: PropertyView = {
	label: ({ value }) => <>
		{value ? Utils.isDate(value) ?
			value.toLocaleString('default', dateOptions.date) : '' + value : '?'}</>,
	icon: () => <Icon uri="date" />,
	editor: (p, form, ctx, autoFocus) =>
		<TextInput label={p.label} value={form.values[p.name]} type="date"
			autoFocus={autoFocus} />
}

export const time: PropertyView = {
	label: ({ value }) => <>
		{value ? Utils.isDate(value) ?
			value.toLocaleString('default', dateOptions.time) : '' + value : '?'}</>,
	icon: () => <Icon uri="time" />,
	editor: (p, form, ctx, autoFocus) =>
		<TextInput label={p.label} value={form.values[p.name]} type="time"
			autoFocus={autoFocus} />
}

export const dateTime: PropertyView = {
	label: ({ value }) => <>
		{value ?
			Utils.isDate(value) ?
				value.toLocaleString('default', dateOptions.dateTime) :
				'' + value : '?'}</>,
	icon: () => <Icon uri="dateTime" />,
	editor: (p, form, ctx, autoFocus) =>
		<TextInput label={p.label} value={form.values[p.name]} type="datetime-local"
			autoFocus={autoFocus} />
}
