import * as React from 'react'
import * as mdl from '../model'
import { format } from '../navigation/BrowserUrl'
import { Component, Context, css, observer } from './common'
import { BoxMarks } from './common/BoxMark'
import { ItemLink } from './LayoutViews'

interface Props {
	item: mdl.Item
	context: Context
	title?: string
	disabled?: boolean
	boxMarks?: boolean
	onNav?: (item: mdl.Item) => void
	level?: mdl.PreviewLevel
}

@observer
export class NavItemLink extends Component<Props> {

	render() {
		const { item, title, disabled, boxMarks, context, level = 'normal',
			onNav } = this.props
		if (!item.isReady)
			return null
		return <div className={css('link', level, item.layout)}>
			<a className={css('entry', disabled && 'disabled')}
				onClick={this.onClick} title={title} draggable={false}
				href={onNav ? null : format({ id: item.id, key: 'view' })}>
				<ItemLink item={item} level={level} context={context} />
			</a>
			{boxMarks && <BoxMarks item={item} />}
		</div>
	}

	onClick = (evn: React.MouseEvent<HTMLAnchorElement>) => {
		if (this.props.disabled) return
		evn.preventDefault()
		const { item, context: { nav }, onNav } = this.props
		if (onNav) onNav(item)
		else nav.go('view', item.id)
	}
}
