import { observer } from 'mobx-react'
import * as React from 'react'
import * as mdl from '../../model'
import { Component, Icon, Menu } from '../common'

interface Props {
	view: mdl.AccountsView
	context: {
		config: mdl.Config
		nav: mdl.Navigation
	}
}

@observer
export class Accounts extends Component<Props> {

	render() {
		const { context: { nav }, view: { accounts } } = this.props
		return <div className="view">
			<header>
				<button onClick={nav.back}><Icon uri="navBack" /></button>
				<span><i>Accounts for </i>
					{'Bruno'}
				</span>
				<Menu>
					<div onClick={this.onGoogle}>Add Google</div>
				</Menu>
			</header>
			<section>
				<div className="content">
					<p>hmm...</p>
					{accounts.map(a => <div key={a.id}>{a.item.labelText}</div>)}
				</div>
			</section>
			<footer>
			</footer>
		</div>
	}

	onGoogle = () => {
		this.props.view.addAccount('google')
	}

}
