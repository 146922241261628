import { action, reaction, when } from '../common'
import * as mdl from '../model'

const LOCALSTORAGE_KEY_CLIPBOARD = 'clipboard'

export const setup = {
	read: async ({ items, ui }:
		{ items: mdl.ItemManager, ui: mdl.UserInterface }) => {
		const str = localStorage.getItem(LOCALSTORAGE_KEY_CLIPBOARD)
		if (str) ui.clipboard = getItems(items, str)
	},

	update: ({ ui }: { ui: mdl.UserInterface }) => {
		reaction(
			() => ui.clipboard?.map(ln =>
				ln instanceof mdl.Link ? ln.item.id : ln.id),
			ids => {
				if (ids)
					localStorage.setItem(LOCALSTORAGE_KEY_CLIPBOARD, ids.join('\n'))
			})
	},

	external: ({ items, ui }:
		{ items: mdl.ItemManager, ui: mdl.UserInterface }) => {
		addEventListener('storage', evn => {
			if (evn.key !== LOCALSTORAGE_KEY_CLIPBOARD) return
			action(() => { ui.clipboard = getItems(items, evn.newValue) })()
		})
	},

}

function getItems(items: mdl.ItemManager, ids: string) {
	return ids.split('\n')
		.filter(id => id.trim().length > 0)
		.map(id => items.requestItem(id))
}
