import { action, O, Utils, when } from '../common'
import * as mdl from '../model'

export const setup = {

	create: () => {
		O.onInit(mdl.Item, item => {
			item.initNew.reactOnce(() => { setLog(item.create) })
		})
	},

	update: () => {
		O.onInit(mdl.Item, item => {
			item.recordUpdate.react(() => { setLog(item.update) })
		})
	},

}

function setLog(log: mdl.Log) {
	navigator.geolocation.getCurrentPosition(p => {
		const coords = p.coords
		action(() => {
			log.position = Utils.filterMembers({
				lat: coords.latitude,
				long: coords.longitude,
				accuracy: Math.round(coords.accuracy * 100) / 100,
				alt: Math.round(coords.altitude * 100) / 100,
				altAccuracy: Math.round(coords.altitudeAccuracy * 100) / 100,
				heading: Math.round(coords.heading),
				speed: Math.round(coords.speed)
			}, (k, v) => typeof v === 'number' && !isNaN(v))
		})()
	})
}
