import { signal } from './common'
import { Item } from './Item'


export class SelectDateAction {
	static readonly key = 'selectDate'
	constructor(public item: Item) { }
	start = signal<(date: Date) => (void | Promise<void>)>()
	end = signal<(id: string) => void>()
}

export class ToggleAction {
	static readonly key = 'toggle'
	constructor(public item: Item) { }
	toggle = () => {
		const p = this.item.props.findByType('boolean')
		if (p) {
			p.value = !p.value
		} else {
			const t = this.item.props.findByTypeInTmpl('boolean')
			if (t)
				this.item.props.set(t.name, !t.value, t.type)
		}
	}
}

export const propertyActionValues = {
	[ToggleAction.key]: ToggleAction,
	[SelectDateAction.key]: SelectDateAction,
}
