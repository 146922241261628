import * as React from 'react'
import { Utils } from '../../common'
import { Block, TextareaInput } from '../common'
import { PropertyView } from './common'

export const object: PropertyView = {
	label: ({ value }) => <>{Utils.enumerateMembers(value)}</>,
	content: ({ value, label }) => <Block label={label}>
		<pre>{Utils.enumerateMembers(value, '  ')}</pre></Block>,
	editor: (prop, form, ctx, autoFocus) =>
		<TextareaInput label={prop.label} value={form.values[prop.name]}
			autoFocus={autoFocus} />
}
