import { action, O, httpClient } from '../../common'
import * as mdl from '../../model'

export const setup = {

	accountAccess: ({ log }: { log: mdl.SystemLogger }) => {
		O.onInit(mdl.Account, account => {
			account.readUser.react(async (credential: mdl.PasswordCredential) => {
				const http = httpClient(credential)
				try {
					const url = account.url + '/_users/org.couchdb.user:' + credential.id
					const data = await http.get(url)
					if (!data)
						throw new Error(`Login '${credential.id}' not found!`)
					action(() => {
						if ('avatar' in data)
							account.item.props.set('avatar', data.avatar, 'icon')
						if ('email' in data)
							account.item.props.set('email', data.email)
					})()
				} catch (err) {
					// configured admin user
					if (err.status === 404)
						await http.get(account.url + '/_up')
					else
						throw err
				}
			})

			account.changePassword.start.react(async (oldPw, newPw1, newPw2) => {
				if (newPw1 !== newPw2) {
					log.error('The repetition does not match the new password!')
				} else if (oldPw !== account.credential['password']) {
					log.error('Incorrect old password!')
				} else {
					try {
						const cred = account.credential as mdl.PasswordCredential
						const url = account.url + '/_users/org.couchdb.user:' +
							account.credential.id
						const http = httpClient(cred)
						const data = await http.get(url)
						if (!data)
							throw new Error(`Login '${account.credential.id}' not found!`)
						data.password = newPw1
						await http.put(url, data)
						account.changePassword.end(newPw1)
					} catch (err) {
						log.error(err)
					}
				}
			})
		})
	},

}
