import * as mdl from '../model'
import { httpClient } from '../common'

export async function generateInstallationId(config: { auth: string },
	auth: mdl.Auth) {
	const cds = auth.getCredential(config.auth)
	const http = httpClient(cds as mdl.PasswordCredential)
	return await http.put(config.auth +
		'/allsbe_id/_design/utils/_update/genId/installation_id', null)
}
