
import { setupAll } from '../../../base/src/common/setup'
import { setup as base } from '../../../base/src/data'
import * as mdl from '../model'
import { setup as access } from './access'
import { setup as accounts } from './account/couch'
import { setup as boxesSetup } from './boxes'
import { setup as components } from './components'
import { setup as config } from './config'
import { setup as dialogs } from './dialogs'
import { setup as GeoLocation } from './GeoLocation'
import {
	createIndexedDbBakStorage, setup as installation
} from './installation'
import { setup as Query } from './Query'
import { setup as recentItems } from './recentItems'
import { setup as Session } from './Session'
import { local_storage as sysStorage } from './Storage'
import { setup as UrlPreview } from './UrlPreview'
import { setup as UserInterface } from './UserInterface'
import { setup as views } from './views'
import './import/HtmlContentParser'

export const setup = {
	base, dialogs, components, views, recentItems, UrlPreview, GeoLocation,
	Session, UserInterface, Query, config,

	installation: ({ app, boxes, items, inst, session, config }: {
		app: mdl.AppBoot, boxes: mdl.BoxManager, items: mdl.ItemManager,
		inst: mdl.Installation, session: mdl.Session, config: mdl.Config
	}) => {
		setupAll(installation, {
			app, boxes, items, inst, session, config,
			sysStorage, bakStorage: createIndexedDbBakStorage()
		})
	},

	access: ({ app, boxes, items, inst }: {
		app: mdl.AppBoot, boxes: mdl.BoxManager, items: mdl.ItemManager,
		inst: mdl.Installation
	}) => {
		setupAll(access, { app, boxes, items, log: inst.log, inst })
	},

	accounts: ({ inst }: { inst: mdl.Installation }) => {
		setupAll(accounts, { log: inst.log })
	},

	boxes: ({ app, boxes, changes, items, inst, session }: {
		app: mdl.AppBoot, boxes: mdl.BoxManager, changes: mdl.ChangeManager,
		items: mdl.ItemManager, inst: mdl.Installation, session: mdl.Session
	}) => {
		setupAll(boxesSetup,
			{ app, boxes, changes, items, inst, auth: session.auth, sysStorage })
	},

}

