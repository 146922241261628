import * as React from 'react'
import { observable, Utils } from '../common'
import * as mdl from '../model'
import {
	action, Component, Context, FileUploadButton, hasCapture, Icon,
	observer, TextareaInput,
} from './common'
import { ItemLabel } from './LayoutViews'
import { NavItemLink } from './NavItemLink'

interface Props {
	view: mdl.AddItemView
	context: Context
}

// TODO: switch to prototypes, templates
const newTypes = [
	'auto', 'list', 'text', 'paste', hasCapture() && 'camera', 'file',
	'url', 'location', 'box', 'storage'
].filter(Utils.isTrue)
if (localStorage.getItem('experiments'))
	newTypes.push('contact', 'query', 'todo', 'comment', 'number', 'date',
		'calendar', 'account')

@observer
export class AddItem extends Component<Props> {

	render() {
		const { view, view: { item }, context, context: { nav } } = this.props
		return <div className="view">
			<header>
				<button onClick={nav.back}><Icon uri="navBack" /></button>
				<div>
					<span><i>add to </i><ItemLabel item={item} context={context} /></span>
				</div>
			</header>
			<section>
				<div className="content">
					<div className="field">
						<TextareaInput target={view} member="searchText"
							placeholder="Text for new item or to search link candidates..."
							autoFocus />
					</div>
				</div>
				<h5>New...</h5>
				<div className="add">
					{newTypes.map(t =>
						<AddButton key={t} type={t} view={view} />)}
				</div>
				<h5>Link to...</h5>
				<div className="items linkTo">
					{view.linkCandidates.map(itm => itm?.isReady ?
						<NavItemLink key={itm.id} item={itm} onNav={this.onLink}
							context={context}
							title={`Link to item ${itm.id}...`} />
						: <div key={itm.id} className="loading">loading...</div>)}
					{view.linkCandidates.length <= 0 && '-'}
				</div>
			</section>
			<footer />
		</div>
	}

	@action onEntry = (evn: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.props.view.searchText = evn.target.value
	}

	onLink = (to: mdl.Item) => {
		this.props.view.item.links.add(to, 0)
		this.props.context.nav.back()
	}

	onKey = (evn: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (evn.ctrlKey && evn.key === 'Enter') {
			// create a text item with the search text
			if (this.props.view.searchText)
				this.props.view.addNewItem.start()
		}
	}

	onDocumentKey = (evn: KeyboardEvent) => {
		const { context: { nav } } = this.props
		switch (evn.key) {
			case 'Escape': nav.back(); break
		}
	}

}

interface AddProps {
	type: string
	view: mdl.AddItemView
}

const renderers = {
	auto: (btn: AddButton) =>
		<a onClick={btn.onAddAuto}
			title={'Auto detect item type from text...'}>
			<Icon uri="star" /></a>,
	file: (btn: AddButton) =>
		<FileUploadButton onChange={btn.onUpload} accept="image/*"
			title="Create an image from a file...">
			<Icon uri="insert_drive_file" />
		</FileUploadButton>,
	camera: (btn: AddButton) =>
		<FileUploadButton onChange={btn.onUpload} accept="image/*" capture
			title="Create an image with your camera...">
			<Icon uri="camera_alt" />
		</FileUploadButton>,
	paste: ({ props: { view } }: { props: AddProps }) =>
		<a onClick={view.onPaste} title={'Paste a new item from clipboard...'}>
			<Icon uri="paste" /></a>,
	contact: ({ props: { view } }: { props: AddProps }) =>
		<a onClick={view.addContact}
			title={'Create an item from one of your contacts...'}>
			<Icon uri="person" /></a>,
}

export class AddButton extends Component<AddProps> {

	render() {
		const { type } = this.props
		return (type in renderers) ? renderers[type](this) :
			<a onClick={this.onAdd} title={`Create ${type} item...`}>
				<Icon uri={type} /></a>
	}

	onAdd = () => {
		const { type, view } = this.props
		view.addNewItem.start(type + '.tmpl')
	}

	onAddAuto = () => {
		const { view } = this.props
		view.addNewItem.start()
	}

	onUpload = (evn: React.FormEvent<HTMLInputElement>) => {
		const file = evn.currentTarget.files[0]
		const { view } = this.props
		view.addNewItem.start('image.tmpl', file)
	}

}
