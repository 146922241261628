import { Utils } from '../../common'

export const ValueTypes = {
	string: {
		parse: (v: string | number) => typeof v === 'string' ? v : '' + v
	},
	number: {
		parse: (v: string | number) => typeof v === 'number' ? v : parseFloat(v)
	},
	boolean: {
		parse: (v: string | number) => typeof v === 'boolean' ? v :
			v === '1' || v === 'true' ||
			v === 'yes' || v === 'on' || (typeof v === 'number' && v !== 0),
	},
	date: {
		parse: (v: string | number) => new Date(v),
		format: (v: Date) => Utils.isDate(v) ? v.toISOString() : '' + v
	},
	blob: {
		// marker only...
	}
}
export type ValueType = typeof ValueTypes[keyof typeof ValueTypes] | void
export type ValueTypeAny =
	{ parse: (v: any) => any, format: (v: any) => any } | void

export type Value<M, K extends keyof M, V extends ValueType = void> =
	{ obj: M, key: K, type?: V }
export type Tmpl = { [k: string]: Value<any, any, any> | Tmpl }

export function val<M, K extends keyof M, V extends ValueType = void>
	(obj: M, key: K, type?: V): Value<M, K, V> {
	return { obj, key, type }
}

export function isVal(obj: any) {
	return 'obj' in obj && 'key' in obj
}
