import * as mdl from '../../../model'
import { textImporters } from './common'

textImporters.push(newItemsForList)

function newItemsForList(txt: string, items: mdl.ItemManager) {
	const lines = txt.split('\n').filter(ln => ln.startsWith('- '))
	if (lines.length <= 0)
		return null
	return lines.map(ln => {
		const item = items.createItem()
		item.props.set('title', ln.substring(3).trim())
		item.tmpls.add(items.getItem('list.tmpl'))
		return item
	})
}
