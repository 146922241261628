import * as React from 'react'
import * as mdl from '../model'
import {
	action, Component, Context, Icon, observer, Observer, TextareaInput
} from './common'
import { NavItemLink } from './NavItemLink'

interface Props {
	view: mdl.SearchView
	context: Context
}

@observer
export class Search extends Component<Props> {

	render() {
		const { context, context: { nav }, view } = this.props
		return <div className="view">
			<header>
				<button onClick={nav.back}><Icon uri="navBack" /></button>
				<div><span><i>Search</i></span></div>
			</header>
			<section>
				<div className="content">
					<div className="field">
						<TextareaInput target={view} member="searchText"
							placeholder="Text to search items with..."
							autoFocus />
					</div>
				</div>
				<div className="items">
					<Observer>{() => <>
						{view.results.map(item => item.isReady ?
							<NavItemLink key={item.id} context={context} item={item} /> :
							<div key={item.id} className="loading">loading...</div>)}
						{view.results.length <= 0 && '-'}
					</>}</Observer>
				</div>
			</section>
			<footer />
		</div>
	}

	@action onEntry = (evn: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.props.view.searchText = evn.target.value
	}

	onDocumentKey = (evn: React.KeyboardEvent<HTMLBodyElement>) => {
		const { context: { nav } } = this.props
		switch (evn.key) {
			case 'Escape': nav.back(); break
		}
		// only outside of text inputs
		if (evn.target === document.body)
			switch (evn.key) {
				case 'h': nav.go('view', 'home'); break
			}
	}
}
