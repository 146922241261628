import * as React from 'react'
import * as mdl from '../../model'
import { Block, css, EnumInput, Icon, TextInput } from '../common'
import { PropertyView } from './common'

const protocols = [
	{ label: 'CouchDB', value: 'couch' },
]

export const storage: PropertyView<mdl.BoxStorage> = {
	icon: ({ value }) =>
		<span className={css('icon', !value.isAvailable && 'passive')}
			title={value.isAvailable ? `${value.allBoxes.length} box(es)` :
				value.credential ? 'Currently not available.' : 'Account missing!'}>
			<Icon uri="storage" />
		</span>,
	content: ({ value }) => <div>
		<Block label="URL">{value.url}</Block>
		<Block label="Protocol">{value.protocol}</Block>
		<Block label="Actions">
			<button onClick={value.toggleActive}>
				{value.isActive ? 'Deactivate' : 'Activate'}</button>
			{!value.isLocal && !value.account &&
				<button onClick={value.createAccount.start}>Create Account</button>}
			<button onClick={value.addBoxes} disabled={value.addBoxes.isActive}
				title="Find and add additional boxes available in this storage"
				style={value.addBoxes.isActive ? { cursor: 'wait' } : null}
			>Scan for Boxes</button>
		</Block>
		<Block label="Reports" if={value.reports?.entries.length > 0}>
			<pre>{value.reports.entries.join('\n')}</pre>
			<button onClick={value.reports.clear}>Clear</button>
		</Block>
	</div>,
	editor: ({ name, label }, { values }, ctx, autoFocus) =>
		mdl.BoxStorage.isLocal(values[name]['protocol'].val) ?
			<div>
				<Block label="URL">{values[name]['url'].val}</Block>
				<Block label="Protocol">{values[name]['protocol'].val}</Block>
			</div> :
			<Block label={label}>
				<EnumInput label="Protocol" value={values[name]['protocol']}
					autoFocus={autoFocus} options={protocols} />
				<TextInput label="URL" value={values[name]['url']} />
			</Block>,
}
