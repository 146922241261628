import { computed, observable } from 'mobx'
import { Item } from './Item'

// TODO: arrays and objects? with element types? => complex, structured types?

export type PropertyType = 'string' | 'text' | 'url' |
	'image' | 'icon' | 'icons' | 'color' |
	'date' | 'time' | 'dateTime' | 'duration' |
	'number' | 'boolean' | 'enum' | 'location' |
	'blob' | 'object' |
	'box' | 'storage' | 'account' |
	'installation' | 'query' | 'action'

export const searchablePropertyTypes: { [k in PropertyType]?: any } =
	{ string: 1, text: 1, url: 1, enum: 1 }

export const stringablePropertyTypes: { [k in PropertyType]?: any } =
	{ string: 1, text: 1, url: 1, enum: 1, color: 1, number: 1, boolean: 1 }

// TODO: now as default for date, time, dateTime props

export class Property<T extends any = any> {
	// TODO: implement immutable properties
	@observable.ref value: T
	type: PropertyType
	defaultValue?: T
	private _label?: string
	constructor(public item: Item, public name: string,
		value: T, type?: PropertyType,
		label?: string, defaultValue?: T, public hidden = false) {
		this.value = value
		this.defaultValue = defaultValue
		this._label = label
		this.type = type ?? propertyTypeForValue(value)
	}

	options?: T[]

	get label() {
		return this._label ??
			this.name.substring(0, 1).toLocaleUpperCase() + this.name.substring(1)
	}

	get stringValue() {
		return this.type in stringablePropertyTypes ? '' + this.value : this.type
	}

	setValue(v: T, type: PropertyType) {
		this.value = v
		this.type = type
	}

	get $debug() {
		const d = { name: this.name, type: this.type, value: this.value }
		if (this.value && typeof this.value === 'object' && '$debug' in this.value)
			d.value = this.value['$debug']
		return d
	}

}

function propertyTypeForValue(val: any) {
	if (!val && val !== 0 && val !== '') return null
	const n: string = val.constructor.name
	return n.charAt(0).toLowerCase() + n.substring(1) as PropertyType
}


