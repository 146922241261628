import { urlPattern, Utils } from '../../../common'
import * as mdl from '../../../model'

const textRules = [
	{	// Image URL
		pattern: urlPattern.image,
		setProps: (item: mdl.Item, m: RegExpMatchArray, tmpl: mdl.Item) => {
			const p = tmpl.props.findByType(
				'image', 'icon', 'url', 'string', 'text') ??
				{ name: 'image', type: 'image' }
			item.props.set(p.name, m[0], p.type)
		}
	},
	{	// URL
		pattern: urlPattern.basic,
		setProps: (item: mdl.Item, m: RegExpMatchArray, tmpl: mdl.Item) => {
			const p = tmpl.props.findByType('url', 'string', 'text') ??
				{ name: 'url', type: 'url' }
			item.props.set(p.name, m[0], p.type)
		}
	},
]

export function setPropsFromText(item: mdl.Item, txt: string,
	items: mdl.ItemManager, tmpl: mdl.Item) {
	for (const r of textRules) {
		const m = r.pattern.exec(txt)
		if (m) {
			r.setProps(item, m, tmpl)
			return item
		}
	}
	const p = tmpl.props.findByType('text', 'string', 'enum',
		'number', 'boolean', 'color')
	if (p)
		item.props.set(p.name, txt, p.type)
	else if (txt.length > 32)
		item.props.set('text', txt, 'text')
	else
		item.props.set('title', txt)
	return item
}
