import * as mdl from '../model'
import { propertyToData } from './properties'
import * as tmpls from './tmpl'
import { isVal } from './tmpl/common'

export function buildData(item: mdl.Item, inclMeta = true) {
	if (!item) return null
	const data: mdl.ItemData = { id: item.id, props: {} }
	for (const name of item.props.keys()) {
		const p = item.props.get(name)
		if (p.value || p.value === 0 || p.value === false)
			data.props[name] = propertyToData(p)
	}
	if (item.layoutId)
		data.layoutId = item.layoutId
	const createLog = setValues(tmpls.logTmpl(item.create))
	if (createLog)
		data.create = createLog
	if (!item.links.isEmpty) {
		data.links = item.links.map(ln => {
			const itm = ln.item
			const ref = itm ? itm.id : ln.url
			return ln.preview === 'normal' && !ln.name ? ref
				: {
					preview: ln.preview === 'normal' ? void 0 : ln.preview,
					name: ln.name,
					url: ref,
				}
		})
	}
	if (!item.tmpls.isEmpty)
		data.tmpls = item.tmpls.map(ln => ln.item?.id ?? ln.url)
	if (inclMeta) {
		addUpdateLog(data, item)
		if (item.rev)
			data.rev = item.rev
	}
	return data
}

export function addUpdateLog(data: mdl.ItemData, item: mdl.Item) {
	const updateLog = setValues(tmpls.logTmpl(item.update))
	if (updateLog) data.update = updateLog
	return data
}

function setValues(tmpl: any, data?: {}) {
	for (const k of Object.keys(tmpl)) {
		const t = tmpl[k]
		if (isVal(t)) {
			let v = t.obj[t.key]
			if (v !== void 0 && t.type && t.type.format) v = t.type.format(v)
			if (v !== void 0) {
				if (!data) data = {}
				data[k] = v
			}
		}
		else {
			const d = setValues(t)
			if (d) {
				if (!data) data = {}
				data[k] = d
			}
		}
	}
	return data
}
