import * as React from 'react'
import { Component, css, observer } from '..'
import * as mdl from '../../../model'
import placeholder from '../../../style/placeholder.svg'

interface Props {
	value: mdl.ImageValue
	objectUrlCache?: string[]
	className?: string
	title?: string
}

@observer
export class Image extends Component<Props> {

	render() {
		const { value, objectUrlCache, className, title } = this.props
		const blob = mdl.ImageValue.blob(value)
		const src = blob ? URL.createObjectURL(blob) :
			mdl.ImageValue.url(value) || placeholder
		if (blob && objectUrlCache) objectUrlCache.push(src)
		return <div
			className={css('img', className, mdl.ImageValue.className(value))}>
			<img src={src} onLoad={objectUrlCache ? null : onLoad}
				title={title} />
		</ div>
	}

}

function onLoad(evn: React.SyntheticEvent<HTMLImageElement>) {
	URL.revokeObjectURL(evn.currentTarget.src)
}


