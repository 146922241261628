import { ObjectType } from './common'

type ErrorData = string |
{ error?: ErrorData, message?: string, name?: string, stack?: string[] }

export class Utils {

	static tryParseJsonObject(str: string) {
		if (!str)
			return null
		str = str.trim()
		if (str.startsWith('{') && str.endsWith('}')) {
			try {
				return JSON.parse(str)
			}
			catch (err) {
				return null
			}
		}
		return null
	}

	static delayAsyncFn<F extends (...args: any[]) => Promise<any>>(
		fn: F, millis: number): F {
		return function() {
			return new Promise((res, rej) => {
				setTimeout(() => { fn.apply(this, arguments).then(res, rej) }, millis)
			})
		} as F
	}

	static charCount(str: string) {
		let r = 0
		for (const c of [...str]) {
			// https://www.charbase.com/200d-unicode-zero-width-joiner
			if (c === '\u200d')
				r--
			else
				r++
		}
		return r
	}

	static isJpeg(url: string) {
		const n = new URL(url).pathname
		return n.endsWith('.jpg') || n.endsWith('.jpeg')
	}

	static firstToLowerCase(str: string) {
		return str.charAt(0).toLowerCase() + str.substring(1)
	}
	static firstToUpperCase(str: string) {
		return str.charAt(0).toUpperCase() + str.substring(1)
	}
	private static _isWalking = '_isWalking'

	static isPrimitive(v: any) { return v !== Object(v) }

	static isBlob(v: any): v is Blob {
		return typeof Blob === 'undefined' ? false : v instanceof Blob
	}

	static isBuffer(v: any): v is Buffer {
		return typeof Buffer === 'undefined' ? false : v instanceof Buffer
	}

	static base64ToBlob(str: string, contentType: string) {
		if (!str)
			return null
		const byteChars = atob(str)
		const byteCount = byteChars.length
		const byteArray = new Uint8Array(byteCount)
		for (let i = 0; i < byteCount; ++i)
			byteArray[i] = byteChars.charCodeAt(i)
		return new Blob([byteArray], { type: contentType })
	}

	static async blobToBase64(blob: Blob) {
		const s = await Utils.blobToDataUrl(blob)
		return s ? s.substring(s.indexOf(';base64,') + 8) : null
	}

	static blobToDataUrl(blob: Blob) {
		return new Promise<string>((res, rej) => {
			if (blob) {
				const r = new FileReader()
				r.onerror = () => { rej(r.error) }
				r.onload = () => { res(r.result as string) }
				r.readAsDataURL(blob)
			} else {
				res(null)
			}
		})
	}

	static url(url: string, params: {}) {
		return url + '?' + new URLSearchParams(params)
	}

	static absoluteUrl(url: string, base: string) {
		return new URL(url, base).href
	}

	static addUrlParams(url: string, params?: {}) {
		if (params)
			url += '?' + new URLSearchParams(params)
		return url
	}

	static deepEquals(a: any, b: any,
		excludes?: string[] | { [k: string]: any; }) {
		if (a === b)
			return true
		if (Utils.isPrimitive(a))
			return a === b
		if (Utils.isPrimitive(b))
			return false
		if (excludes && Array.isArray(excludes))
			excludes = Utils.arrayToObject(excludes)
		const keysA = Object.keys(a)
			.filter(k => !(excludes && k in excludes))
			.filter(k => a[k] !== void 0)
		const keysB = Object.keys(b)
			.filter(k => !(excludes && k in excludes))
			.filter(k => b[k] !== void 0)
		if (keysA.length !== keysB.length)
			return false
		for (const k of keysA) {
			if (!(k in b))
				return false
			if (!Utils.deepEquals(a[k], b[k], excludes))
				return false
		}
		return true
	}

	// no cyclic references!
	static deepCopyJson(obj: any): any {
		return JSON.parse(JSON.stringify(obj))
	}

	static clone(obj: any): any {
		const clone = Utils._clone(obj)
		Utils._cleanupWalk(obj)
		return clone
	}

	private static _clone(obj: any): any {
		if (obj === null || typeof obj !== 'object')
			return obj
		if (obj.constructor === Date || obj.constructor === RegExp ||
			obj.constructor === Function || obj.constructor === String ||
			obj.constructor === Number || obj.constructor === Boolean)
			return new obj.constructor(obj)
		if (Utils._isWalking in obj)
			return obj[Utils._isWalking]
		const clone = new obj.constructor()
		obj[Utils._isWalking] = clone
		for (let k in obj)
			if (k !== Utils._isWalking)
				clone[k] = Utils._clone(obj[k])
		return clone
	}

	static diff(obj1: any, obj2: any): any {
		const obj = Utils._diff(obj1, obj2)
		Utils._cleanupWalk(obj1)
		return obj || {}
	}

	private static _diff(obj1: any, obj2: any): any {
		if (obj1 === obj2)
			return
		const t1 = typeof obj1
		const t2 = typeof obj2
		if (t1 === 'function' || t2 === 'function')
			return
		if (t1 === 'undefined')
			return { old: obj2 }
		if (t2 === 'undefined')
			return { new: obj1 }
		if (obj1 === null || t1 === 'string' || t1 === 'number' ||
			t1 === 'boolean' || t1 === 'symbol')
			return { new: obj1, old: obj2 }
		if (Utils._isWalking in obj1)
			return
		obj1[Utils._isWalking] = true
		const obj: any = {}
		let isEmpty = true
		for (let k in obj1) {
			if (k === Utils._isWalking)
				continue
			const v = Utils._diff(obj1[k], obj2[k])
			if (v !== void 0) {
				obj[k] = v
				isEmpty = false
			}
		}
		for (let k in obj2) {
			if (k in obj1)
				continue
			const v = Utils._diff(obj1[k], obj2[k])
			if (v !== void 0) {
				obj[k] = v
				isEmpty = false
			}
		}
		return isEmpty ? void 0 : obj
	}

	// Clear a specific member within an object.
	// Also removes members with empty objects or undefined values.
	static deepClearMember(obj: any, memberName: string): any {
		obj = Utils.clone(obj)
		Utils._deepClearMember(obj, memberName)
		Utils._cleanupWalk(obj)
		return obj
	}

	private static _deepClearMember(obj: any, memberName: string) {
		if (obj === void 0)
			return true
		if (typeof obj !== 'object')
			return false
		// TODO: clean up references to possible empty walked objects
		if (Utils._isWalking in obj)
			return false
		obj[Utils._isWalking] = true
		let isEmpty = true
		for (let k in obj) {
			if (k === Utils._isWalking)
				continue
			if (k === memberName)
				delete obj[k]
			else {
				if (Utils._deepClearMember(obj[k], memberName))
					delete obj[k]
				else
					isEmpty = false
			}
		}
		return isEmpty
	}

	private static _cleanupWalk(obj: any) {
		if (typeof obj === 'object' && Utils._isWalking in obj) {
			delete obj[Utils._isWalking]
			for (let k in obj)
				Utils._cleanupWalk(obj[k])
		}
	}

	static isEmptyObject(obj: any): boolean {
		for (let k in obj)
			return false
		return true
	}

	/** Returns true if the given value v is true. Reusable v => !!v */
	static isTrue<T>(v: T): boolean { return !!v }

	static compareStrings<T>(selectFn: (e: T) => string) {
		return (a: T, b: T) => selectFn(a).localeCompare(selectFn(b))
	}

	static copyValues(source: any, destination: any): any {
		for (var key in source) {
			if (key[0] == '_')
				continue
			var v = source[key]
			if (ObjectType.isValue(v))
				destination[key] = v
		}
		return destination
	}

	static copyMembers<T>(source: T, destination: Partial<T>,
		...keys: (keyof T)[]) {
		for (const k of keys)
			if (k in source)
				destination[k] = source[k]
		return destination
	}

	static key2Val<T>(obj: T): T {
		for (let k of Object.keys(obj))
			obj[k] = k
		return obj
	}

	static createObj(constructorFn, args?: any[]) {
		if (constructorFn === String)
			return args && args.length > 0 && args[0] ? args[0] : ''
		if (constructorFn === Number)
			return args && args.length > 0 && args[0] ? args[0] : 0
		if (constructorFn === Boolean)
			return args && args.length > 0 && args[0] ? args[0] : false
		if (constructorFn === Array)
			return args ? args : []
		if (args)
			return new (constructorFn.bind.apply(constructorFn, [null].concat(args)))
		return new constructorFn()
	}

	static decodeUriEncodedFormData(formData: string): any {
		var param = {}
		var tks = formData.split('&')
		for (var i = 0, tk: string; (tk = tks[i]); ++i) {
			var s = tk.indexOf('=')
			if (0 <= s)
				param[decodeURIComponent(tk.substring(0, s))] =
					decodeURIComponent(tk.substring(s + 1))

			else
				param[decodeURIComponent(tk)] = true // or ""?
		}
		return param
	}


	static readonly ALPHABET_BASE32_CROCKFORD =
		'0123456789abcdefghjkmnpqrtuvwxyz'.split('')

	// Encode positive integer (0..2147483647) to base32 
	// string (eg. '0'..'1zzzzzz').
	// Integer numbers < 0 or > 2147483647 return an empty string.
	static encodeBase32(num: number, alphabet = Utils.ALPHABET_BASE32_CROCKFORD) {
		if (num == 0)
			return '0'
		num = num >> 0
		let str = ''
		while (num > 0) {
			str = alphabet[num & 0x1f] + str
			num = num >> 5
		}
		return str
	}

	static decodeBase32(str: string, alphabet = Utils.ALPHABET_BASE32_CROCKFORD) {
		let num = 0
		for (const c of str) {
			num = num << 5
			num += alphabet.indexOf(c)
		}
		return num
	}

	static encodeBase64(str: string): string {
		return str ? window.btoa(str) : ''
	}

	static decodeBase64(base64: string): string {
		return base64 ? window.atob(base64) : ''
	}

	static runWithNextTick(fn: (...args) => any);
	static runWithNextTick(fn: (...args) => any, context: any, ...args);
	static runWithNextTick(fn: (...args) => any, context?: any, ...args) {
		if (context)
			window.setTimeout(() => { fn.apply(context, args) })
		else
			window.setTimeout(fn)
	}

	static wrapForNextTick(fn: (...args) => any, context?: any): (...any) => any {
		return function(...args) {
			window.setTimeout(() => {
				fn.apply(context || this, args)
			})
		}
	}

	static getStackTrace(levelUp = 0): string[] {
		//try { throw new Error() } catch (err) { 
		//return err.stack.split('\n').slice(levelUp + 2) }
		return (<any>new Error()).stack.split('\n').slice(levelUp + 2)
	}

	/** Run fn after the promise (promise.then(fn)) or directly fn(promise),
	 *  if not a promise. */
	static after<T = void, R = void>(promise: T | Promise<T>, fn: (v: T) => R) {
		if (promise instanceof Promise)
			return promise.then(fn)
		else
			return fn(promise)
	}

	static mapKeys<T extends {}>(obj: T, fn: (k, v) => string) {
		const res = {}
		for (const k of Object.keys(obj)) {
			const v = obj[k]
			res[fn(k, v)] = v
		}
		return res
	}

	static addMoveFirst<T>(arr: T[], v: T,
		maxLen?: number, removeCb?: (v: T) => void) {
		const idx = arr.indexOf(v)
		if (idx !== 0) {
			if (idx > 0)
				arr.splice(idx, 1)
			arr.unshift(v)
		}
		if (maxLen !== void 0) {
			if (removeCb)
				for (let i = maxLen, len = arr.length; i < len; ++i)
					removeCb(arr.pop())

			else
				for (let i = maxLen, len = arr.length; i < len; ++i)
					arr.pop()
		}
	}

	static getAllPropertyNames(obj: any) {
		if (!obj || typeof obj !== 'object')
			return []
		const res = {}
		while (obj) {
			for (const k of Object.getOwnPropertyNames(obj))
				res[k] = 1
			obj = Object.getPrototypeOf(obj)
		}
		return Object.keys(res)
	}

	static asEnumerableProperties(obj: any, inclFunctions = true) {
		if (!obj || typeof obj !== 'object')
			return obj
		const res = {}
		for (const k of Utils.getAllPropertyNames(obj))
			if (inclFunctions || typeof obj[k] !== 'function')
				res[k] = obj[k]
		return res as any
	}

	static substringBefore(str: string, match: string) {
		if (typeof str !== 'string')
			return str
		const idx = str.indexOf(match)
		return idx >= 0 ? str.substring(0, idx) : null
	}

	static substringAfter(str: string, match: string) {
		if (typeof str !== 'string')
			return str
		const idx = str.indexOf(match)
		return idx >= 0 ? str.substring(idx + match.length) : null
	}

	static errorToJson(err: Error | string, inclStack = true)
		: { message: string, name?: string, stack?: string[] } {
		return !err ? null : typeof err === 'string'
			? {
				message: err,
			}
			: {
				...Utils.asEnumerableProperties(err, false),
				stack: inclStack ? err.stack && err.stack.split
					? err.stack.split('\n').map(v => v.trim()) : err.stack : void 0,
			}
	}

	static errorToString(err: Error, inclStack = true,
		indent?: string, level = 0) {
		return Utils.stringify(Utils.errorToJson(err, inclStack), indent, level)
	}

	static extractErrorMessage(data: ErrorData) {
		return typeof data === 'string' ?
			data.substring(0, 120) + (data.length > 120 ? '...' : '') :
			'error' in data ? Utils.extractErrorMessage(data.error) :
				'message' in data ? data.message :
					'stack' in data && data.stack.length > 0 ? data.stack[0] :
						'name' in data ? data.name :
							Utils.findMemberValue(data,
								v => typeof v === 'string' && v.length > 2) ??
								data ? JSON.stringify(data) : null
	}

	static isDate(val: any): val is Date {
		return val instanceof Date && !isNaN(val.getTime())
	}

	static daysInMonth(date: Date) {
		if (date instanceof Date)
			return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
	}

	static sameDate(a: Date, b: Date) {
		return a instanceof Date && b instanceof Date &&
			a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() &&
			a.getDate() === b.getDate()
	}

	static toIsoDateString(d: Date) {
		return d ? d.toLocaleDateString('sv') : null
	}

	static parseIsoDate(str: string) {
		return str ? new Date(str + 'T00:00:00.00' +
			Utils.toIsoTzString(new Date(str))) : null
	}

	static toIsoTimeString(d: Date) {
		return Utils.toIsoDateTimeString(d).substring(11)
	}

	static parseIsoTime(str: string) {
		return str ? new Date('2020-02-02T' + str) : null
	}

	static toIsoDateTimeString(d: Date) {
		const ms = d.getMilliseconds()
		return d.toLocaleString('sv').replace(' ', 'T') + (ms > 0 ? '.' + ms : '') +
			Utils.toIsoTzString(d)
	}

	static parseIsoDateTime(str: string) {
		return str ? new Date(str) : null
	}

	static toIsoTzString(d: Date) {
		const tzo = -d.getTimezoneOffset()
		const s = tzo === 0 ? 'Z' : tzo > 0 ? '+' : '-'
		return s + Utils.padAbsOne(tzo / 60) + ':' + Utils.padAbsOne(tzo % 60)
	}

	static padAbsOne(num: number) {
		const n = Math.floor(Math.abs(num))
		return (n < 10 ? '0' : '') + n
	}

	/** Weekday starting with Monday (1) to Sunday (7). */
	static weekday(date: Date) {
		if (date instanceof Date) {
			const d = date.getDay()
			return d === 0 ? 7 : d
		}
	}

	static stringify(val: any, indent?: string, level = 0) {
		const t = typeof val
		if (level > 100)
			return '-- stringify: maximal depth level exceeded! --'
		if (t === 'function')
			return ''
		if (t === 'string')
			return '"' + val + '"'
		if (t !== 'object')
			return '' + val
		if (val === null)
			return 'null'
		if (Utils.isDate(val))
			return '"' + val.toISOString() + '"'
		if (val instanceof Error)
			return Utils.errorToString(val, true, indent, level)
		if (Object.prototype.toString.call(val) === '[object Array]' &&
			'map' in val)
			return '[' + val.map(v => Utils.stringify(v, indent, level + 1))
				.join(indent ? ', ' : ',') + ']'
		const keys = []
		for (const k in val)
			keys.push(k)
		if (keys.length <= 0)
			return '{}'
		keys.sort()
		const members = []
		for (const k of keys) {
			const s = Utils.stringify(val[k], indent, level + 1)
			if (s)
				members.push(k + ':' + (indent ? ' ' : '') + s)
		}
		const nl = indent ? '\n' + indent.repeat(level + 1) : ''
		return `{${nl}${members.join(',' + nl)}${indent ?
			'\n' + indent.repeat(level) : ''}}`
	}

	/** Mask an object with the members of another object (mask).
	 * Array values of the mask get appended to the values of the objects member.
	 */
	static mask<T>(obj: T, mask): T {
		if (obj === void 0 || obj === null)
			return mask
		if (Array.isArray(obj))
			return Array.isArray(mask) ? [...obj, ...mask] : mask !== void 0 ?
				[...obj, mask] : [...obj] as any
		if (typeof obj === 'object') {
			const res = {} as T
			for (const k of Object.keys(obj)) {
				const v = obj[k]
				if (k in mask) {
					const m = mask[k]
					res[k] = Utils.mask(v, m)
				}

				else
					res[k] = v
			}
			for (const k of Object.keys(mask)) {
				if (!(k in obj))
					res[k] = mask[k]
			}
			return res
		}
		return mask
	}

	static parseNumbers(str: string) {
		return str ? str.split(',').map(v => parseInt(v))
			.filter(v => !isNaN(v)) : []
	}

	/** Enumerate object members as a comma separated string.
	 * Values can be primitives and single member objects/arrays.
	 */
	static enumerateMembers(obj: any, indent?: string) {
		return !obj ? '' :
			Object.keys(obj).map(k => k + ': ' + Utils.stringify(obj[k], indent))
				.join(indent ? ',\n' : ', ')
	}

	static readonly isoDatePattern = /^["']\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)["']$/

	/** Parse a comma separated string into object members.
	 * Values can be primitives and single member objects/arrays.
	 */
	static parseMembers(str: string, obj = {}) {
		for (const s of str.split(',')) {
			if (str) {
				const idx = s.indexOf(':')
				const m = s.substring(0, idx).trim()
				const v = s.substring(idx + 1).trim()
				const l = v.length - 1
				const v0 = v.charAt(0)
				const vL = v.charAt(l)
				obj[m] = v0 === '{' && vL === '}' ?
					Utils.parseMembers(v.substring(1, l)) :
					Utils.isoDatePattern.test(v) ? new Date(v.substring(1, l)) :
						v0 === "'" && vL === "'" ?
							JSON.parse('"' + v.substring(1, l).replace(/"/g, "'") + '"') :
							JSON.parse(v)
			}
		}
		return obj
	}

	static assignDeep<T extends {}>(target: T, src: {}) {
		for (const k of Object.keys(src)) {
			const v = src[k]
			if (k in target && typeof v === 'object')
				Utils.assignDeep(target[k], v)
			else
				target[k] = v
		}
		return target
	}

	static filterMembers<T extends {}, K extends keyof T>(src: T,
		fn: (k: K, v: T[K], obj: T) => boolean) {
		const res = {} as Partial<T>
		for (const k of Object.keys(src) as K[]) {
			const v = src[k]
			if (fn(k, v, src))
				res[k] = v
		}
		return res
	}

	static filterMembersDeep<T extends {}>(src: T,
		fn: (k: string, v: any, obj: T) => boolean) {
		if (typeof src !== 'object')
			return src
		const res = {} as Partial<T>
		for (const k of Object.keys(src)) {
			const v = src[k]
			if (fn(k, v, src))
				res[k] = Utils.filterMembersDeep(v, fn)
		}
		return res
	}

	static mapMembers<T extends {}, K extends keyof T>(src: T,
		fn: (v: T[K], k: K, obj: T) => any) {
		const res = {} as {
			[k in K]: any;
		}
		for (const k of Object.keys(src) as K[])
			res[k] = fn(src[k], k, src)
		return res
	}

	/** Deletes specified members in the specified object. */
	static deleteMembers<T extends {}, K extends keyof T>(obj: T, ...keys: K[]) {
		for (const k of keys)
			delete obj[k]
		return obj
	}

	static findMemberKey<T extends {}, K extends keyof T>(src: T,
		fn: (v: T[K], k: K, obj: T) => boolean) {
		for (const k of Object.keys(src) as K[])
			if (fn(src[k], k, src))
				return k
		return null
	}

	static findMemberValue<T extends {}, K extends keyof T>(src: T,
		fn: (v: T[K], k: K, obj: T) => boolean) {
		for (const k of Object.keys(src) as K[])
			if (fn(src[k], k, src))
				return src[k]
		return null
	}

	static isPromise(o: any): o is Promise<any> {
		return typeof o === 'object' && 'then' in o && typeof o.then === 'function'
	}

	static wait<T>(time: number, returnValue?: T) {
		return new Promise<T>(r => { setTimeout(r, time, returnValue) })
	}

	static valueCounts(values: string[]): { [k: string]: number; };
	static valueCounts(values: number[]): { [k: number]: number; };
	static valueCounts(values: any[]) {
		return values.reduce((counts, v) =>
			({ ...counts, [v]: (counts[v] || 0) + 1 }), {})
	}

	static keyWithMaxValue<T>(obj: T) {
		let max: any, key: keyof T
		for (const k of Object.keys(obj)) {
			const v = obj[k]
			if (max === void 0 || v > max) {
				max = v
				key = k as keyof T
			}
		}
		return key
	}

	static valueWithMaxCount<T extends string | number>(values: T[]) {
		return Utils.keyWithMaxValue(Utils.valueCounts(values as any)) as T
	}

	static arrayToObject<T extends string | number, V>(arr: T[], val?: V): {
		[k in T]: V;
	};
	static arrayToObject<T, K extends string | number, V>(arr: T[],
		keyFn: (v: T) => K, val?: V | ((v: T) => V)): {
			[k in K]: V;
		};
	static arrayToObject(arr: any[], keyFn?: (v: any) => string | number,
		val?: any | ((v: any) => any)) {
		const obj = {}
		if (!arr)
			return obj
		if (typeof keyFn === 'function') {
			for (const v of arr) {
				const k = keyFn(v)
				obj[k] = typeof val === 'function' ? val(v) : val ?? v
			}
		} else {
			for (const v of arr) {
				const k = String(v)
				obj[k] = keyFn ?? k
			}
		}
		return obj
	}

	/** String key Map to object. Allows to map the values also. */
	static mapToObject<V>(src: Map<string, V>, fn?: (v: V, k: string) => any) {
		const res = {} as { [k: string]: any; }
		for (const k of src.keys())
			res[k] = fn ? fn(src.get(k), k) : src.get(k)
		return res
	}

	/** Flatten one level nested array.
	 * `nestedArray.reduce(Utils.flatArray, [])`
	 */
	static flatArray<T>(array: T[], element: T[]) {
		return array.concat(element)
	}

	/** Distinct values. Compares with ===.
	 * `doubledArray.filter(Utils.distinct)`
	 */
	static distinct<T>(v: T, idx: number, arr: T[]) {
		return arr.indexOf(v) === idx
	}

	/** Distinct with key extractor.
	 * `doubledArray.filter(Utils.distinctKey(e => e.id))`
	 * @param keyFn Function to extract key from element.
	 */
	static distinctKey<T>(keyFn: (element: T) => string) {
		const matches = {}
		return (v: T, idx: number, arr: T[]) => {
			const k = keyFn(v)
			if (k in matches)
				return false
			matches[k] = true
			return true
		}
	}

	/** Side effect for each element of an array.
	 * Kind of inline for-each.
	 * `array.map(Utils.each(e => { console.log(e.id) }))`
	 * Short form for
	 * `array.map(e => { console.log(e.id); return e })`
	 * TODO: deprecate
	 * @param fn Function to call for each element.
	 */
	static each<T>(fn: (element: T) => void) {
		return (v: T, idx: number, arr: T[]) => {
			fn(v)
			return v
		}
	}

	static toId<T>(obj: { id: T; }) {
		return obj.id
	}

	/** Array.findIndex starting at the end of the array. */
	static findLastIndex<T>(arr: T[],
		predicate: (element: T, idx: number, array: T[]) => boolean,
		thisArg: object = null) {
		for (let i = arr.length - 1; i >= 0; i--)
			if (predicate.call(thisArg, arr[i], i, arr))
				return i
		return -1
	}

	/** Array.indexOf and Array.splice(idx, 1). */
	static remove<T>(arr: T[], elem: T) {
		const idx = arr.indexOf(elem)
		const contained = idx >= 0
		if (contained)
			arr.splice(idx, 1)
		return contained
	}

	static addUnique<V>(values: V[], value: V) {
		if (!values)
			return [value]
		if (values.indexOf(value) < 0)
			values.push(value)
		return values
	}

	static intersect<T extends string | number>(arr1: T[], arr2: T[]): T[] {
		if (arr1.length > arr2.length) {
			const tmp = arr1
			arr1 = arr2
			arr2 = tmp
		}
		const map = Utils.arrayToObject(arr1)
		const res: T[] = []
		for (let i = 0, l = arr2.length; i < l; ++i) {
			const v = arr2[i]
			if (v in map)
				res.push(v)
		}
		return res
	}

}

