import { action, computed, observable } from './common'
import { Item } from './Item'

// Who, when, where.

export class Log {
	private _userId: string
	@computed get userId() { return this._userId || this.user?.id }
	set userId(v: string) { this._userId = v }
	@observable user: Item
	@observable date: Date
	private _installationId: string
	@computed get installationId() {
		return this._installationId || this.installation?.id
	}
	set installationId(v: string) { this._installationId = v }
	@observable installation: Item
	@observable.ref position: {
		/** Accuracy in meters */
		accuracy?: number
		/** Altitude in meters above sea level */
		alt?: number
		/** Altitude accuracy in meters */
		altAccuracy?: number
		/** Traveling direction in degrees (0° north, 90° east,
		 * 180° south, 270° west) */
		heading?: number
		/** Latitude in decimal degrees */
		lat?: number
		/** Longitude in decimal degrees */
		long?: number
		/** Traveling speed in m/s */
		speed?: number
	}
}

export class StatusReports {
	@observable entries: string[] = []
	@action add(rec: string) {
		this.entries.push(new Date(Date.now()).toISOString() + ': ' + rec)
	}
	@action clear = () => { this.entries.length = 0 }
}
