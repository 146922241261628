import { observer } from 'mobx-react'
import * as React from 'react'
import { propertyToData } from '../../../base/src/data/properties'
import { Utils } from '../common'
import * as mdl from '../model'
import {
	Block, Component, dateTimeFormat, enumerate, Icon, Link, Menu
} from './common'
import { ItemContent, ItemLabel } from './LayoutViews'

interface Props {
	view: mdl.DetailsView
	context: {
		config: mdl.Config
		nav: mdl.Navigation
		ui: mdl.UserInterface
	}
}

@observer
export class Details extends Component<Props> {

	render() {
		const { context, context: { nav }, view: { item } } = this.props
		return <div className="view">
			<header>
				<button onClick={nav.back}><Icon uri="navBack" /></button>
				<div>
					<span>
						<i>details of </i>
						<ItemLabel item={item} context={context} />
					</span>
				</div>
				<Menu>
					<div onClick={this.onEdit}>edit</div>
					<div onClick={this.onCopy}><Icon uri="copy" /> copy</div>
					<div onClick={this.onPlace}>place</div>
					<div onClick={this.onDelete}>delete</div>
				</Menu>
			</header>
			<section>
				<div className="content">
					<Block label="ID">{item.id}, revision: {item.rev}</Block>
					<Block label="Properties">
						<pre>
							{Utils.enumerateMembers(
								Utils.mapMembers(item.props.asObject, propertyToData), '  ')}
						</pre>
					</Block>
					<Log label="Created" log={item.create} nav={nav} />
					<Log label="Updated" log={item.update} nav={nav} />
					{item.links && <Block label="Links">
						{enumerate(item.links.map(l => l.item && !l.item.isMissing ?
							<Link nav={nav} itemId={l.item.id} /> : <span>{l.url}</span>),
							', ')}
						<span className="note"> available: {item.links.available.length
						} of {item.links.length}</span>
					</Block>}
					<Block label="Boxes">
						{enumerate(item.boxes.map(b =>
							<Link nav={nav} itemId={b.id}>
								<ItemLabel item={b.item} context={context} />
							</Link>), ', ')}
					</Block>
					{item._info && <Block label="Storage Info">
						<pre>{Utils.enumerateMembers(item._info, '  ')}</pre>
					</Block>}
					{item.conflicts && <Block label="Conflicts">
						{item.conflicts.map((c, idx) =>
							<pre key={idx}>{Utils.stringify(c, '  ')}</pre>)}
					</Block>}
					<Block label="Search Text"><pre>{item.searchText}</pre></Block>
				</div>
			</section>
			<footer>
				<span>Item: {item.id}
					{item.create.date &&
						`, created at ${dateTimeFormat.format(item.create.date)}`}
					{item.conflicts && ', has conflicts!'}
				</span>
			</footer>
		</div>
	}

	onEdit = () => { this.props.context.nav.go('edit') }
	onPlace = () => { this.props.context.nav.go('place') }
	onDelete = () => {
		const { view, context: { nav } } = this.props
		view.item.delete()
		nav.back()
	}
	onCopy = () => {
		const { view, context: { ui, nav } } = this.props
		ui.clipboard = [view.item]
		nav.back()
	}
	onDocumentKey = (evn: React.KeyboardEvent<HTMLBodyElement>) => {
		const { context: { nav } } = this.props
		switch (evn.key) {
			case 'h': nav.go('view', 'home'); break
			case 'e': this.onEdit(); break
			case 'p': this.onPlace(); break
			case 'Escape': nav.back(); break
		}
	}
}

@observer
class Log extends
	Component<{ label: string, log: mdl.Log, nav: mdl.Navigation }> {

	render() {
		const { label, log, nav } = this.props
		const userLabel = log.user?.props.label ? log.user.props.label.value :
			log.userId
		const installationLabel = log.installation?.props.label ?
			log.installation.props.label.value :
			log.installationId && `Installation ${log.installationId}`
		return <Block label={label}>
			on {dateTimeFormat.format(log.date)}
			{userLabel && <><br />by <Link nav={nav} itemId={log.userId}>
				{userLabel}</Link></>}
			{log.position && <><br />at {log.position.lat.toPrecision(6)}° / {
				log.position.long.toPrecision(6)}°{
					log.position.alt && ` (${log.position.alt.toFixed(0)}m)`}
			</>}
			{installationLabel && <>
				<br />with <Link nav={nav} itemId={log.installationId}>
					{installationLabel}</Link></>}
		</Block>
	}

}
