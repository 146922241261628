import * as React from 'react'
import * as mdl from '../../model'
import { Icon } from '../common'
import { ItemIcon, ItemLabel } from '../LayoutViews'
import {
	findPropWithIconView, PropContent, PropEditor, PropIcon, PropLabel
} from '../PropertyViews'
import { Layout } from './common'

export const list: Layout = {
	label: (item, context) => item.props.label ?
		<PropLabel prop={item.props.label} context={context} /> :
		<>Item {item.id}</>,
	icon: (item, context) => {
		const prop = item.props.icon ?? findPropWithIconView(item) ??
			item.props.label
		return prop ? <PropIcon prop={prop} context={context} /> :
			<Icon uri={'text:?'} />
	},
	content: (item, context) => <>{item.props.content.map(p =>
		<PropContent key={p.name} prop={p} context={context} />)}</>,
	links: {
		small: (item, context) =>
			<div><ItemLabel item={item} context={context} /></div>,
		compact: (item, context) => <>
			<ItemIcon item={getItemWithIcon(item)} context={context} />
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
			</div>
		</>,
		normal: (item, context) => <>
			<ItemIcon item={getItemWithIcon(item)} context={context} />
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
				{getLinkContentProps(item).slice(0, 1).map(p =>
					<PropLabel key={p.name} prop={p} context={context} />)}
			</div>
		</>,
		extended: (item, context) => <>
			<ItemIcon item={getItemWithIcon(item)} context={context} />
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
				{getLinkContentProps(item).slice(0, 3).map(p =>
					<PropLabel key={p.name} prop={p} context={context} />)}
			</div>
		</>,
		full: (item, context) => <>
			<ItemIcon item={getItemWithIcon(item)} context={context} />
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
				{getLinkContentProps(item).map(p =>
					<PropLabel key={p.name} prop={p} context={context} />)}
			</div>
		</>,
	},
	editor: (item, context, form) => <>{item.props.editable
		.map((p, idx) => <PropEditor key={p.name} prop={p} context={context}
			form={form} autoFocus={idx === 0} />)}</>,
}

export function getLinkContentProps(item: mdl.Item) {
	return item.props.content
		.filter(p => p !== item.props.label && p.type !== 'image')
}

export function getItemWithIcon(item: mdl.Item) {
	if (item.props.icon || findPropWithIconView(item))
		return item
	if (item.links.available) {
		for (const ln of item.links.available) {
			if (ln.item.props.icon || findPropWithIconView(ln.item))
				return ln.item
		}
	}
	return item
}
