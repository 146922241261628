import * as React from 'react'
import * as mdl from '../../model'
import { Calendar } from '../common'
import { ItemLabel } from '../LayoutViews'
import { Layout } from './common'

export const calendar: Layout = {
	content: (item, context) =>
		<Calendar startMonth={item.props.findByType('date')?.value}
			renderDate={date =>
				mdl.CalendarLayout.getItemsForDate(item, date).map(item =>
					<div key={item.id}><ItemLabel item={item} context={context} /></div>)}
			onSelect={item.props.findByAction('selectDate')?.value?.start} />,
}
