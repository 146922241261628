import { BoxStorage, DataFilter } from './BoxStorage'
import { action, computed, observable, signal } from './common'
import { Item } from './Item'
import { StatusReports } from './Log'
import { BoxValue } from './PropertyValue'

export interface ItemData {
	id: string
	rev?: number
	create?: any
	update?: any
	props?: { [name: string]: any }
	content?: ItemLinkData[]
	links?: ItemLinkData[]
	layoutId?: string
	tmpls?: string[]
	boxes?: string[]
	// possible storage conflicts
	conflicts?: any[]
	// storage info
	_info?: any
}

export interface ItemData3 {
	id: string
	parents?: string[]
	props?: PropertyData[]
	content?: ItemLinkData[]
	links?: ItemLinkData[]
	layoutId?: string
	tmpls?: string[]
}

export type PropertyData = {
	name: string
	value: string | number | boolean | Object
	type?: string
	label?: string
}

export type ItemLinkData = string |
{ url: string, name: string, preview: string }

export namespace ItemLinkData {
	export function url(d: ItemLinkData) {
		return d && typeof d === 'object' ? d.url : d
	}
}

export type BoxPermissions = 'no' | 'ro' | 'rw'

export class Box {
	@observable label: string
	@observable mark: string
	@observable color: string
	@observable backColor: string
	constructor(public readonly item: Item, data: BoxValue) {
		this.item = item
		this.label = data.box
		this.mark = data.mark
		this.color = data.color
		this.backColor = data.backColor
	}
	@computed get id() { return this.item.id }
	@computed get indexId() { return this.item.props.get('indexId')?.value }
	get $debug() {
		return {
			id: this.id, name: this.label, isActive: this.isActive,
			storages: this.allStorages.map(s => s.$debug),
		}
	}
	static getBox(boxItem: Item) {
		return boxItem?.props?.findByType('box')?.value as Box
	}
	@computed get allStorages() {
		return this.item.findRelatedPropertyValues<BoxStorage>('storage')
	}
	@computed get availableStorages() {
		// the fastest storages first
		// TODO: measure storage responses and sort accordingly
		return this.allStorages
			.filter(s => s.isAvailable)
			.sort((a, b) => a.isLocal ? b.isLocal ? 0 : -1 : 1)
	}
	@computed get hasAvailableStorages() {
		return this.availableStorages.length > 0
	}
	findStorageByUrl(...urls: string[]) {
		const storages = this.allStorages
		for (const url of urls)
			for (const s of storages)
				if (s.url === url)
					return s
		return null
	}
	/** Passive boxes are "normal" items only */
	@observable private _isActive = false
	get isActive() { return this._isActive }
	set isActive(v) {
		if (v === this._isActive)
			return
		this._isActive = v
		for (const s of this.availableStorages) {
			if (this._isActive)
				s.access.open(this.id)
			else
				s.access.close(this.id)
		}
	}
	toggleActive = action(() => {
		this.isActive = !this.isActive
	})
	static hasActiveStorage(boxItem: Item) {
		const box = Box.getBox(boxItem)
		return box?.isActive && box.hasAvailableStorages
	}
	@observable permissions: BoxPermissions = null
	@computed get isWriteAllowed() {
		return this.permissions === null || this.permissions === 'rw'
	}
	refreshPermissions = action(() => { this.permissions = null })
	// TODO: explicitly read all item IDs from a box
	/** Build all items of this box. */
	buildAll = signal<(filter?: DataFilter) => Promise<void>>()
	// these items have been found in a newer version in other boxes
	@observable private _dirtyItemIds: { [id: string]: any }
	@computed get dirtyItemIds() {
		return this._dirtyItemIds ? Object.keys(this._dirtyItemIds) : []
	}
	@action addDirtyItemId(itemId: string) {
		if (!this._dirtyItemIds)
			this._dirtyItemIds = {}
		this._dirtyItemIds[itemId] = 1
	}
	@action removeDirtyItemId(itemId: string) {
		if (!this._dirtyItemIds)
			return
		delete this._dirtyItemIds[itemId]
	}
	/** Update all contained items to their newest version. */
	updateItems = signal<() => Promise<void>>()
	/** Load all the data of this box (data for multiple items). */
	readData = async (filter?: DataFilter) => {
		if (this.isActive) {
			for (const s of this.availableStorages) {
				const d = await s.access.readData(this.id, filter)
				if (d?.length > 0)
					return d
			}
		}
		return []
	}
	/** Load the data for only one item. */
	readItem = async (id: string, completely = false) => {
		if (this.isActive) {
			for (const s of this.availableStorages) {
				const d = await s.access.readItem(id, [this.id], completely)
				if (d)
					return d
			}
		}
		return null
	}
	/** */
	readFromLinks = async (itemId: string) => {
		if (this.isActive) {
			for (const s of this.availableStorages) {
				const d = await s.access.readFromLinks(itemId, [this.id])
				if (d?.length > 0)
					return d
			}
		}
		return []
	}
	/** */
	readSearchTexts = async () => {
		if (this.isActive) {
			for (const s of this.availableStorages) {
				const d = await s.access.readSearchTexts([this.id])
				if (d?.length > 0)
					return d
			}
		}
		return []
	}
	/** */
	writeItem = async (data: ItemData) => {
		if (this.isActive)
			await Promise.all(this.availableStorages.map(s =>
				s.access.writeItem(data, this.id)))
	}
	/** */
	removeItem = async (id: string) => {
		if (this.isActive)
			await Promise.all(this.availableStorages.map(s =>
				s.access.removeItem(id, this.id)))
	}
	/** Place all items into another box. */
	placeAll = signal()
	/** Update all available storages. */
	updateStorages = signal<() => Promise<void>>()
	@observable status = ''
	/** Refresh all items in all their boxes. */
	refreshAll = signal()
	/** search index for all the items in this box */
	searchIndex: object
	reIndex = signal<() => Promise<void>>()
	/** Status reports. */
	reports = new StatusReports()
}
