import lunr from 'lunr'
import { action, Utils } from '../../common'
import * as mdl from '../../model'

export async function buildIndexes(boxes: mdl.BoxManager) {
	// TODO: merge box indexes
	const indexes = await Promise.all(boxes.activeBoxes
		.map(async (box) => {
			if (box.searchIndex) {
				return lunr.Index.load(box.searchIndex)
			}
			else {
				const items = await box.readSearchTexts()
				if (!items || items.length <= 0) return null
				const idx = lunr(function() {
					this.field('text')
					for (const item of items) {
						if (item)
							this.add({
								id: item.id, text: item.text
							})
					}
				})
				// box.searchIndex = idx.toJSON()
				return idx
			}
		}))
	return indexes.filter(Utils.isTrue)
}

export const maxSearchResultCount = 20

export function search(q: string, indexes: lunr.Index[],
	view: { searchResults: mdl.SearchResult[] }, items: mdl.ItemManager) {
	// search a single word as prefix as well
	// keep the word separate for stemming,...
	if (q.indexOf(' ') < 0)
		q += ' ' + q + '*'
	// ignore multi-line texts 
	if (q.indexOf('\n') >= 0)
		return
	// search all boxes
	try {
		const results = indexes.map(idx => idx.search(q))
			.reduce(Utils.flatArray, [])
			.sort((a, b) => b.score - a.score)
			.filter(Utils.distinctKey(r => r.ref))
		if (results.length > maxSearchResultCount)
			results.length = maxSearchResultCount
		action(() => {
			view.searchResults = results
				.map(r => ({ item: items.requestItem(r.ref), score: r.score }))
		})()
		for (const r of view.searchResults) r.item.complete()
	}
	catch (err) {
		// ignore for now...
	}
}
