import { computed, observable, Signal } from '../../common'
import { Box } from '../Box'
import { Item } from '../Item'
import { ItemView } from './ItemView'

export class PlaceItemView extends ItemView {
	readonly key = 'place'
	@observable items: Item[]
	@computed get itemCount() { return this.items ? this.items.length : 1 }
	@computed get isReady() {
		return !!this.item?.isReady && !!this.allBoxes
	}
	allBoxes: Box[]
	itemsInBox(box: Box) {
		return this.items ? this.items.reduce(
			(s, itm) => s + (itm.isInBox(box) ? 1 : 0), 0) :
			this.item.isInBox(box) ? 1 : 0
	}
	toggleBox(box: Box) {
		if (!box.isActive || !box.hasAvailableStorages) return
		if (this.items) {
			if (this.itemsInBox(box) === this.itemCount)
				for (const item of this.items) item.removeFromBox(box)
			else
				for (const item of this.items)
					item.addToBox(box)
		}
		else {
			if (this.item.boxes.includes(box))
				this.item.removeFromBox(box)
			else
				this.item.addToBox(box)
		}
	}
}
