import { action, computed, IGNORE_ERROR, Utils } from '../common'
import { Box } from './Box'
import { BoxStorage } from './BoxStorage'
import { observable, signal } from './common'
import { Item, ItemStatus } from './Item'
import { ItemManager } from './ItemManager'
import { Logger } from './SystemLog'

export class BoxManager {

	constructor(public items: ItemManager, public log: Logger) { }

	@observable.shallow storageItems: Item[] = []

	// box of the current user for unboxed items, boxes, users,...
	@observable userBoxItem: Item
	// box cache
	@observable.shallow private boxById: Map<string, Item> = new Map()

	getBox(id: string) {
		return Box.getBox(this.getBoxItem(id))
	}

	getBoxItem(id: string) {
		return this.boxById.get(id)
	}

	@computed get allBoxItems() {
		return [...this.boxById.values()]
	}

	@computed get allBoxes() {
		return this.allBoxItems.map(Box.getBox).filter(Utils.isTrue)
	}

	@computed get activeBoxes() {
		return this.allBoxes.filter(b => b.isActive)
	}

	@action setBox(boxItem: Item) {
		this.boxById.set(boxItem.id, boxItem)
	}

	@action removeBox(boxItemId: string) {
		return this.boxById.delete(boxItemId)
	}

	@computed get allStorages() {
		return this.storageItems.map(BoxStorage.getStorage).filter(Utils.isTrue)
	}

	@computed get availableStorages() {
		return this.storageItems.map(BoxStorage.getStorage)
			.filter(s => s?.isAvailable)
	}

	getStorage(id: string) {
		return this.allStorages.find(s => s.id === id)
	}

	getStorageByUrl(boxStorageUrl: string) {
		return this.allStorages.find(s => s.url === boxStorageUrl)
	}

	@action setStorage(storage: BoxStorage) {
		if (this.storageItems.includes(storage.item))
			return
		// prefer local storages
		// OPT: measure access and sort accordingly
		if (storage.isLocal)
			this.storageItems.splice(0, 0, storage.item)
		else
			this.storageItems.push(storage.item)
	}

	get $debug() {
		const d = { boxes: {}, storages: {} }
		for (const b of this.boxById.values())
			d.boxes[b.id] = Box.getBox(b)?.$debug
		for (const s of this.storageItems)
			d.storages[s.id] = s.$debug
		if (this.userBoxItem)
			d['userBox'] = Box.getBox(this.userBoxItem)?.$debug
		return d
	}

	// add a box via URL
	addBox = signal<(url: string) => Promise<void>>()

	// read a box
	readBox = signal<(boxStorageUrls: string[], boxId: string) => Promise<void>>()

	/** some items will be generated */
	generatedItemsFactory: { [id: string]: (item: Item) => Promise<Item> } = {}

	/** actually load an item */
	async loadItem(item: Item, completely: boolean) {
		if (item.id in this.generatedItemsFactory) {
			await this.generatedItemsFactory[item.id](item)
			if (item.status < ItemStatus.level1) item.status = ItemStatus.level1
			return
		}
		const storages = this.availableStorages
		let count = storages.length
		if (count > 0) {
			// race the first successful or the first error, if none is successful
			return new Promise<void>((res, rej) => {
				let firstError = null
				for (const st of storages) {
					const stBoxIds = st.activeBoxes.map(Utils.toId)
					st.access.readItem(item.id, stBoxIds, completely)
						.then(d => {
							if (d) {
								item.build(d)
								res()
							}
							if (--count <= 0) {
								if (firstError) rej(firstError)
								else res()
							}
						}, err => {
							this.log.error(err)
							if (!firstError)
								firstError = err
							if (--count <= 0) {
								if (firstError) rej(firstError)
								else res()
							}
						})
				}
			})
		}
	}

}
