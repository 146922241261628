import * as React from 'react'
import { Component, Icon, InputProps, observer, ValueProps } from './common'

@observer
export class BooleanInput<T>
	extends Component<InputProps & ValueProps<T>> {

	render() {
		const { label, title, children } = this.props
		const value = 'value' in this.props ? this.props.value : null
		const val = 'value' in this.props ? value.val :
			this.props.target[this.props.member]
		return (
			<div className={'field boolean' + (value?.hasErrors ? ' error' : '')}
				title={title} onClick={this.onChange}>
				{label && <label>{label}</label>}
				<span tabIndex={0} onKeyPress={this.onKey}>
					<Icon uri={val ? 'checked' : 'unchecked'} /></span>
				{value?.hasErrors && <div>{value.errorMessages.join(', ')}</div>}
				{children}
			</div>
		)
	}

	onChange = () => {
		if ('value' in this.props)
			this.props.value.value = (!this.props.value.val).toString()
		else
			this.props.target[this.props.member] =
				!this.props.target[this.props.member] as any
	}

	onKey = (evn: React.KeyboardEvent<HTMLSpanElement>) => {
		const { key } = evn
		if (key === 'Enter' || key === ' ') this.onChange()
	}
}

