
export interface Entity { id: string | number }

export module update {
	export function values(obj, data) {
		// member values via corresponding data members, if available
		for (const k in obj) {
			const t = typeof obj[k]
			// TODO: Date,...
			if (t === 'string' || t === 'number' || t === 'boolean') {
				if (k in data) obj[k] = data[k]
			}
		}
	}

	export function list<I, D>(
		list: I[], dataList: D[],
		createFn: (data: D) => I, contentFn: (item: I, data: D) => void) {
		// a list of unidentified pages
		list.splice(dataList.length)
		dataList.forEach((data, idx) => {
			let item
			if (idx >= list.length) {
				item = createFn(item)
				list.push(item)
			}
			else item = list[idx]
			contentFn(item, data)
		})
	}

	export function entityList<E extends Entity, D extends Entity>(
		entities: E[], dataList: D[],
		createFn: (data: D) => E, contentFn: (entity: E, data: D) => void) {
		// a list of identified entities
		dataList.forEach((data, idx) => {
			let entity
			if (idx >= entities.length) {
				// new chapter at the end
				entity = createFn(data)
				entities.push(entity)
				entity.id = data.id
			}
			else {
				entity = entities[idx]
				if (entity.id !== data.id) {
					const i = entities.findIndex(e => e.id === data.id)
					if (i >= 0) {
						if (i < idx) throw 'Invalid data!'
						// move it from another index
						entity = entities.splice(i, 1)[0]
						entities.splice(idx, 0, entity)
					}
					else {
						// insert new chapter
						entity = createFn(data)
						entities.splice(idx, 0, entity)
						entity.id = data.id
					}
				}
			}
			contentFn(entity, data)
		})
		// remove possible left-overs
		entities.splice(dataList.length)
	}

	export function entitySet<E extends Entity, D extends Entity>(
		entities: E[], dataSet: D[],
		createFn: (data: D) => E, contentFn: (entity: E, data: D) => void) {
		// an unordered set of identified entities
		let oldEntities = [...entities]
		dataSet.forEach(data => {
			let entity = entities.find(e => e.id === data.id)
			if (!entity) {
				entity = createFn(data)
				entities.push(entity)
				entity.id = data.id
			}
			else {
				oldEntities = oldEntities.filter(e => e.id !== data.id)
			}
			contentFn(entity, data)
		})
		// remove the books without corresponding data
		oldEntities.forEach(e => entities.splice(entities.indexOf(e), 1))
	}
}
