import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import * as React from 'react'
import {
	action, Component, computed, equalsColor, extractImage,
	FormValue, Icon, observable, observer, Observer, setColor
} from '..'
import { Utils } from '../../../common'
import * as mdl from '../../../model'
import placeholder from '../../../style/placeholder.svg'

const showExperimental = !!localStorage.getItem('experiments')

interface Props {
	value: FormValue
	width?: number
	height?: number
	asIcon?: boolean
	active: boolean
	onClose: () => void
}

@observer
export class PixelEditor extends Component<Props> {

	ref = React.createRef<HTMLImageElement>()
	cropper: Cropper
	// object URL cache to revoke
	blobUrlCache: string
	blobUrlRef: Blob

	@observable full = true

	@computed get url() {
		const { val } = this.props.value
		const url = mdl.ImageValue.url(val)
		if (url) {
			if (this.blobUrlCache) {
				URL.revokeObjectURL(this.blobUrlCache)
				this.blobUrlRef = null
			}
			return extractImage(url)
		}
		const blob = mdl.ImageValue.blob(val)
		if (blob) {
			if (blob !== this.blobUrlRef) {
				if (this.blobUrlCache)
					URL.revokeObjectURL(this.blobUrlCache)
				this.blobUrlCache = URL.createObjectURL(blob)
				this.blobUrlRef = blob
			}
			return this.blobUrlCache
		}
		if (this.blobUrlCache) {
			URL.revokeObjectURL(this.blobUrlCache)
			this.blobUrlRef = null
		}
		return this.props.asIcon ? '' : placeholder
	}

	@computed get imageType() {
		const { val } = this.props.value
		const blob = mdl.ImageValue.blob(val)
		if (blob)
			return blob.type
		return Utils.isJpeg(mdl.ImageValue.url(val)) ? 'image/jpeg' : 'image/png'
	}

	render() {
		const { width, height, active } = this.props
		return <div onKeyUp={this.onAnyKey}>
			<div className={active ? 'cropperjs' : 'image'} tabIndex={0}>
				<img src={this.url} ref={this.ref} crossOrigin="anonymous" style={
					active ? null : { maxWidth: width, maxHeight: height }} />
			</div>
			{active &&
				<div className="actions">
					<Observer>{() =>
						<button onClick={this.onFull} disabled={this.full}
							title="Select the whole image.">
							<Icon uri="fullscreen" /></button>
					}</Observer>
					<button onClick={this.onZoomIn} title="Zoom in">
						<Icon uri="zoom_in" /></button>
					<button onClick={this.onZoomOut} title="Zoom out">
						<Icon uri="zoom_out" /></button>
					<button onClick={this.onRotate}
						title="Rotate image by 90° clockwise.">
						<Icon uri="rotate_right" /></button>
					{showExperimental &&
						<button onClick={this.onEraseBackground} title="Erase background.">
							<Icon uri="auto_fix_high" /></button>}
					<button onClick={this.onCrop}
						title="Crop and accept the current selection.">
						<Icon uri="check" /></button>
					<button onClick={this.onCancel}
						title="Cancel this edit action. [ESC]">
						<Icon uri="close" /></button>
				</div>
			}
		</div>
	}

	componentDidUpdate() {
		if (this.props.active) {
			if (this.cropper) this.cropper.replace(this.ref.current.src)
			else this.createCropper()
			this.ref.current.parentElement.focus()
			this.props.value.onSubmit.react(this.onCrop)
		} else {
			this.destroyCropper()
		}
	}

	componentWillUnmount() {
		super.componentWillUnmount()
		this.clear()
	}

	@action createCropper() {
		if (this.cropper) return
		const { width, height } = this.props
		const img = this.ref.current
		this.cropper = new (Cropper as any)(img, {
			dragMode: 'move' as any,
			toggleDragModeOnDblclick: false,
			guides: false,
			highlight: true,
			responsive: true,
			viewMode: 0,
			center: false,
			aspectRatio: typeof width === 'number' && width === height ? 1 : NaN,
			autoCrop: true,
			autoCropArea: 1,
			minContainerHeight: 128,
			minCropBoxWidth: width,
			minCropBoxHeight: height,
			cropstart: () => { this.full = false },
		})
	}

	@action destroyCropper() {
		if (this.cropper) this.cropper.destroy()
		this.cropper = null
	}

	@action clear() {
		this.destroyCropper()
		// if (this.blobUrlCache) URL.revokeObjectURL(this.blobUrlCache)
		// this.blobUrlCache = null
	}

	@action close() {
		this.clear()
		this.props.onClose()
	}

	onCrop = () => {
		if (!this.cropper)
			return
		const { width, height, asIcon, value } = this.props
		const canvas = this.cropper.getCroppedCanvas({
			width, height,
			imageSmoothingEnabled: true,
			imageSmoothingQuality: 'high' as any,
		})
		if (asIcon) {
			const url = canvas.toDataURL(this.imageType)
			action(() => {
				this.close()
				value.val = url
			})()
		}
		else {
			canvas.toBlob(b => {
				const v = { image: b, width: canvas.width, height: canvas.height }
				action(() => {
					this.close()
					this.props.value.val = v
				})()
			},
				this.imageType)
		}
	}

	onFull = () => {
		this.cropper.setCropBoxData(this.cropper.getCanvasData())
		this.full = true
	}
	onCancel = () => {
		this.close()
	}
	onRotate = () => { this.cropper.rotate(90) }
	onZoomIn = () => { this.cropper.zoom(0.1) }
	onZoomOut = () => { this.cropper.zoom(-0.1) }
	onEraseBackground = () => {
		const { width, height } = this.props
		const canvas = this.cropper.getCroppedCanvas({
			width, height,
			imageSmoothingEnabled: true,
			imageSmoothingQuality: 'high' as any,
		})
		const ctx = canvas.getContext('2d')
		const d = ctx.getImageData(0, 0, canvas.width, canvas.height)
		const params = prompt('Red Green Blue Tolerance',
			[d.data[0], d.data[1], d.data[2], 10].join(' '))
		const [r, g, b, tolerance] = params.split(' ').map(s => parseInt(s))
		for (let i = 0; i < d.data.length; i += 4) {
			if (equalsColor(d.data, i, r, g, b, tolerance))
				setColor(d.data, i, 0, 0, 0, 0)
		}
		ctx.putImageData(d, 0, 0)
		canvas.toBlob(b => {
			if (this.blobUrlCache) URL.revokeObjectURL(this.blobUrlCache)
			this.blobUrlCache = URL.createObjectURL(b)
			this.cropper.replace(this.blobUrlCache)
		}, this.imageType)
	}

	onAnyKey = (evn: React.KeyboardEvent) => {
		if (evn.key === 'Escape') {
			this.onCancel()
			evn.stopPropagation()
			evn.nativeEvent.stopImmediatePropagation()
		}
	}

}

