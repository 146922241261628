import 'material-design-icons-iconfont/dist/material-design-icons.css'
import * as React from 'react'
import * as ReactDom from 'react-dom'
import { setupAll } from '../../base/src/common/setup'
import { setup as data } from './data'
import { setup as env } from './env'
import * as mdl from './model'
import { setup as navigation } from './navigation'
import './style/app.scss'
import { setup as ui } from './ui'
import { AppView } from './view/AppView'

export function setupApp(app: mdl.App) {
	const props = {
		...app, app, nav: app.ui.nav, inst: app.installation,
		auth: app.session.auth, log: app.installation.log,
	}
	setupAll({ data, env, navigation, ui }, props)
}

export async function startApp(app: mdl.App) {
	await app.init()

	ReactDom.render(<>
		<AppView app={app} />
		{/* <DevTools /> */}
	</>, document.getElementById('app'))

}
