import { FormValue } from '../ui/Form'

export type NumberValue = number | { number: number; unit?: string; }

export type ImageValue = string | Blob | {
	image: Blob | string; width?: number; height?: number; class?: string;
	info?: any; source?: string
} | {
	icon: Blob | string; width?: number; height?: number; class?: string;
	info?: any; source?: string
}
export namespace ImageValue {
	export function url(v: ImageValue): string {
		// TODO: blobs via createObjectURL() ...when to revokeObjectURL()?
		return typeof v === 'string' ? v :
			typeof v['image'] === 'string' ? v['image'] :
				typeof v['icon'] === 'string' ? v['icon'] : null
	}
	export function blob(v: ImageValue): Blob {
		return v instanceof Blob ? v :
			v['image'] instanceof Blob ? v['image'] :
				v['icon'] instanceof Blob ? v['icon'] : null
	}
	export function className(v: ImageValue): string {
		return typeof v['class'] === 'string' ? v['class'] : null
	}
	export function isValid(v: ImageValue) {
		return v && (!!ImageValue.url(v) || !!ImageValue.blob(v))
	}
}

export interface LocationValue {
	address?: string
	latitude: number
	longitude: number
	accuracy?: number
	altitude?: number
	altitudeAccuracy?: number
	heading?: number
	speed?: number
}
export namespace LocationValue {
	export function isValid(lat: number, lng: number): boolean
	export function isValid(loc: LocationValue): boolean
	export function isValid(lat: any, lng?: number) {
		const t = typeof lat
		return t === 'object' ?
			typeof lat.latitude === 'number' && typeof lat.longitude === 'number' :
			t === 'number' && typeof lng === 'number'
	}
	export interface FormValues {
		address: FormValue<{ address?: string }, 'address'>
		latitude: FormValue<{ latitude: number }, 'latitude'>
		longitude: FormValue<{ longitude: number }, 'longitude'>
		altitude: FormValue<{ altitude?: number }, 'altitude'>
	}
}


export interface BoxValue {
	box: string
	mark?: string
	color?: string
	backColor?: string
}
export namespace BoxValue {
	export interface FormValues {
		label: FormValue<{ label: string }, 'label'>
		mark: FormValue<{ mark: string }, 'mark'>
		color: FormValue<{ color: string }, 'color'>
		backColor: FormValue<{ backColor: string }, 'backColor'>
	}
}


export interface BoxStorageValue {
	storage: string
	url?: string
}
export namespace BoxStorageValue {
	export interface FormValues {
		protocol: FormValue<{ protocol: string }, 'protocol'>
		url: FormValue<{ url: string }, 'url'>
	}
}


export interface AccountValue {
	account: string
	url: string
}
export namespace AccountValue {
	export interface FormValues {
		provider: FormValue<{ provider: string }, 'provider'>
		url: FormValue<{ url: string }, 'url'>
	}
}

