import { O } from '../common'
import * as mdl from '../model'

export const setup = {
	sys: {
		installation: ({ boxes, inst }:
			{ boxes: mdl.BoxManager, inst: mdl.Installation }) => {
			boxes.generatedItemsFactory['installation.sys'] =
				async (item: mdl.Item) => {
					item.props.set('title', 'This provisional Installation')
					item.props.set('installation', inst, 'installation')
					item.isGenerated = true
					return item
				}
		},

		boxes: ({ boxes }: { boxes: mdl.BoxManager }) => {
			boxes.generatedItemsFactory['boxes.sys'] =
				async (item: mdl.Item) => {
					item.props.set('title', 'Boxes')
					item.props.set('icon', 'box', 'icon')
					item.isGenerated = true
					item.links.setComputed(() => boxes.allBoxItems
						.sort((a, b) => a.labelText.localeCompare(b.labelText))
						.map(b => O.new(mdl.Link, b.id, b)))
					return item
				}
		},

		storages: ({ boxes }: { boxes: mdl.BoxManager }) => {
			boxes.generatedItemsFactory['storages.sys'] =
				async (item: mdl.Item) => {
					item.props.set('title', 'Box Storages')
					item.props.set('icon', 'storage', 'icon')
					item.isGenerated = true
					item.links.setComputed(() => boxes.storageItems
						.sort((a, b) => a.labelText.localeCompare(b.labelText))
						.map(b => O.new(mdl.Link, b.id, b)))
					return item
				}
		},

		session: ({ boxes, session }:
			{ boxes: mdl.BoxManager, session: mdl.Session }) => {
			boxes.generatedItemsFactory['session.sys'] =
				async (item: mdl.Item) => {
					item.props.set('title', 'Session')
					item.isGenerated = true
					item.links.add(session.user)
					item.links.add(session.userBoxItem)
					return item
				}
		},

		system: ({ boxes, items }:
			{ boxes: mdl.BoxManager, items: mdl.ItemManager }) => {
			boxes.generatedItemsFactory['system.sys'] =
				async (item: mdl.Item) => {
					item.props.set('title', 'System')
					item.props.set('icon', 'system', 'icon')
					item.isGenerated = true
					item.links.add(items.getItem('installation.sys'))
					item.links.add(items.getItem('session.sys'))
					item.links.add(items.getItem('storages.sys'))
					item.links.add(items.getItem('boxes.sys'))
					return item
				}
		},

		templates: ({ boxes }: { boxes: mdl.BoxManager }) => {
			boxes.generatedItemsFactory['list.tmpl'] =
				async (item: mdl.Item) => {
					item.props.set('title', 'Default List Template')
					item.props.set('icon', null, 'icon')
					item.isGenerated = true
					return item
				}
			boxes.generatedItemsFactory['user.tmpl'] =
				async (item: mdl.Item) => {
					item.props.set('name', 'User Name')
					item.props.set('icon', null, 'icon')
					item.isGenerated = true
					return item
				}
			boxes.generatedItemsFactory['text.tmpl'] =
				async (item: mdl.Item) => {
					item.props.set('text', 'Default text template.', 'text')
					item.layoutId = 'text.layout'
					item.isGenerated = true
					return item
				}
			boxes.generatedItemsFactory['url.tmpl'] =
				async (item: mdl.Item) => {
					item.props.set('title', 'Default URL Template')
					item.props.set('url', 'https://alls.be', 'url')
					item.props.set('description', '', 'text')
					item.props.set('icon', null, 'icon')
					item.layoutId = 'url.layout'
					item.isGenerated = true
					return item
				}
			boxes.generatedItemsFactory['location.tmpl'] =
				async (item: mdl.Item) => {
					item.props.set('title', 'Default Location Template')
					item.props.set('location', null, 'location')
					item.isGenerated = true
					return item
				}
			boxes.generatedItemsFactory['query.tmpl'] =
				async (item: mdl.Item) => {
					item.props.set('title', 'Default Query Template')
					item.props.set('query', null, 'query')
					item.isGenerated = true
					return item
				}
			boxes.generatedItemsFactory['todo.tmpl'] =
				async (item: mdl.Item) => {
					item.props.set('title', 'Default Todo Template')
					item.props.set('description', '', 'text')
					item.props.set('completed', false, 'boolean')
					item.props.set('toggle', O.new(mdl.ToggleAction, item), 'action')
					item.layoutId = 'todo.layout'
					item.isGenerated = true
					return item
				}
			boxes.generatedItemsFactory['comment.tmpl'] =
				async (item: mdl.Item) => {
					item.props.set('text', 'Default comment template.', 'text')
					item.layoutId = 'comment.layout'
					item.isGenerated = true
					return item
				}
			boxes.generatedItemsFactory['number.tmpl'] =
				async (item: mdl.Item) => {
					item.props.set('value', { number: 0, unit: 'kg' }, 'number')
					item.isGenerated = true
					return item
				}
			boxes.generatedItemsFactory['date.tmpl'] =
				async (item: mdl.Item) => {
					item.props.set('date', new Date(Date.now()), 'date')
					item.isGenerated = true
					return item
				}
			boxes.generatedItemsFactory['calendar.tmpl'] =
				async (item: mdl.Item) => {
					item.props.set('title', 'Default Calendar Template')
					item.props.set('date', new Date(Date.now()), 'date')
					item.props.set('select', O.new(mdl.SelectDateAction, item), 'action')
					item.props.set('icon', null, 'icon')
					item.layoutId = 'calendar.layout'
					item.isGenerated = true
					return item
				}
			boxes.generatedItemsFactory['image.tmpl'] =
				async (item: mdl.Item) => {
					item.props.set('image', '', 'image')
					item.props.set('caption', 'Default image template.')
					item.props.set('icon', null, 'icon')
					item.layoutId = 'image.layout'
					item.isGenerated = true
					return item
				}
			boxes.generatedItemsFactory['box.tmpl'] =
				async (item: mdl.Item) => {
					item.props.set('title', 'Default Box Template')
					item.props.set('box', O.new(mdl.Box, item,
						{ box: 'default', mark: 'D', color: 'white', backColor: 'gray' }),
						'box')
					item.isGenerated = true
					return item
				}
			boxes.generatedItemsFactory['storage.tmpl'] =
				async (item: mdl.Item) => {
					item.props.set('title', 'Default Box Storage Template')
					item.props.set('storage', O.new(mdl.BoxStorage, item,
						{ storage: 'couch', url: 'https://' }), 'storage')
					item.isGenerated = true
					return item
				}
			boxes.generatedItemsFactory['installation.tmpl'] =
				async (item: mdl.Item) => {
					item.props.set('title', 'Default Installation Template')
					item.isGenerated = true
					return item
				}
			boxes.generatedItemsFactory['account.tmpl'] =
				async (item: mdl.Item) => {
					item.props.set('title', 'Default Account Template')
					item.props.set('account', O.new(mdl.Account, item,
						{ account: 'couch', url: 'https://couch.alls.be' }), 'account')
					item.props.set('avatar', null, 'icon')
					item.props.set('email', '')
					item.isGenerated = true
					return item
				}
		},

	},

}
