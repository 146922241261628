import { Utils } from '../../../common'
import { UrlInfo } from './common'

export const specialUrls = [
	{
		prefix: 'https://www.google.com/search?',
		icon: 'https://upload.wikimedia.org/wikipedia/commons' +
			'/5/53/Google_%22G%22_Logo.svg',
		title: 'search "{q}"',
		url: 'keep params: q',
	},
	{
		prefix: 'https://www.google.com/maps/search/',
		url: 'trim',
	},
	{
		prefix: 'https://www.google.com/maps/place/',
		url: 'trim',
	},
	{
		prefix: 'https://www.bing.com/search?',
		icon: 'https://www.bing.com/sa/simg/favicon-2x.ico',
		title: 'search "{q}"',
		url: 'keep params: q',
	},
	{
		prefix: 'https://search.yahoo.com/search?',
		icon: 'https://search.yahoo.com/favicon.ico',
		title: 'search "{p}"',
		url: 'keep params: p'
	},
	{
		prefix: 'https://www.wolframalpha.com/input',
		icon:
			'https://www.wolframalpha.com/_next/static/images/favicon_fzx75d1e.ico',
		title: 'lookup "{i}"',
		url: 'keep params: i',
	},
	{
		prefix: 'https://en.wikipedia.org/w/index.php?search=',
		icon: 'https://en.wikipedia.org/static/favicon/wikipedia.ico',
		title: 'search "{search}"',
		url: 'keep params: search',
	},
	{
		prefix: 'https://www.search.ch/?',
		icon: 'https://lib.search.ch/favicon.ico',
		url: 'keep params: q',
		title: 'suche "{q}"',
	},
	{
		prefix: 'https://tel.search.ch',
		params: 'was, wo',
		icon: 'https://lib.search.ch/favicon.ico',
		url: 'keep params: was, wo',
		title: '📞 von "{was}" in "{wo}"',
	},
	{
		prefix: 'https://tel.search.ch',
		params: 'was',
		icon: 'https://lib.search.ch/favicon.ico',
		url: 'keep params: was, wo',
		title: '📞 von "{was}"',
	},
	{
		prefix: 'https://tel.search.ch',
		params: 'wo',
		icon: 'https://lib.search.ch/favicon.ico',
		url: 'keep params: was, wo',
		title: '📞 in "{wo}"',
	},
	{
		prefix: 'https://tel.search.ch',
		icon: 'https://lib.search.ch/favicon.ico',
		url: 'trim',
		title: 'Telefonbuch',
	},
	{// TODO: complete
		prefix: 'https://connect.garmin.com/modern/activity/',
		icon: '?',
		url: 'trim',
		title: 'Garmin Connect',
	},
] as {
	prefix: string
	params?: string
	title?: string
	icon?: string
	url?: string
}[]

UrlInfo.parser['$special'] = (txt, ct, url) => interpretUrl(url)

export function interpretUrl(url: string, rules = specialUrls) {
	for (const sUrl of rules) {
		if (url.startsWith(sUrl.prefix)) {
			const urlObj = new URL(url)
			const params = urlObj.searchParams
			if (sUrl.params && !hasAllParams(params, sUrl.params.split(',')))
				continue
			const info: UrlInfo = {}
			info.title = sUrl.title?.replace(/{([^}]*)}/g, (m, p) => params.get(p))
			info.icon = sUrl.icon
			if (sUrl.url) {
				const trimmedUrl = urlObj.origin + urlObj.pathname
				if (sUrl.url === 'trim') {
					info.url = trimmedUrl
				} else if (sUrl.url.startsWith('keep params:')) {
					const newParams = {}
					for (const s of sUrl.url.substring(12).split(',')) {
						const n = s.trim()
						if (n && params.has(n))
							newParams[n] = params.get(n)
					}
					info.url = Utils.addUrlParams(trimmedUrl, newParams)
				}
			}
			return info
		}
	}
}

function hasAllParams(params: URLSearchParams, names: string[]) {
	for (const s of names) {
		const n = s.trim()
		if (n && !params.has(n))
			return false
	}
	return true
}

