import * as React from 'react'
import { Utils } from '../../../common'
import { getExternalSearchUrl, searchImages } from '../../../data/ImageSearch'
import * as mdl from '../../../model'
import {
	Component, computed, Dom, FormValue, Icon, observable, observer, Observer
} from '../../common'

interface Props {
	query: FormValue | string
	asIcon?: boolean
	autoFocus?: boolean
	config: mdl.Config
	onSelect: (url: string) => void
}

interface SearchResultData {
	link: string
	thumbnail: string
	width: number
	height: number
}

@observer
export class ImageSearch extends Component<Props> {

	@observable queryText = null
	@observable.shallow searchResults: SearchResultData[]

	@computed get query() {
		return this.queryText === null ?
			(this.props.query instanceof FormValue ? this.props.query.val :
				this.props.query) +
			(this.props.asIcon ? ' icon' : '') :
			this.queryText
	}
	set query(v: string) {
		this.queryText = v
		this.searchResults = []
	}

	render() {
		const { asIcon, autoFocus, config } = this.props
		return <>
			<div className="actions">
				<Observer>{() =>
					<input type="text" value={this.query || ''} autoFocus={autoFocus}
						onChange={this.onQueryText} placeholder="URL or query..." />
				}</Observer>
				<button onClick={this.onSearch}><Icon uri="search" /></button>
				<Observer>{() => !this.query ? null :
					<a href={getExternalSearchUrl(config.api, this.query)}
						target="_blank" className="button"><Icon uri="launch" /></a>
				}</Observer>
			</div>
			<Observer>{() => !this.searchResults ? null :
				<div className="searchResults icons" onClick={this.onSelect}>
					{this.searchResults.map(r =>
						<div key={r.link} className={asIcon ? 'icon' : null}>
							<img src={r.thumbnail} title={r.link} />
						</div>)}
				</div>
			}</Observer>
		</>
	}

	onQueryText = (evn: React.FormEvent<HTMLInputElement>) => {
		this.query = evn.currentTarget.value
	}

	onSearch = async () => {
		const url = this.searchUrl
		if (url)
			this.props.onSelect(url)
		else
			this.searchResults = await searchImages(this.props.config.api, this.query)
	}
	onSelect = (evn: React.MouseEvent<HTMLImageElement>) => {
		const idx = Dom.findTargetChildIndex(evn)
		if (idx < 0)
			console.error('Search result not found!')
		else
			this.selectSearchResult(idx)
	}

	@computed get searchUrl() {
		return Utils.charCount(this.query) < 3 ?
			'text:' + encodeURIComponent(this.query) :
			mdl.ImageCapture.imageUrlPattern.test(this.query) ? this.query : null
	}

	selectSearchResult(idx: number) {
		const res = this.searchResults[idx]
		if (res?.link)
			this.props.onSelect(res.link)
	}

}
