import * as React from 'react'
import * as mdl from '../../model'
import { Component, observer } from '../common'

interface Props {
}

@observer
export class Dialog extends Component<Props> {

	render() {
		const { children } = this.props
		return <div className="dialog">
			{children}
			<div className="curtain"></div>
		</div>
	}

}
