
import { Utils } from '../../common'
import * as mdl from '../../model'
import * as stringIndex from '../stringIndex'
import { addAll, Index, inverseMap, updateIndex } from './common'

const indexKey = 'index:box-url'

export const boxUrl: Index = {
	build: item => {
	},
	remove: item => {
	},
	run: async (terms, resultIds, items) => {
		for (const term of terms) {
			const [key, arg] = term.split(':')
			if (key === 'boxes-of') {
				const item = items.requestItem(arg)
				await item.complete()
				addAll(resultIds, item.boxes.map(Utils.toId))
			}
		}
	},
}
