import * as React from 'react'
import { Block, TextInput } from '../common'
import { PropertyView } from './common'

export const color: PropertyView = {
	label: ({ value }) =>
		<span className="color" style={{ backgroundColor: value }}>&nbsp;</span>,
	icon: ({ value }) => <span className="icon"><Color val={value} /></span>,
	content: ({ value, label }) => <Block label={label}>
		<span className="color" style={{ backgroundColor: value }}>&nbsp;</span>
	</Block>,
	editor: ({ name, label }, form, ctx, autoFocus) => <Block label={label}>
		<TextInput value={form.values[name]} autoFocus={autoFocus} type="color" />
	</Block>,
}

function Color({ val }: { val: string }) {
	return <span className="color" style={{ backgroundColor: val }}>&nbsp;</span>
} 
