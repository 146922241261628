import { action, computed, observable } from './common'

export interface Credential {
	type: 'password' | 'token'
	id: string
	[ext: string]: any
}

export interface PasswordCredential extends Credential {
	type: 'password'
	id: string
	password: string
}

export interface CredentialsProvider {
	getCredential(url: string): Credential
}

export class Auth implements CredentialsProvider {
	@observable.shallow credentials = new Map<string, Credential>()
	getCredential(url: string) {
		return this.credentials.get(url)
	}
	@computed get allCredentials() {
		const obj: { [url: string]: Credential } = {}
		for (const k of this.credentials.keys())
			obj[k] = this.credentials.get(k)
		return obj
	}
	setCredential(url: string, cred: Credential): this
	setCredential(credentials: { [url: string]: Credential }): this
	@action setCredential(arg0: string | { [url: string]: Credential },
		cred?: Credential) {
		if (typeof arg0 === 'string')
			this.credentials.set(arg0, cred)
		else
			for (const url of Object.keys(arg0)) this.credentials.set(url, arg0[url])
		return this
	}
	removeCredential(url: string): this {
		this.credentials.delete(url)
		return this
	}
}
