import { action, O, when } from '../common'
import { setPropsFromText } from '../data/import'
import * as mdl from '../model'
import { ItemStatus } from '../model'

const copyValue: { [type in mdl.PropertyType]?:
	(p: mdl.Property, newItem: mdl.Item) => any } = {
	action: ({ value }, newItem) => O.new(value.constructor, newItem),
	box: ({ value }, newItem) => O.new(mdl.Box, newItem, { box: value.name }),
	storage: ({ value }, newItem) => O.new(mdl.BoxStorage, newItem,
		{ storage: value.protocol, url: value.url }),
	account: ({ value }, newItem) => O.new(mdl.Account, newItem,
		{ account: value.provider, url: value.url }),
	query: ({ value }, newItem) => O.new(mdl.Query, newItem,
		{ query: value.select }),
}

export function newItemWithTmpl(items: mdl.ItemManager, tmpl: mdl.Item,
	content?: { file?: File; text?: string; props?: mdl.PropertyArgs[] }) {
	const newItem = items.createItem()
	for (const p of tmpl.props) {
		if (p.type === 'image' && content?.file) {
			newItem.props.set(p.name, content.file, p.type)
		} else if (p.type in copyValue) {
			newItem.props.set(p.name, copyValue[p.type](p, newItem),
				p.type)
		}
	}
	if (content?.text)
		setPropsFromText(newItem, content.text, items, tmpl)
	newItem.tmpls.add(tmpl)
	if (content?.props) {
		for (const p of content.props)
			newItem.props.set(p[0],
				typeof p[1] === 'function' ? p[1](newItem) : p[1], p[2])
	}
	return newItem
}
