import { action, IReactionDisposer, O, reaction } from '../common'
import * as mdl from '../model'
import { ImageValue } from '../model'
import { proxy } from '../view/common/services'

const iconSize = 64

export const setup = {

	imageBlobAndIcon: () => {
		O.onInit(mdl.Item, item => {
			item.request3.react(() => {
				// TODO: more than one image property per item?
				reaction(() => item.props.findByType('image'), p => {
					reaction(() => p.value, async v => {
						if (!v) return
						const t = typeof v
						const url0 = ImageValue.url(v)
						const blob0 = ImageValue.blob(v)
						const url = blob0 ? URL.createObjectURL(blob0) : proxy(url0)
						if (!url) return
						const img = await createImage(url)
						const { w, h, icon } = imageToIcon(img)
						const blob = blob0 ?? imageToBlob(img)
						action(() => {
							if (!blob0 || v.width !== w || v.height !== h)
								p.value = { image: blob, width: w, height: h }
							item.props.set('icon', icon, 'icon')
						})()
						if (blob0)
							URL.revokeObjectURL(url)
					})
				})
			})
		})
	},

	itemIcons: () => {
		// icons as data URLs
		O.onInit(mdl.Item, item => {
			item.request3.react(() => {
				reaction(() => findUrlIconProp(item), async p => {
					if (!p) return
					const dataUrl = await toDataURL(proxy(p.value), iconSize, iconSize)
					action(() => { p.value = dataUrl })()
				}, { fireImmediately: true })
			})
		})
	},

}

function findUrlIconProp(item: mdl.Item) {
	const p = item.props.findByType('icon')
	return p && /^https?:\/\//.test(p.value) ? p : null
}

export function createImage(url: string) {
	return new Promise<HTMLImageElement>((resolve, reject) => {
		const img = new Image()
		img.onload = () => { resolve(img) }
		img.src = url
	})
}

export function imageToIcon(img: HTMLImageElement) {
	const icon = imageToDataUrl(img.src.endsWith('.svg') ?
		imageToCanvas(img, iconSize, iconSize) : img, iconSize, iconSize)
	return { w: img.naturalWidth, h: img.naturalHeight, icon }
}

function imageToDataUrl(img: HTMLImageElement | HTMLCanvasElement,
	width?: number, height?: number) {
	const canvas = document.createElement('canvas')
	canvas.width = width
	canvas.height = height
	const w = img.width, h = img.height
	const cw = w / h > width / height ? h / height * width : w
	const ch = w / h > width / height ? h : w / width * height
	const x = w / h > width / height ? (w - cw) / 2 : 0
	const y = w / h > width / height ? 0 : (h - ch) / 2
	//	console.log(w, h, width, height, cw, ch, x, y)
	const ctx = canvas.getContext('2d')
	ctx.drawImage(img, x, y, cw, ch, 0, 0, width, height)
	return canvas.toDataURL(
		img instanceof HTMLImageElement && isJpeg(img) ? 'image/jpeg' : 'image/png')
}

function imageToCanvas(img: HTMLImageElement, width?: number, height?: number) {
	let w = img.width, h = img.height
	if (width) {
		h = width / img.width * img.height
		w = width
	}
	if (height && height > h) {
		w = height / img.height * img.width
		h = height
	}
	const canvas = document.createElement('canvas')
	canvas.width = w
	canvas.height = w
	const ctx = canvas.getContext('2d')
	ctx.drawImage(img, 0, 0)
	return canvas
}

function imageToBlob(img: HTMLImageElement, width?: number, height?: number) {
	const canvas = imageToCanvas(img, width, height)
	return new Promise<Blob>((resolve, reject) => {
		canvas.toBlob(resolve, isJpeg(img) ? 'image/jpeg' : 'image/png')
	})
}

function isJpeg(img: HTMLImageElement) {
	const n = new URL(img.src).pathname
	return n.endsWith('.jpg') || n.endsWith('.jpeg')
}

export async function toDataURL(url: string, width?: number, height?: number) {
	const img = await createImage(url)
	return imageToDataUrl(url.endsWith('.svg') ?
		imageToCanvas(img, width, height) : img, width, height)
}

