import { AppBase } from './AppBase'
import { BoxStorage } from './BoxStorage'
import { action, computed, observable, signal } from './common'
import { Item } from './Item'
import { SystemLogger } from './SystemLog'

declare const __VERSION__: string
declare const __COMMIT_DATE__: Date

export class Installation {
	constructor(public app: AppBase) { }
	@observable item: Item
	version = __VERSION__
	commitDate = __COMMIT_DATE__
	@action setItem(item: Item) {
		this.item = item
		this.item.props.set('installation', this)
		const idGenProp = this.item?.props.get('idGen')?.value ?? 0
		if (idGenProp > this._idGen)
			this._idGen = idGenProp
	}
	initNew = signal<(item: Item) => Promise<void>>()
	@computed get id() { return this.item?.id }
	@observable private _idGen = 0
	@computed get idGen() {
		const idGenProp = this.item?.props.get('idGen')?.value ?? 0
		return idGenProp > this._idGen ? idGenProp : this._idGen
	}
	set idGen(v: number) {
		if (this.item) this.item.props.set('idGen', v)
		this._idGen = v
	}
	@computed get isMissing() { return this.id === void 0 }
	log = new SystemLogger()
	reIndex = signal<() => Promise<void>>()
	installDesktop = signal()

	createLocalStorage = {
		start: signal<() => Promise<void>>(),
		end: signal<(newStorageItemId: string) => void>(),
	}
	@computed get localStorage() {
		const s: BoxStorage = this.item?.findRelatedPropertyValue('storage')
		return s?.isLocal && s.isAvailable ? s : null
	}
}
