
import * as mdl from '../../../model'
import { CouchDataAccess } from './CouchDataAccess'

export interface Context {
	items: mdl.ItemManager
	boxes: mdl.BoxManager
	log: mdl.Logger
}

export const setup = {

	access: (ctx: Context) => {

		mdl.BoxStorage.protocols['couch'] = function(props): mdl.BoxStorageAccess {
			if (!props.url || !props.credential)
				return null
			const da = new CouchDataAccess(props, ctx.log)
			da.onChange.react(async (boxId: string, itemId: string) => {
				const item = ctx.items.getItem(itemId, true)
				if (item && item.isReady) {
					const d = await da.readItem(itemId, [boxId], true)
					if (d) {
						item.build(d)
						item.addToBox(ctx.boxes.getBoxItem(boxId))
					} else {
						// might just be a remove from box, not really a delete
						item.removeFromBox(ctx.boxes.getBoxItem(boxId))
					}
				}
			})
			return da
		}
	},

}

