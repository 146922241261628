import { Utils } from '../../../common'
import * as mdl from '../../../model'
import { migrate_v0 } from '../../../../../base/src/data/migrate/migrate_v0'
import { ItemDocBase } from './types'

export function queryUri(docId: string, view: string) {
	return '_design/' + docId + '/_view/' + view
}
export function boxIdToDbName(boxId: string) { return 'box_' + boxId }
export function dbNameToBoxId(dbName: string) {
	return dbName.startsWith('box_') ? dbName.substring(4) : null
}
export function itemToDocId(itemId: string) { return 'i' + itemId }
export function docToItemId(docId: string) { return docId.substring(1) }

export function isItemDoc(doc: { id: string } | { _id: string }) {
	if (doc === null) return false
	return doc['id' in doc ? 'id' : '_id'].startsWith('i')
}

export function toFullRev(doc: ItemDocBase) {
	return mdl.Item.toFullRev(
		{ rev: doc.item_rev, update: doc.update, create: doc.create })
}

export function docToItemData(doc: ItemDocBase) {
	if (!doc) return null
	const itemData: mdl.ItemData =
	{
		id: docToItemId(doc._id), props: {},
		_info: { couchdb: { _id: doc._id, _rev: doc._rev } }
	}
	if (doc.item_rev)
		itemData.rev = doc.item_rev
	if (doc.props)
		Utils.copyMembers(doc, itemData, 'create', 'update',
			'props', 'links', 'content', 'layoutId', 'tmpls')
	else if ('type' in doc)
		migrate_v0(doc, itemData)
	if ('_conflicts' in doc) {
		itemData.conflicts = [{ ...doc }, ...doc._conflicts]
		delete itemData.conflicts[0]._conflicts
	}
	if ('_attachments' in doc) {
		if (doc['type'] === 'image') {
			const attKey = 'file' in doc._attachments ? 'file' : 'image'
			const att = doc._attachments[attKey]
			if (!att)
				throw new Error(`Attachment ${attKey} for property ${name
					} of item ${itemData.id} missing!`)
			itemData.props.image.image = att.content_type === 'Buffer' ?
				Buffer.from(att['data'], 'base64') :
				Utils.base64ToBlob(att['data'], att.content_type)
			itemData.props.image.type = att.content_type
			if ('size' in att) itemData.props.image.size = att['size']
		} else {
			for (const name of Object.keys(itemData.props)) {
				const prop = itemData.props[name]
				if (!prop || typeof prop !== 'object')
					continue
				for (const k of Object.keys(prop)) {
					const v = prop[k]
					if (typeof v !== 'string' || !v.startsWith('attachment:'))
						continue
					const attKey = v.substring('attachment:'.length)
					const att = doc._attachments[attKey]
					if (!att)
						throw new Error(`Attachment ${attKey} for property ${name
							} of item ${itemData.id} missing!`)
					prop[k] = att.content_type === 'Buffer' ?
						Buffer.from(att['data'], 'base64') :
						Utils.base64ToBlob(att['data'], att.content_type)
					prop.type = att.content_type
					if ('size' in att) prop.size = att['size']
				}
			}
		}
	}
	Utils.deleteMembers(itemData as any,
		'_id', '_rev', '_attachments', '_conflicts')
	return itemData
}

export async function itemDataToDoc(data: mdl.ItemData) {
	if (data === null) return null
	// TODO: adapt to itemdata
	const doc: ItemDocBase = {
		...data, props: { ...data.props },
		_id: itemToDocId(data.id), item_rev: data.rev
	}
	delete doc._info
	if (doc.item_rev === 0)
		delete doc.item_rev
	Utils.deleteMembers(doc, 'id', 'rev')
	// replace blobs with references to attachments
	// only one blob per property!
	for (const name of Object.keys(doc.props)) {
		const prop = doc.props[name]
		if (!prop || typeof prop !== 'object')
			continue
		for (const k of Object.keys(prop)) {
			const v = prop[k]
			if (Utils.isBlob(v) || Utils.isBuffer(v)) {
				if (!('_attachments' in doc)) doc._attachments = {}
				doc._attachments[name] = {
					content_type: Utils.isBlob(v) ? v.type : 'Buffer',
					data: Utils.isBlob(v) ? await Utils.blobToBase64(v) :
						v.toString('base64')
				}
				doc.props[name] = { ...prop, [k]: 'attachment:' + name }
				break
			}
		}
	}
	return doc
}

export function queryToSearchText({ id, key, value }:
	{ id: string, key: string, value: string }) {
	return {
		id: docToItemId(id),
		rev: key,
		text: value
	}
}

export function queryToId({ id }: { id: string, key: string, value: string }) {
	return docToItemId(id)
}

