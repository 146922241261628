import { Credential, CredentialsProvider } from './Auth'
import { computed, signal } from './common'
import { Item } from './Item'
import { AccountValue } from './PropertyValue'

export class Account {
	provider: string
	url: string
	constructor(public readonly item: Item, data?: AccountValue) {
		this.item = item
		this.provider = data?.account
		this.url = data?.url
	}
	@computed get id() { return this.item.id }
	@computed get isActive() {
		return Account.isLocal(this.provider) || !!this.credential
	}
	static isLocal(provider: string) {
		return provider === 'local'
	}
	@computed get isLocal() { return Account.isLocal(this.provider) }
	static canLogin(provider: string, url: string) {
		return !!url && !Account.isLocal(provider)
	}
	@computed get canLogin() { return Account.canLogin(this.provider, this.url) }
	get $debug() {
		return this.id + ': ' + this.url
	}
	credentialsProvider: CredentialsProvider
	/** Authentication credentials to access this server. */
	@computed get credential() {
		return this.url && this.credentialsProvider?.getCredential(this.url)
	}
	login = signal<(credential: Credential) => Promise<void>>()
	readUser = signal<(credential: Credential) => Promise<void>>()
	logout = signal()
	changePassword = {
		start: signal<
			(oldPw: string, newPw1: string, newPw2: string) => Promise<void>>(),
		end: signal<(newPw: string) => Promise<void>>()
	}
}

