import * as React from 'react'
import * as mdl from '../model'
import { AddItem } from './AddItem'
import { Accounts } from './center/Accounts'
import { Component, observer, Observer } from './common'
import { CreateItem } from './CreateItem'
import { Details } from './Details'
import * as dialogs from './dialogs'
import { Dialog } from './dialogs/Dialog'
import { EditItem } from './EditItem'
import { Login } from './Login'
import { LogView } from './LogView'
import { NoItem } from './NoItem'
import { PlaceItem } from './PlaceItem'
import { Search } from './Search'
import { ViewItem } from './ViewItem'

interface Props {
	app: mdl.App
}

@observer
export class AppView extends Component<Props> {

	render() {
		const { app: { ui: { nav }, ui, config, installation: { log } } } =
			this.props
		return <div>
			<LogView log={log} />
			<Observer>{() => {
				if (!nav.view)
					return null
				if (!nav.view.isReady)
					return log.errors.length ? null :
						<img src="assets/loading.gif"
							className="loading" />
				const ctx = { nav, ui, config }
				switch (nav.view.key) {
					case 'view': return <ViewItem view={nav.view} context={ctx} />
					case 'edit': return <EditItem view={nav.view} context={ctx} />
					case 'place': return <PlaceItem view={nav.view} context={ctx} />
					case 'add': return <AddItem view={nav.view} context={ctx} />
					case 'create': return <CreateItem view={nav.view} context={ctx} />
					case 'search': return <Search view={nav.view} context={ctx} />
					case 'details': return <Details view={nav.view} context={ctx} />
					case 'accounts': return <Accounts view={nav.view} context={ctx} />
					case 'login': return <Login view={nav.view} />
					default: return <div>Hallo</div>
				}
			}}</Observer>
			<Observer>{() =>
				<>{ui.dialogs.map((d, i) => {
					const Content = dialogs[d.component]
					return <Dialog key={i}><Content dlg={d} /></Dialog>
				})}</>
			}</Observer>
		</div>
	}

}
