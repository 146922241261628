import { O, reaction, Utils, when } from '../common'
import * as mdl from '../model'
import { newItemWithTmpl } from './newItemWithTmpl'

export const setup = {

	credentialsProvider: ({ auth }: { auth: mdl.Auth }) => {
		O.onInit(mdl.Account, account => {
			account.credentialsProvider = auth
		})
	},

	loadItem: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.Item, item => {
			item.request1.react(item => boxes.loadItem(item, false))
			item.request2.react(item => boxes.loadItem(item, true))
		})
	},

	boxInStorage: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.Box, async box => {
			await when(() => box.item.isFinished)
			reaction(() => box.allStorages.map(Utils.toId), storageIds => {
				for (const storage of boxes.availableStorages) {
					if (storageIds.includes(storage.id))
						storage.access.addBox(box)
					else
						storage.access.removeBox(box.id)
				}
			})
		})
	},

	boxStorageLinks: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.Box, async box => {
			await when(() => box.item.isFinished)
			reaction(() => box.allStorages.map(Utils.toId), storageItemIds => {
				const idMap = Utils.arrayToObject(storageItemIds)
				for (const storageItem of boxes.storageItems) {
					if (storageItem.id in idMap)
						storageItem.links.add(box.item)
					else
						storageItem.links.remove(box.item.id)
				}
			})
		})
		O.onInit(mdl.BoxStorage, async storage => {
			await when(() => storage.item.isFinished)
			reaction(() => storage.allBoxes.map(Utils.toId), boxItemIds => {
				const idMap = Utils.arrayToObject(boxItemIds)
				for (const boxItem of boxes.allBoxItems) {
					if (boxItem.id in idMap)
						boxItem.links.add(storage.item)
					else
						boxItem.links.remove(storage.item.id)
				}
			})
		})
	},

	hideMarkProps: () => {
		O.onInit(mdl.Box, box => {
			box.item.request2.react(() => {
				box.item.props.hide('mark', 'color', 'backColor')
			})
		})
	},

	userBox: {

		box: ({ boxes }: { boxes: mdl.BoxManager }) => {
			// put a box also into the user box
			O.onInit(mdl.Box, ({ item }) => {
				item.request3.react(() => {
					if (boxes.userBoxItem && !item.isGenerated)
						item.addToBox(boxes.userBoxItem)
				})
			})
		},

		installation: ({ boxes }: { boxes: mdl.BoxManager }) => {
			// put an installation also into the user box
			O.onInit(mdl.Item, item => {
				item.request3.react(() => {
					if (boxes.userBoxItem && !item.isGenerated &&
						!!item.props.findByType('installation'))
						item.addToBox(boxes.userBoxItem)
				})
			})
		},

	},

	userInBox: ({ boxes }: { boxes: mdl.BoxManager }) => {
		// put a user into the user box
		O.onInit(mdl.Item, item => {
			item.request3.react(() => {
				// TODO: maybe better a user property value or something...
				if (boxes.userBoxItem && item.id.endsWith('.user'))
					item.addToBox(boxes.userBoxItem)
			})
		})
	},

	boxInOwnBox: () => {
		// always put a box item in it's own box
		O.onInit(mdl.Box, box => {
			const { item } = box
			item.request3.react(() => {
				if (!item.isGenerated)
					item.addToBox(box)
			})
		})
	},

	loadMissingItems: ({ items }: { items: mdl.ItemManager }) => {
		// after activating a box, there might be some missing items available
		O.onInit(mdl.Box, box => {
			reaction(() => box.isActive, active => {
				if (active) {
					setTimeout(() => {
						for (const item of items.missingItems)
							box.readItem(item.id).then(d => { item.build(d) })
					})
				}
			}, { fireImmediately: true })
		})
	},

	permissions: () => {
		O.onInit(mdl.Box, box => {
			reaction(() =>
				box.isActive && box.permissions === null && [...box.availableStorages],
				storages => {
					if (!storages)
						return
					for (const s of storages) {
						if ('readPermissions' in s.access) {
							s.access.readPermissions(box.id)
								.then(p => { box.permissions = p })
							return
						}
					}
				})
		})
	},

	createAccountForStorage: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.BoxStorage, storage => {
			storage.createAccount.start.react(async () => {
				const tmpl = await items.requireItem('account.tmpl')
				const newItem = newItemWithTmpl(items, tmpl, {
					props: [['account', (itm: mdl.Item) =>
						O.new(mdl.Account, itm, { account: 'couch', url: storage.url }),
						'account']]
				})
				await storage.item.links.addNew(newItem)
				storage.createAccount.end(newItem.id)
			})
		})
	},

	addBoxesToStorage: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.BoxStorage, storage => {
			storage.addBoxes.react(async () => {
				const boxInfos = await storage.access.getBoxes(boxes.allStorages
					.map(Utils.toId))
				for (const boxInfo of boxInfos) {
					if (!boxInfo.permissions || boxInfo.permissions === 'no')
						continue
					await boxes.readBox([storage.url], boxInfo.id)
					const box = boxes.getBox(boxInfo.id)
					box.permissions = boxInfo.permissions
					storage.item.links.add(box.item, 0)
				}
			})
		})
	},

}
