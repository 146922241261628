import { IGNORE_ERROR, O, reaction } from '../../common'
import * as mdl from '../../model'

export module setup {

	export function view({ items }: { items: mdl.ItemManager }) {
		O.onInit(mdl.ItemView, (view, id: string) => {
			const idx = id.indexOf(',')
			if (idx > 0) id = id.substring(0, idx)
			view.item = items.requestItem(id)
			view.item.complete()
				.then(() => {
					// complete the direct linked items also
					const links = view.item.links
					reaction(() => links.available, links => {
						links.forEach(link =>
							items.requestItem(link.refId).complete().catch(IGNORE_ERROR))
					}, { fireImmediately: true })
				})
				.catch(IGNORE_ERROR)
		})
	}

}
