import { action, httpClient, O, reaction } from '../common'
import * as mdl from '../model'

const STORAGE_KEY_SESSION = 'session'

interface UserData {
	userId: string
	userBoxStorageUrls: string[]
	userBoxId: string
}

export const setup = {
	login: ({ config, boxes, session, inst }:
		{
			config: { api: { auth: string } }, inst: mdl.Installation,
			boxes: mdl.BoxManager, session: mdl.Session
		}) => {
		O.onInit(mdl.LoginView, view => {
			view.login.start.react(async (loginName, password) => {
				loginName = loginName.toLowerCase()
				const cred: mdl.PasswordCredential =
					{ id: loginName, type: 'password', password }
				const http = httpClient(cred)
				const data: UserData = await http.get(
					config.api.auth + '/_users/org.couchdb.user:' + loginName)
				session.auth.setCredential(config.api.auth, cred)
				if (data.userBoxStorageUrls)
					for (const url of data.userBoxStorageUrls)
						// TODO: smarter need-credential detection
						if (url.startsWith('http'))
							session.auth.setCredential(url, cred)
				await login(boxes, session, data.userId,
					data.userBoxStorageUrls, data.userBoxId)
				session.isReady = true
				await inst.reIndex()
					.then(() => {
						view.login.end()
					}, (err) => {
						console.log(err)
						view.login.end()
					})
			})
		})
	},

	// TODO: logout feature
	// TODO: login only needed for online boxes

	userSession: {
		read: ({ session }: { session: mdl.Session }) => {
			const data = JSON.parse(localStorage.getItem(STORAGE_KEY_SESSION))
			if (data?.credentials) {
				session.auth.setCredential(data.credentials)
			}
		},

		autoLogin: async ({ boxes, session, app }:
			{ boxes: mdl.BoxManager, session: mdl.Session, app: mdl.AppBase }) => {
			app.init.reactOnce(async () => {
				const data: UserData =
					JSON.parse(localStorage.getItem(STORAGE_KEY_SESSION))
				if (data?.userId && data.userBoxId && data.userBoxStorageUrls?.length) {
					await login(boxes, session, data.userId,
						data.userBoxStorageUrls, data.userBoxId)
				}
				session.isReady = true
			})
		},

		update: ({ session }: { session: mdl.Session }) => {
			reaction(() => session.user &&
				mdl.Box.getBox(session.userBoxItem)?.availableStorages[0]?.url ?
				{
					userId: session.user.id, userBoxId: session.userBoxItem.id,
					userBoxStorageUrls: mdl.Box.getBox(session.userBoxItem)
						.availableStorages.map(s => s.url),
					credentials: session.auth.allCredentials
				} : null,
				data => {
					if (data?.userId)
						localStorage.setItem(STORAGE_KEY_SESSION, JSON.stringify(data))
				})
		},

	},

	accountLogin: ({ log, session }:
		{ log: mdl.Logger, session: mdl.Session }) => {
		O.onInit(mdl.Account, account => {
			account.login.react(async (credential: mdl.Credential) => {
				const oldCredential = session.auth.getCredential(account.url)
				session.auth.setCredential(account.url, credential)
				try {
					await account.readUser(credential)
				} catch (err) {
					session.auth.setCredential(account.url, oldCredential)
					log.error('Login failed!', { err })
				}
			})
			account.logout.react(async () => {
				session.auth.removeCredential(account.url)
			})
			account.changePassword.end.react(async newPw => {
				const cred = session.auth.getCredential(account.url)
				if (cred.type === 'password') {
					cred['password'] = newPw
					session.auth.setCredential(account.url, { ...cred })
				}
			})
		})
	},

}

async function login(boxes: mdl.BoxManager, session: mdl.Session,
	userId: string, userBoxStorageUrls: string[], userBoxId: string) {
	await boxes.readBox(userBoxStorageUrls, userBoxId)
	const boxItem = boxes.getBoxItem(userBoxId)
	const box = mdl.Box.getBox(boxItem)
	if (!box)
		throw new Error(`Item ${boxItem.id} does not contain a box!`)
	const storage = box.findStorageByUrl(...userBoxStorageUrls)
	if (!storage)
		throw new Error(`Box ${boxItem.id} does not contain a storage item!`)
	action(() => {
		session.user = boxes.items.getItem(userId)
		session.userBoxItem = boxItem
		boxes.userBoxItem = boxItem
		// activate box
		box.isActive = true
		// activate storage
		storage.isActive = true
	})()
	await session.user.request()
}
