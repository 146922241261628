import * as mdl from '..'
import { Utils } from '../../common'

function getDateProp(item: mdl.Item) {
	return item.props.findByType('date')
}

function isDateItem(item: mdl.Item, date: Date) {
	return Utils.sameDate(getDateProp(item)?.value, date)
}

export namespace CalendarLayout {

	export function findDateItem(item: mdl.Item, date: Date) {
		if (!item?.links || !date) return null
		for (const ln of item.links) {
			if (isDateItem(ln.item, date))
				return ln.item
		}
		return null
	}

	export function getItemsForDate(item: mdl.Item, date: Date): mdl.Item[] {
		const items = []
		if (item?.links) {
			for (const ln of item.links) {
				if (isDateItem(ln.item, date) && ln.item.links)
					items.push(...ln.item.links.map(ln => ln.item))
			}
		}
		return items
	}

}
