
import { newItemsFromText } from '../../../base/src/data/import'
import { O, urlPattern } from '../common'
import * as mdl from '../model'
import { ItemStatus } from '../model'

const MIME_TYPE_HTML = 'text/html'

interface ClipboardItem {
	types: string[]
	getType(type: string): Promise<Blob>
}

export const setup = {

	paste: ({ nav, items }:
		{ nav: mdl.Navigation, items: mdl.ItemManager }) => {
		document.addEventListener('paste', (evn: ClipboardEvent) => {
			if (handleEvent(evn))
				emitData(evn.clipboardData, nav, items)
		})
	},

	dragDrop: ({ nav, items }:
		{ nav: mdl.Navigation, items: mdl.ItemManager }) => {
		let delayTimer = null
		let dropMarker: Node = null
		const dropMarkerClear = () => {
			if (dropMarker)
				document.body.removeChild(dropMarker)
			dropMarker = null
		}
		document.addEventListener('drop', (evn: DragEvent) => {
			if (handleEvent(evn)) {
				emitData(evn.dataTransfer, nav, items)
				evn.preventDefault()
			}
			dropMarkerClear()
		})
		document.addEventListener('dragover', (evn: DragEvent) => {
			if (!evn.dataTransfer.types.includes('allsbe/link') && handleEvent(evn)) {
				if (!dropMarker) {
					document.body.insertAdjacentHTML('beforeend',
						'<div class="dialog dropArea">' +
						'<div>drop to add</div>' +
						'<div class="curtain"></div>' +
						'</div>')
					dropMarker = document.body.lastChild
				}
				clearTimeout(delayTimer)
				delayTimer = null
				evn.dataTransfer.dropEffect = 'copy'
				evn.preventDefault()
			}
		})
		document.addEventListener('dragleave', (evn: DragEvent) => {
			if (!delayTimer)
				delayTimer = setTimeout(dropMarkerClear, 100)
		})
	},

	AddItemViewPaste: ({ nav, items, log }:
		{
			nav: mdl.Navigation, items: mdl.ItemManager, log: mdl.SystemLogger
		}) => {
		O.onInit(mdl.AddItemView, async view => {
			view.onPaste.react(async () => {
				try {
					if ('read' in navigator.clipboard) {
						const dataItems: ClipboardItem[] = await navigator.clipboard.read()
						for (const dataItem of dataItems) {
							const imgType = dataItem.types.find(t => t.startsWith('image/'))
							if (imgType) {
								const imgBlob = await dataItem.getType(imgType)
								if (dataItem.types.includes(MIME_TYPE_HTML)) {
									const html = await dataItem.getType(MIME_TYPE_HTML)
										.then(b => b.text())
									await emitNewImageData(imgBlob, nav, items,
										extractImageSource(html, MIME_TYPE_HTML))
								} else {
									await emitNewImageData(imgBlob, nav, items)
								}
							} else {
								const type = dataItem.types[0]
								const txt = await dataItem.getType(type).then(b => b.text())
								await emitNewTextData(txt, nav, items, type)
							}
						}
						nav.back()
					} else if ('readText' in navigator.clipboard) {
						const text = await navigator.clipboard.readText()
						await emitNewTextData(text, nav, items)
						nav.back()
					}
				}
				catch (err) {
					log.error(err)
				}
			})
		})
	},
}

function handleEvent(evn: Event) {
	const e = evn.target as HTMLElement
	if (['input', 'textarea'].indexOf(e.localName) >= 0 || e.isContentEditable)
		return false
	return true
}

function emitData(data: DataTransfer, nav: mdl.Navigation,
	items: mdl.ItemManager) {
	if (!nav.view ||
		!(nav.view.key === 'view' || nav.view.key === 'edit') || !nav.view.item)
		return
	const { item } = nav.view
	// internal drops
	const internalData = data.getData('allsbe/link')
	if (internalData) {
		// TODO: handle internal drops...
		// const d = JSON.parse(internalData)
		// if (item && d.itemId === item.id) return
		return
	}
	const dts =
		{ text: null as DataTransferItem, image: null as DataTransferItem }
	for (const dt of data.items) {
		if (dt.kind === 'string' && !dts.text) dts.text = dt
		if (dt.kind === 'file' && !dts.image) dts.image = dt
	}
	if (nav.view.key === 'edit') {
		const view: mdl.EditItemView = nav.view
		if (dts.image) {
			const imgProp = item.props.findByType('image')
			if (imgProp) {
				// TODO: get source
				view.form.values[imgProp.name].val = dts.image.getAsFile()
				return
			}
			const textProp = item.props.findByType('string', 'text')
			if (textProp) {
				view.form.values[textProp.name].val = dts.image.getAsFile().name
				return
			}
		} else if (dts.text) {
			dts.text.getAsString(str => {
				if (urlPattern.image.test(str)) {
					const imgProp = item.props.findByType('image')
					if (imgProp) {
						view.form.values[imgProp.name].val = { url: str }
						return
					}
				}
				const textProp = item.props.findByType('string', 'text')
				if (textProp) {
					view.form.values[textProp.name].val = str
					return
				}
			})
		}
	}
	else {
		if (dts.image) {
			const blob = dts.image.getAsFile()
			if (dts.text) {
				// Chrome 89: dts.text.type seems to be empty within getAsString hander!
				const t = dts.text.type
				dts.text.getAsString(str => {
					emitNewImageData(blob, nav, items, extractImageSource(str, t))
				})
			} else {
				emitNewImageData(blob, nav, items)
			}
		} else if (dts.text) {
			// Chrome 89: dts.text.type seems to be empty within getAsString hander!
			const t = dts.text.type
			dts.text.getAsString(str => { emitNewTextData(str, nav, items, t) })
		}
	}
}

function extractImageSource(str: string, type: string) {
	if (type.startsWith(MIME_TYPE_HTML)) {
		const dom = new DOMParser().parseFromString(str, MIME_TYPE_HTML)
		if (dom?.images?.length > 0)
			return dom.images[0].src
	}
	return str
}

async function emitNewTextData(text: string, nav: mdl.Navigation,
	items: mdl.ItemManager, type?: string) {
	if (!nav.view || !(nav.view.key === 'view' || nav.view.key === 'add')
		|| !nav.view.item)
		return
	const { item } = nav.view
	const newItems = await newItemsFromText(text, items, type)
	await Promise.all(newItems.map(newItem => emitLinkToNew(nav, item, newItem)))
}

async function emitNewImageData(blob: Blob, nav: mdl.Navigation,
	items: mdl.ItemManager, source?: string) {
	if (!nav.view || !(nav.view.key === 'view' || nav.view.key === 'add')
		|| !nav.view.item)
		return
	const { item } = nav.view
	const newItem = items.createItem()
	newItem.props.set('image', source ? { image: blob, source } : blob, 'image')
	newItem.tmpls.add(items.getItem('image.tmpl'))
	await emitLinkToNew(nav, item, newItem)
}

async function emitLinkToNew(nav: mdl.Navigation,
	item: mdl.Item, newItem: mdl.Item) {
	await item.links.addNew(newItem)
	//	nav.go('edit', newItem.id)
}
