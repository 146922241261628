import { buildData } from '../../../base/src/data/build'
import { readItem } from '../../../base/src/data/read'
import { action, autorun, IGNORE_ERROR, O, reaction, Utils, when }
	from '../common'
import * as mdl from '../model'
import { IndexedDbStore } from './common/IndexedDb'
import { generateInstallationId } from './installationId'
import { AsyncStorage, SyncStorage } from './Storage'

const STORAGE_KEY_INSTALLATION = 'installation'
const STORAGE_KEY_ID_GEN = 'idGen'
const STORAGE_KEY_LOG = 'log'

export const setup = {

	initNew: ({ config, session, inst, items }: {
		config: { api: { auth: string } }, inst: mdl.Installation,
		session: mdl.Session, items: mdl.ItemManager
	}) => {
		inst.initNew.react(async item => {
			item.id = await generateInstallationId(config.api, session.auth)
			item.props.set('title', 'Installation ' + item.id)
			item.props.set('info', {
				userAgent: navigator.userAgent,
				language: navigator.language || navigator['userLanguage'] ||
					navigator['browserLanguage'] || navigator['systemLanguage'],
				screen: { ...window.screen, pixelRatio: window.devicePixelRatio },
			}, 'object')
			const tmpl = items.requestItem('installation.tmpl')
			await when(() => tmpl.isReady)
			item.tmpls.add(tmpl)
		})
	},

	store: ({ app, items, inst, sysStorage, bakStorage }: {
		app: mdl.AppBoot, items: mdl.ItemManager, inst: mdl.Installation,
		sysStorage: SyncStorage, bakStorage: AsyncStorage
	}) => {
		// migrate installation data storage
		const str = sysStorage.getItem(STORAGE_KEY_INSTALLATION)
		if (str?.startsWith('{')) {
			const d = JSON.parse(str)
			if (d.id)
				sysStorage.setItem(STORAGE_KEY_INSTALLATION, d.id)
		}

		app.init.react(() => {
			const instId = sysStorage.getItem(STORAGE_KEY_INSTALLATION)
			if (instId) {
				const item = items.getItem(instId)
				inst.setItem(item)
			} else {
				when(() => !!inst.id, () => {
					sysStorage.setItem(STORAGE_KEY_INSTALLATION, inst.id)
					bakStorage.setVal(STORAGE_KEY_INSTALLATION, inst.id)
				})
			}
		})
	},

	external: ({ app, inst, sysStorage, items }: {
		app: mdl.AppBoot, inst: mdl.Installation, sysStorage: SyncStorage,
		items: mdl.ItemManager
	}) => {
		app.init.react(() => {
			// observe installation data in browser storage
			sysStorage.onChange(evn => {
				if (evn.key !== STORAGE_KEY_INSTALLATION) return
				if (evn.newValue && !inst.item) {
					const item = items.getItem(evn.newValue)
					inst.setItem(item)
				}
			})
		})
	},

	idGen: {
		read: ({ app, inst, sysStorage, bakStorage }: {
			app: mdl.AppBoot, inst: mdl.Installation,
			sysStorage: SyncStorage, bakStorage: AsyncStorage
		}) => {
			app.init.react(() => {
				// load idGen
				const idGen = parseInt(sysStorage.getItem(STORAGE_KEY_ID_GEN))
				if (idGen && idGen > inst.idGen)
					action(() => { inst.idGen = idGen })()
				// verify with value with backup
				bakStorage.getVal(STORAGE_KEY_ID_GEN)
					.then(idGen => {
						if (idGen > inst.idGen) {
							console.warn(`IdGen (${inst.idGen
								}) is smaller than the one in backup (${idGen})!`)
							action(() => { inst.idGen = idGen })()
						}
					})
					.catch(err => { inst.log.error(err) })
				// update
				reaction(() => inst.idGen, idGen => {
					if (!idGen) return
					const current = sysStorage.getItem(STORAGE_KEY_ID_GEN)
					if (current && parseInt(current) > idGen) {
						console.warn(`Try to save idGen (${idGen
							}) that is smaller than the one in storage (${current})!`)
						action(() => { inst.idGen = parseInt(current) })()
					} else if (!current || parseInt(current) < idGen) {
						sysStorage.setItem(STORAGE_KEY_ID_GEN, '' + idGen)
						// backup
						bakStorage.setVal(STORAGE_KEY_ID_GEN, idGen)
							.catch(err => { inst.log.error(err) })
					}
				})
			})
		},

		external: ({ app, inst, sysStorage }:
			{ app: mdl.AppBoot, inst: mdl.Installation, sysStorage: SyncStorage }
		) => {
			app.init.react(() => {
				// observe idGen in storage
				sysStorage.onChange(async evn => {
					if (evn.key !== STORAGE_KEY_ID_GEN) return
					const idGen = parseInt(evn.newValue)
					if (idGen > inst.idGen)
						// another instance has generated a new ID
						action(() => { inst.idGen = idGen })()
					else if (idGen < inst.idGen)
						// another instance has tried to set a lower ID generator value =>
						// the higher one wins
						sysStorage.setItem(STORAGE_KEY_ID_GEN, '' + inst.idGen)
				})
			})
		},

		debug: ({ app, inst }: { app: mdl.AppBoot, inst: mdl.Installation }) => {
			app.init.react(() => {
				// DEBUG: detect ID gen to detect overwrites
				let oldIdGen = -1
				autorun(() => {
					//		console.log('ID Gen', oldIdGen, inst.idGen)
					if (inst.idGen < oldIdGen)
						console.warn(
							`ID Generator ${oldIdGen} has been overwritten with ${inst.idGen
							}!`)
					oldIdGen = inst.idGen
				})
			})
		},

	},

	systemLog: {
		read: ({ inst, sysStorage }:
			{ inst: mdl.Installation, sysStorage: SyncStorage }) => {
			inst.log.requestArchive.react(() => {
				const archive = sysStorage.getItem(STORAGE_KEY_LOG)?.split('\n')
				if (archive) inst.log.archive = archive
				else if (!inst.log.archive) inst.log.archive = []
			})
		},

		update: ({ inst, sysStorage }:
			{ inst: mdl.Installation, sysStorage: SyncStorage }) => {
			reaction(() => inst.log.entries.map(e => mdl.LogEntry.format(e)),
				entries => {
					const archive = sysStorage.getItem(STORAGE_KEY_LOG)?.split('\n')
					if (archive && archive.length > 0) entries = append(archive, entries)
					sysStorage.setItem(STORAGE_KEY_LOG, entries.join('\n'))
				})
		},

		delete: ({ inst, sysStorage }:
			{ inst: mdl.Installation, sysStorage: SyncStorage }) => {
			reaction(() => inst.log.archive?.length,
				len => { if (len === 0) sysStorage.removeItem(STORAGE_KEY_LOG) })
		},

		console: ({ inst }: { inst: mdl.Installation }) => {
			for (const l of ['error', 'warn', 'log', 'info']) {
				console['direct_' + l] = console[l]
				console[l] = (...args: any[]) => {
					inst.log.console(mdl.LogLevel[l], args)
				}
			}
		},

	},

	localBoxStorage: ({ inst, boxes, items }: {
		inst: mdl.Installation, boxes: mdl.BoxManager, items: mdl.ItemManager
	}) => {
		inst.createLocalStorage.start.react(async () => {
			const storage = boxes.availableStorages.find(s => s.isLocal)
			if (storage) {
				inst.item.links.add(storage.item)
				storage.item.links.add(inst.item)
			} else {
				const storageItem = await items.createNewItem()
				storageItem.props.set('title', inst.item.labelText + ' Storage')
				storageItem.props.set('storage', O.new(mdl.BoxStorage, storageItem,
					{ storage: 'indexed', url: 'allsbe:' + inst.id }), 'storage')
				inst.item.links.add(storageItem)
				storageItem.links.add(inst.item)
				storageItem.status = mdl.ItemStatus.level2
				await storageItem.complete()
				storageItem.addToBoxesOf(inst.item)
				inst.createLocalStorage.end(storageItem.id)
			}
		})
	}

}

export function append(archive: string[], entries: string[]) {
	const last = archive[archive.length - 1]
	const newIdx = Utils.findLastIndex(entries, e => e <= last)
	entries = [...archive, ...entries.slice(newIdx + 1)]
	return entries
}

let indexedDbBakStorage: AsyncStorage

export function createIndexedDbBakStorage() {
	if (!indexedDbBakStorage)
		indexedDbBakStorage = new IndexedDbStore('bak', 'installation')
	return indexedDbBakStorage
}
