import { Box, ItemData } from './Box'
import { BoxManager } from './BoxManager'
import { action, observable, signal } from './common'
import { Item } from './Item'
import { ItemManager } from './ItemManager'
import { Logger } from './SystemLog'

declare const __DEBUG__: boolean

interface Action {
	itemId: string
	type: 'create' | 'update' | 'delete'
	data?: ItemData
}

interface Actions {
	[boxId: string]: { [itemId: string]: Action }
}

export class ChangeManager {
	constructor(public boxes: { getBox(id: string): Box },
		public items: ItemManager, public log: Logger) { }

	@observable hasPending = false

	private actions: Actions = {}
	get hasWaitingActions() { return Object.keys(this.actions).length > 0 }

	signalChanges = signal<() => void>(action(() => { this.hasPending = true }))

	signalSave = signal(async () => {
		const actions = this.actions
		this.actions = {}
		// changed items
		const pendingItemBoxIds = getBoxIds(actions)
		// save per box
		const boxIds = Object.keys(actions)
		const boxErrors: { [boxId: string]: Error } = {}
		await Promise.all(boxIds.map(async boxId => {
			try {
				// try to save items into this box
				const boxActions = actions[boxId]
				await Promise.all(Object.values(boxActions)
					.map(action => this.saveAction(action, boxId)))
				// save for this box was successful => items not pending anymore
				for (const id of Object.keys(boxActions)) {
					delete pendingItemBoxIds[id]
					this.commitAction(boxActions[id])
				}
			}
			catch (err) {
				boxErrors[boxId] = err
			}
		}))
		// handle errors
		logErrors(this.log, pendingItemBoxIds, boxErrors, boxIds)
		markErrorBoxes(this.boxes, pendingItemBoxIds, Object.keys(boxErrors),
			boxIds, actions)
		// reload still pending items
		await this.reloadItems(pendingItemBoxIds).catch(this.log.error)
		// maybe there are already new actions waiting...
		this.hasPending = this.hasWaitingActions
	})

	private commitAction(action: Action) {
		if (action.data) {
			const newAction = getChangeActionForItem(this.actions, action.itemId)
			if (newAction?.data)
				newAction.data.rev = action.data.rev + 1
			const item = this.items.getItem(action.itemId, true)
			if (item) {
				item.rev = action.data.rev
				if (!newAction)
					item.hasChanged = false
			}
		}
	}

	private async reloadItems(itemBoxes: { [itemId: string]: string[] }) {
		const promises = []
		for (const id of Object.keys(itemBoxes)) {
			const item = this.items.getItem(id, true)
			if (item) {
				for (const boxId of itemBoxes[id]) {
					const box = this.boxes.getBox(boxId)
					promises.push(box.readItem(id, true).then(d => {
						item.settingData = true
						if (d)
							item.addToBox(box)
						else
							item.removeFromBox(box)
						item.settingData = false
						return d
					}))
				}
			}
		}
		const data: ItemData[] = await Promise.all(promises)
		const newestData: { [id: string]: ItemData } = {}
		for (const d of data) {
			if (d && !containsNewerOrEqualRev(newestData, d))
				newestData[d.id] = d
		}
		for (const id of Object.keys(newestData)) {
			const item = this.items.getItem(id, true)
			// item might not be cached anymore => no need to rebuild
			if (item) {
				item.build(newestData[id], true)
				item.hasChanged = false
			}
		}
	}

	setChange(data: ItemData, boxIds: string[]) {
		const { id } = data
		for (const boxId of boxIds) {
			if (!(boxId in this.actions)) this.actions[boxId] = {}
			const boxActions = this.actions[boxId]
			if (id in boxActions) {
				const action = boxActions[id]
				action.data = data
				if (action.type === 'create') delete action.data.rev
				if (action.type === 'delete') action.type = 'update'
			} else {
				boxActions[id] = { itemId: id, type: 'update', data }
			}
		}
		this.signalChanges()
	}

	setAdd(data: ItemData, boxId: string) {
		const { id } = data
		if (!(boxId in this.actions)) this.actions[boxId] = {}
		const boxActions = this.actions[boxId]
		if (id in boxActions) {
			const action = boxActions[id]
			action.data = data
			if (action.type === 'delete') action.type = 'create'
		} else {
			boxActions[id] = { itemId: id, type: 'create', data }
		}
		this.signalChanges()
	}

	setRemove(id: string, boxId: string) {
		if (!(boxId in this.actions)) this.actions[boxId] = {}
		const boxActions = this.actions[boxId]
		if (id in boxActions) {
			const action = boxActions[id]
			if (action.type === 'create')
				delete boxActions[id]
			else
				action.type = 'delete'
		} else {
			boxActions[id] = { itemId: id, type: 'delete' }
		}
		this.signalChanges()
	}

	// save data to a box
	private async saveAction({ type, itemId, data }: Action,
		boxId: string) {
		const box = this.boxes.getBox(boxId)

		if (data && data.rev <= 0)
			delete data.rev

		if (__DEBUG__ && typeof location !== 'undefined' &&
			(location.hostname in { 'localhost': 1, 'dev.server': 1 })
			&& !navigator.webdriver) {
			const msg = `${type} item ${itemId} in box ${boxId}`
			if (type === 'delete') console['direct_log'](msg)
			else console['direct_log'](msg, data)

			// TODO: remove ignore-save
			if (typeof window !== 'undefined' && window['__ignore_changes'])
				return
		}

		if (type === 'delete') {
			await box.removeItem(itemId)
		} else {
			if (data)
				await box.writeItem(data)
		}
	}

}

function containsNewerOrEqualRev(newestData: { [id: string]: ItemData },
	data: ItemData) {
	return data.id in newestData &&
		Item.compareRev(newestData[data.id], data) >= 0
}

function getBoxIds(actions: Actions) {
	const pendingItemBoxIds: { [itemId: string]: string[] } = {}
	for (const boxId of Object.keys(actions)) {
		for (const id of Object.keys(actions[boxId])) {
			if (id in pendingItemBoxIds)
				pendingItemBoxIds[id].push(boxId)

			else
				pendingItemBoxIds[id] = [boxId]
		}
	}
	return pendingItemBoxIds
}

function getChangeActionForItem(actions: Actions, itemId: string) {
	for (const boxActions of Object.values(actions))
		if (itemId in boxActions)
			return boxActions[itemId]
	return null
}

function logErrors(log: Logger,
	pendingItemBoxIds: { [itemId: string]: string[] },
	boxErrors: { [boxId: string]: Error }, boxIds: string[]) {
	const itemIdsToReload = Object.keys(pendingItemBoxIds)
	const errorBoxIds = Object.keys(boxErrors)
	if (errorBoxIds.length > 0) {
		const msg = errorBoxIds.length === boxIds.length ?
			'Failed to save changes!' :
			`Failed to save changes in ${errorBoxIds.length
			} of ${boxIds.length} boxes!`
		log.error(itemIdsToReload.length > 0 ? msg +
			` => Reload item(s) ${itemIdsToReload.join(', ')}.` : msg, boxErrors)
	} else if (itemIdsToReload.length > 0) {
		// should never happen...
		log.error('Saved changes incomplete!' +
			` => Reload item(s) ${itemIdsToReload.join(', ')}.`)
	}
}

function markErrorBoxes(boxes: { getBox(id: string): Box },
	itemsToReload: { [itemId: string]: any },
	errorBoxIds: string[], boxIds: string[], actions: Actions) {
	if (errorBoxIds.length > 0 && errorBoxIds.length !== boxIds.length) {
		for (const boxId of errorBoxIds) {
			const boxActions = actions[boxId]
			const box = boxes.getBox(boxId)
			for (const itemId of Object.keys(boxActions)) {
				if (!(itemId in itemsToReload))
					box.addDirtyItemId(itemId)
			}
		}
	}
}

