import { Utils } from '../../common'
import { computed, observable, signal } from '../common'
import { Item } from '../Item'
import { ImageValue } from '../PropertyValue'

export class ImageCapture {
	static readonly imageUrlPattern = /^(https?:\/\/|data:image\/|blob:)./
	static readonly iconUrlPattern = /^(text:|material-icon:)./

	@observable isOpen = false
	@observable isCanceled = false
	open = signal(() => {
		this.isOpen = true
		this.isCanceled = false
	})

	@observable recentItems: Item[]
	@computed get recentIcons(): (string | Blob)[] {
		return this.recentItems?.map(item => item.props.findByType('icon')?.value)
			.filter(Utils.isTrue)
			.filter(Utils.distinct)
	}
	@computed get recentImages(): ImageValue[] {
		const items = this.recentItems
		return items?.map(item => item.props.findByType('image')?.value)
			.filter(ImageValue.isValid)
			.concat(...items.map(item =>
				item.links?.map(ln => ln.item?.props.findByType('image')?.value)
					.filter(ImageValue.isValid)).filter(Utils.isTrue))
			.filter(Utils.distinct)
	}
	@observable fontIcons: { title: string, icons: string[] }[]
}
