import { observable } from 'mobx'
import { O, Utils } from '../common'
import { action, computed } from './common'
import { Item } from './Item'
import { Property, PropertyType } from './Property'
import { propertyActionValues } from './PropertyActionValue'


export class Properties {

	@observable.shallow private map = new Map<string, Property>()

	constructor(public item: Item) { }

	@computed get asList() { return [...this.map.values()] }
	@computed get asObject() { return Utils.mapToObject(this.map) }

	*[Symbol.iterator]() {
		yield* this.map.values()
	}

	get length() { return this.map.size }
	get isEmpty() { return this.map.size <= 0 }

	keys() { return this.map.keys() }
	values() { return this.map.values() }
	get(...names: string[]) {
		for (const n of names) {
			if (this.map.has(n))
				return this.map.get(n)
		}
		return null
	}
	has(name: string) { return this.map.has(name) }

	hide(...names: string[]) {
		for (const n of names) {
			if (this.map.has(n))
				this.map.get(n).hidden = true
		}
	}

	findByType<T = any>(...types: PropertyType[]): Property<T> {
		for (const t of types) {
			for (const p of this.map.values())
				if (p.type === t)
					return p
		}
		return null
	}

	findByTypeInTmpl(...types: PropertyType[]) {
		for (const tmpl of this.item.tmpls) {
			for (const t of types) {
				for (const p of tmpl.item.props.values())
					if (p.type === t)
						return p
			}
		}
		return null
	}

	findByAction(action: string) {
		const a = propertyActionValues[action]
		for (const p of this.map.values()) {
			if (p.type === 'action' && p.value?.constructor === a)
				return p
		}
		return null
	}

	@computed get icon() {
		return this.get('icon') ?? this.findByType('icon')
	}

	@computed get title() {
		return this.get('label') ?? this.get('title')
	}

	@computed get label() {
		return this.title ?? this.findByType('string', 'text', 'url') ??
			(this.isEmpty ? null : this.asList[0])
	}

	@computed get content() {
		return this.asList.filter(p => (p !== this.label || (p.type !== 'string' &&
			p.type !== 'date')) &&
			p !== this.icon && p.type !== 'action' && !p.hidden)
	}

	@computed get editable() {
		const list = this.label && this.label.name !== 'caption' ?
			[this.label, ...this.asList.filter(p => p !== this.label)] :
			[...this.map.values()]
		const additions = {}
		for (const t of this.item.tmpls) {
			for (const p of t.item.props) {
				if (!this.map.has(p.name) && !(p.name in additions)) {
					const v = p.type === 'action' || p.type === 'account' ?
						O.new(p.value.constructor, this.item) : null
					list.push(O.new(Property, this.item, p.name, v, p.type))
					additions[p.name] = p
				}
			}
		}
		return list
	}

	set<T>(prop: Property<T>): void
	set<T>(name: string, value: T, type?: PropertyType): void
	@action set(name: string | Property, value?: any, type?: PropertyType) {
		if (name instanceof Property) {
			if (name.value !== void 0)
				this.map.set(name.name, name)
			return
		}
		if (value === void 0)
			return
		// DEBUG: test for invalid values
		if (value && typeof value === 'object' && 'item' in value &&
			value.item !== this.item && this.item.id !== 'installation.sys')
			console.warn(`Setting property ${name} to item ${this.item.id
				} with value pointing to item ${value.item.id}!`, value)
		// TODO: implement immutable properties
		if (this.map.has(name)) {
			this.map.get(name).value = value
		} else {
			const p = O.new(Property, this.item, name, value, type)
			this.map.set(name, p)
		}
	}

	@action delete(name: string) { return this.map.delete(name) }

}
