import { setUrlInfo, UrlInfo }
	from '../../../base/src/data/import'
import { O, reaction, readResponse, Utils } from '../common'
import * as mdl from '../model'

interface Config {
	api: {
		services: { preview: string, proxy: string }
		linkPreview: { url: string, key: string }
	}
}

export const setup = {
	capture: ({ config, items, log }:
		{ config: Config, items: mdl.ItemManager, log: mdl.Logger }) => {
		O.onInit(mdl.Item, item => {
			item.request3.reactOnce(() => {
				reaction(() => item.props.findByType('url'), urlProp => {
					if (!urlProp) return
					const titleProp = item.props.get('title')
					if (urlProp.value && !titleProp?.value)
						captureUrlPreview(item, config, items).catch(log.error)
				}, { fireImmediately: true })
			})
		})
	},

	research: ({ config, items, log }:
		{ config: Config, items: mdl.ItemManager, log: mdl.Logger }) => {
		O.onInit(mdl.Item, item => {
			item.research.react(() => captureUrlPreview(item, config, items)
				.catch(log.error))
		})
	},

}

async function captureUrlPreview(item: mdl.Item, config: Config,
	items: mdl.ItemManager) {
	// TODO: check for URL template
	const urlProp: mdl.Property<string> = item.props.findByType('url')
	if (!urlProp?.value) return
	const url = urlProp.value
	let info: UrlInfo = UrlInfo.parser['$special']?.(null, '$special', url) ?? {}
	let baseUrl = info.url ?? url
	if (!info.title || !info.icon) {
		const resp = await fetch(Utils.addUrlParams(config.api.services.proxy,
			{ url: baseUrl }))
		const txt = await readResponse(resp, 'text')
		const ct = resp.headers.get('Content-Type')
		const t = Utils.substringBefore(ct, ';') ?? ct
		const respUrl = resp.headers.get('X-Response-Url')
		if (respUrl) baseUrl = respUrl
		if (txt && t in UrlInfo.parser)
			Object.assign(info, UrlInfo.parser[t](txt, t, baseUrl), info)
	}
	if (!info.icon)
		// try the /favicon.ico
		info.icon = await fetchIcon(
			new URL(baseUrl).origin + '/favicon.ico', config, null)
	if (typeof info.icon === 'string' && !info.icon.startsWith('data:'))
		info.icon = await fetchIcon(info.icon, config, info.icon)
	if (typeof info.image === 'string' && !info.image.startsWith('data:'))
		info.image = await fetchImage(info.image, config, info.image)
	// console.log(info)
	setUrlInfo(item, info, items, urlProp)
}

async function fetchIcon(url: string, { api: { services } }: Config,
	defaultValue = null) {
	const icon = await fetch(Utils.addUrlParams(services.proxy, { url }))
		.then(r => r.ok ? r.blob() : null)
		.then(Utils.blobToDataUrl)
		.catch<string>(() => null)
	return icon ? { icon, source: url, class: 'small' } : defaultValue
}

async function fetchImage(url: string, { api: { services } }: Config,
	defaultValue = null) {
	const image = await fetch(Utils.addUrlParams(services.proxy, { url }))
		.then(r => r.ok ? r.blob() : null)
		.catch<Blob>(() => null)
	return image ? { image, source: url } : defaultValue
}

