import * as React from 'react'
import { Component, Icon, observable, observer } from '..'

interface Props<T extends { [k in K]: Array<T[K][0]> }, K extends keyof T> {
	title: string
	source: T
	member: K
	children: (v: T[K][0], idx: number) => JSX.Element
	onSelect: (url: string) => void
}

@observer
export class IconList<T extends { [k in K]: Array<T[K][0]> }, K extends keyof T>
	extends Component<Props<T, K>> {

	@observable show = false

	render() {
		const { title, source, member, children, onSelect } = this.props
		const col = source[member] as any
		return col ? <>
			<label onClick={this.toggle}>
				<Icon uri={this.show ? 'expand_more' : 'chevron_right'} />
				{title}
			</label>
			{this.show &&
				<div className="searchResults icons" onClick={this.onSelect}>
					{col.map(children)}
				</div>}
		</> : null
	}

	toggle = () => { this.show = !this.show }

	onSelect = (evn: React.MouseEvent<HTMLElement>) => {
		const trg = evn.target as HTMLElement
		this.props.onSelect('src' in trg ? trg['src'] :
			'src' in trg.firstChild ? trg.firstChild['src'] :
				'uri' in trg.dataset ? trg.dataset.uri :
					trg.textContent && trg.textContent.includes(':') ?
						trg.textContent : trg.textContent ? 'text:' + trg.textContent :
							null)
	}

}

