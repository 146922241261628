import { urlPattern } from '../../../common'
import * as mdl from '../../../model'
import { textImporters } from './common'

textImporters.push(newItemsForUrl)

function newItemsForUrl(txt: string, items: mdl.ItemManager) {
	if (!txt.startsWith('http') || !urlPattern.basic.test(txt))
		return null
	const item = items.createItem()
	if (urlPattern.image.test(txt)) {
		item.props.set('image', txt, 'image')
		item.tmpls.add(items.getItem('image.tmpl'))
	} else {
		item.props.set('url', txt, 'url')
		item.tmpls.add(items.getItem('url.tmpl'))
	}
	return [item]
}
