// common utilities
// TODO: sync with /common/Utils.ts
// TODO: split into categories

export interface Constructor<T> extends Function { new(...args: any): T }

export class ErrorX extends Error {
	constructor(message: string, public cause?: Error) {
		super(message + ' - ' + cause.message)
	}
}

// TODO: be more exact
export const urlPattern = {
	basic: /^https?:\/\/\S+$/,
	image: /^(https?:\/\/[^ #\?]+\.(png|gif|jpg|jpeg)|data:image\/.+)([#\?].*)?$/,
}

// detect nodejs
declare var process
export class Env {
	static isNodeJs = typeof process !== 'undefined' && !process.browser
}

// 2D vector operations
export class Vector {
	static add(v1: number[], v2: number[]) {
		return [v1[0] + v2[0], v1[1] + v2[1]]
	}
	static sub(v1: number[], v2: number[]) {
		return [v1[0] - v2[0], v1[1] - v2[1]]
	}
	static mul(v: number[], f: number) { return [v[0] * f, v[1] * f] }
}

// TODO: unit tests
export class ObjectType {
	// TODO: Date and RegExp in JSON
	private static valueTypes = ['Boolean', 'Number', 'String', 'Date', 'RegExp']
	private static objTypes = ['Function', 'Array', 'Object', 'Error']
	private static typeMap = {}
	private static valueTypeMap = {}
	private static toString = {}.toString

	private static init = (() => {
		for (var i = 0, t; (t = ObjectType.valueTypes[i]); ++i)
			ObjectType.typeMap['[object ' + t + ']'] = t
		for (var i = 0, t; (t = ObjectType.objTypes[i]); ++i)
			ObjectType.typeMap['[object ' + t + ']'] = t
		for (var i = 0, t; (t = ObjectType.valueTypes[i]); ++i)
			ObjectType.valueTypeMap['[object ' + t + ']'] = true
	})()

	static get(obj: any) {
		return ObjectType.typeMap[ObjectType.toString.call(obj)]
	}

	static isValue(obj: any) {
		// TODO: experiment with typeof... to potentially improve speed?
		return ObjectType.valueTypeMap[ObjectType.toString.call(obj)] === true
	}
}

// nextTick
const nextTickFns = !Env.isNodeJs ? [] : void 0
function runNextTickFns() {
	const fns = [].concat(nextTickFns)
	nextTickFns.length = 0
	for (let fn of fns) fn()
}

declare const setImmediate

export function nextTick(fn: () => void) {
	if (typeof process !== 'undefined' && typeof process.nextTick === 'function')
		process.nextTick(fn)
	else if (nextTickFns) {
		nextTickFns.push(fn)
		if (typeof setImmediate === 'function') setImmediate(runNextTickFns)
		else setTimeout(runNextTickFns, 0)
	} else if (typeof setImmediate === 'function') setImmediate(fn)
	else setTimeout(fn, 0)
}

export function delay(milliseconds: number) {
	return new Promise<void>((resolve, reject) => {
		function fn() { try { resolve() } catch (err) { reject(err) } }
		if (milliseconds > 0) setTimeout(fn, milliseconds)
		else nextTick(fn)
	})
}

export class SetMultiMap {

	static add<K, V>(map: Map<K, Set<V>>, key: K, value: V) {
		let values = map.get(key)
		if (!values) map.set(key, new Set<V>([value]))
		else values.add(value)
		return map
	}

	static getFirst<K, V>(map: Map<K, Set<V>>, key: K) {
		let values = map.get(key)
		if (!values || values.size < 1) return null
		return values.values().next().value
	}

}

export class ArraySetMultiMap {

	static add<K, V>(map: Map<K, V[]>, key: K, value: V) {
		let values = map.get(key)
		if (!values) map.set(key, [value])
		else if (values.indexOf(value) < 0) values.push(value)
		return map
	}

	static getFirst<K, V>(map: Map<K, V[]>, key: K) {
		let values = map.get(key)
		if (!values || values.length < 1) return null
		return values[0]
	}

}

export class FlatPromise<T> {
	resolve: (v: T) => void
	reject: (reason: any) => void
	promise = new Promise<T>((res, rej) => {
		this.resolve = res
		this.reject = rej
	})
}

export const IGNORE_ERROR = (err: Error) => { }


// type helpers

// https://github.com/Microsoft/TypeScript/issues/12215#issuecomment-307871458
export type Key = string | number | symbol
export type Diff<T extends Key, U extends Key> =
	({ [P in T]: P } & { [P in U]: never } & { [x: string]: never })[T]
export type Omit<T, K extends keyof T> = { [P in Diff<keyof T, K>]: T[P] }
export type Overwrite<T, U> = { [P in Diff<keyof T, keyof U>]: T[P] } & U

// examples
type A = { a: string, b: number }
type B = { a: boolean }
type O = Omit<A, keyof B>
type V = Overwrite<A, B>
