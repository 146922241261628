import { Observer, observer } from 'mobx-react'
import * as React from 'react'
import { observable, Utils } from '../common'
import * as mdl from '../model'
import { Component, Context, dateTimeFormat, Icon, Menu } from './common'
import { ItemEditor, ItemIcon, ItemLabel } from './LayoutViews'
import { TemplatesList } from './Templates'

const showExperimental = !!localStorage.getItem('experiments')

interface Props {
	view: mdl.EditItemView
	context: Context
}

@observer
export class EditItem extends Component<Props> {

	render() {
		const { view: { item, form, addTmpl, removeTmpl }, context } = this.props
		return <div className="edit view">
			<header>
				<Observer>{() =>
					<button onClick={this.onOk} disabled={form.hasErrors}
						className={form.isDirty ? 'active' : ''}>
						<Icon uri={form.hasErrors ? 'error' :
							form.isDirty ? 'navOk' : 'navBack'} />
					</button>
				}</Observer>
				<div>
					<ItemIcon item={item} context={context} />
					<span><ItemLabel item={item} context={context} /></span>
				</div>
				<Menu>
					<div onClick={this.onPlace}>place</div>
					<div onClick={this.onDelete}>delete</div>
				</Menu>
			</header>
			<section>
				<div className="content">
					<ItemEditor item={item} context={context} form={form} />
				</div>
				{showExperimental && <div className="content">
					<TemplatesList title="Templates" item={item} context={context}
						onAddTmpl={addTmpl} onRemoveTmpl={removeTmpl} />
				</div>}
			</section>
			{item.conflicts && <section className="conflicts">
				{item.conflicts.map(c =>
					<Conflict key={c._rev ?? c} data={c} item={item} />)}
			</section>}
			<footer>
				<span>Item: {item.id}
					{item.create.date &&
						`, created at ${dateTimeFormat.format(item.create.date)}`}
				</span>
				<div className="buttons">
					<Observer>{() =>
						<button onClick={this.onOk}
							disabled={form.hasErrors || !form.isDirty}>
							<Icon uri="ok" /></button>
					}</Observer>
					<button onClick={this.onCancel}><Icon uri="cancel" /></button>
				</div>
			</footer>
		</div>
	}

	onOk = () => { this.submit() }
	onCancel = () => { this.props.context.nav.back() }
	onPlace = () => { this.props.context.nav.go('place') }
	onDelete = () => {
		this.props.view.item.delete()
		// TODO: go back to the previous item (go back 2 won't 
		// cover it completely...)
		this.props.context.nav.back(2)
	}
	onKey = (evn: KeyboardEvent) => {
		const { nav } = this.props.context
		switch (evn.key) {
			case 'Enter': evn.ctrlKey && this.submit(); break
			case 'Escape': nav.back(); break
		}
	}

	submit() {
		const { form } = this.props.view
		if (form.hasErrors) return
		form.submit()
		this.props.context.nav.back()
	}
}


interface ConflictProps {
	item: mdl.Item
	data: { _id: string, _rev: string }
}

export class Conflict extends Component<ConflictProps> {

	render() {
		const { data } = this.props
		return <div>
			<h5>
				{data._rev ?? data}{' '}
				<button onClick={this.onSelect}>Select</button>
			</h5>
			{data._rev &&
				<pre>
					{Utils.stringify(
						Utils.deleteMembers({ ...data }, '_id', '_rev'), '  ')}
				</pre>
			}
		</div>
	}

	onSelect = () => {
		const { item, data } = this.props
		item.selectRev(data._rev)
	}
}
