import * as React from 'react'

export class CancelClick extends React.Component {

	render() {
		return <span onClick={this.onClick}>
			{this.props.children}
		</span>
	}

	onClick = (evn: React.MouseEvent<HTMLElement>) => {
		evn.stopPropagation()
		evn.preventDefault()
	}
}
