import * as React from 'react'
import * as mdl from '../../model'
import { f } from '../common'
import { ItemIcon, ItemLabel } from '../LayoutViews'
import { PropContent } from '../PropertyViews'
import { Layout } from './common'

export const comment: Layout = {
	icon: (item, context) =>
		<ItemIcon item={item.create.user} context={context} />,
	content: (item, context) => <div className="comment">
		<h6><ItemLabel item={item.create.user} context={context} />
			<span>{f.dateTime(item.create.date)}</span></h6>
		{item.props.asList.map(p =>
			<PropContent key={p.name} prop={p} context={context} />)}
	</div>,
	links: {
		small: (item, context) => <div className="comment">
			<h6>{f.dateTime(item.create.date)}</h6>
			<PropContent prop={getContentProp(item)} context={context} />
		</div>,
		compact: (item, context) => <div className="comment">
			<h6><ItemLabel item={item.create.user} context={context} />
				<span>{f.dateTime(item.create.date)}</span></h6>
			<PropContent prop={getContentProp(item)} context={context} />
		</div>,
		normal: (item, context) => <>
			<ItemIcon item={item.create.user} context={context} />
			<div className="comment">
				<h6><ItemLabel item={item.create.user} context={context} />
					<span>{f.dateTime(item.create.date)}</span></h6>
				<PropContent prop={getContentProp(item)} context={context} />
			</div>
		</>,
		extended: (item, context) => <>
			<ItemIcon item={item.create.user} context={context} />
			<div className="comment">
				<h6><ItemLabel item={item.create.user} context={context} />
					<span>{f.dateTime(item.create.date)}</span></h6>
				{item.props.asList.map(p =>
					<PropContent key={p.name} prop={p} context={context} />)}
			</div>
		</>,
	},
}

function getContentProp(item: mdl.Item): mdl.Property {
	return item.props.get('comment', 'text', 'title', 'label') ??
		item.props.findByType('text') ?? item.props.findByType('string')
}

