import {
	action, autorun, extendObservable, IReactionDisposer, isObservableArray,
	observable
} from 'mobx'
import { O } from './ObjectStatus'

/** Manages disposers per object. */
export class ReactionDisposer<O extends {}> {
	map = new WeakMap<O, IReactionDisposer>()
	dispose = (obj: O) => {
		if (this.map.has(obj)) {
			this.map.get(obj)()
			this.map.delete(obj)
		}
	}
	set(obj: O, val: IReactionDisposer) {
		this.dispose(obj)
		this.map.set(obj, val)
	}

}

const globalDisposer = new WeakMap<Object, IReactionDisposer[]>()

export function disposeWithObject(obj: Object, val: IReactionDisposer) {
	if (globalDisposer.has(obj)) globalDisposer.get(obj).push(val)
	else globalDisposer.set(obj, [val])
}

O.onDispose(Object, obj => {
	if (globalDisposer.has(obj)) {
		for (const d of globalDisposer.get(obj)) d()
		globalDisposer.delete(obj)
	}
})

export function isArray<T>(arr: T[] | T): arr is T[] {
	return Array.isArray(arr) || isObservableArray(arr)
}

export function setComputed<T, K extends keyof T>(target: T, member: K,
	fn: () => T[K]) {
	extendObservable(target, {
		get [member]() { return fn() }
	})
}
