import { Utils } from '../../../base/src/common'

export const jsonHeaders = {
	'Accept': 'application/json',
	'Content-Type': 'application/json',
}

export async function request(url: string, req: RequestInit, params?: {}) {
	try {
		return await fetch(Utils.addUrlParams(url, params), req)
	}
	catch (err) {
		throw new ResponseError(`Failed to ${req.method ?? 'get'} ${url}!`,
			url, -1, null, { error: Utils.errorToJson(err), params }, err.name)
	}
}

export function httpClient(auth?: { id: string, password: string },
	init?: (req: RequestInit) => void) {
	const req: RequestInit = { headers: { ...jsonHeaders } }
	if (auth)
		req.headers['Authorization'] = basicAuth(auth)
	if (init)
		init(req)
	return {
		get: <R = any>(url: string, params?: {}) =>
			request(url, req, params).then(readResponse) as Promise<R>,
		getText: (url: string, params?: {}) =>
			request(url, req, params).then(readText),
		getJson: <R = any>(url: string, params?: {}) =>
			request(url, req, params).then(readJson) as Promise<R>,
		getBlob: <R = any>(url: string, params?: {}) =>
			request(url, req, params).then(readBlob) as Promise<R>,
		post: <R = any>(url: string, body: any) =>
			request(url, { ...req, method: 'post', body: JSON.stringify(body) })
				.then(readResponse) as Promise<R>,
		postForm: <R = any>(url: string, data: {}) =>
			request(url, {
				...req,
				headers: {
					...req.headers, 'Content-Type': 'application/x-www-form-urlencoded'
				},
				method: 'post', body: new URLSearchParams(data)
			}).then(readResponse) as Promise<R>,
		put: <R = any>(url: string, body: any) =>
			request(url, { ...req, method: 'put', body: JSON.stringify(body) })
				.then(readResponse) as Promise<R>,
		delete: <R = any>(url: string) => request(url,
			{ ...req, method: 'delete' }).then(readResponse) as Promise<R>,
	}
}

export function basicAuth({ id, password }: { id: string, password: string }) {
	return 'Basic ' + btoa(id + ':' + password)
}

export class ResponseError extends Error {
	constructor(msg: string, public url: string, public status: number,
		public headers: object, public data: string | object,
		name = 'ResponseError') {
		super(msg)
		this.name = name
	}
	static create(resp: Response, data?: object | string,
		name?: string) {
		let msg = resp.statusText
		if (!msg)
			msg = Utils.extractErrorMessage(data) ?? 'Unknown Error!'
		return new ResponseError(msg, resp.url, resp.status,
			headersToObject(resp.headers), data, name)
	}
}

export function headersToObject(headers: Headers) {
	return headers ? Array.from(headers)
		.reduce((obj, h) => ({ ...obj, [h[0]]: h[1] }), {}) : null
}

export async function readResponse<R = any>(resp: Response,
	expectedContentType?: 'text' | 'json' | 'blob' | string): Promise<R> {
	if (!expectedContentType) {
		const ct = resp.headers?.get('Content-Type')
		expectedContentType = ct?.startsWith(jsonHeaders['Content-Type']) ?
			'json' : 'text'
	}
	if (!resp.ok) {
		let data = null
		try { data = await resp.text() }
		catch (err) { /* just try... */ }
		if (expectedContentType?.endsWith('json')) {
			try { data = JSON.parse(data) }
			catch (err) { }
		}
		throw ResponseError.create(resp, data)
	}
	if (['text', 'json', 'xml'].find(t => expectedContentType?.endsWith(t))) {
		// text response
		let txt = null
		try { txt = await resp.text() }
		catch (err) { throw ResponseError.create(resp, err.message, err.name) }
		if (expectedContentType?.endsWith('json')) {
			try {
				return JSON.parse(txt)
			}
			catch (err) {
				throw ResponseError.create(resp, { error: err.message, data: txt },
					err.name)
			}
		}
		return txt
	} else {
		// binary response
		return await resp.blob() as any
	}
}

export function readText(resp: Response) {
	return readResponse<string>(resp, 'text')
}

export function readJson<R = any>(resp: Response) {
	return readResponse<R>(resp, 'json')
}

export async function readBlob(resp: Response) {
	return readResponse<Blob>(resp, 'blob')
}

