export * from '../../../../base/src/ui'
export { observer } from 'mobx-react'

const mobileUserAgentPattern =
	/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
export function isMobile() {
	return mobileUserAgentPattern.test(navigator.userAgent)
}
export function hasCapture() {
	const inpt = document.createElement('input')
	inpt.setAttribute('capture', 'true')
	return !!inpt['capture']
}

export const numFormat = new Intl.NumberFormat('de-CH')
export const dateFormat = new Intl.DateTimeFormat('de-CH')
export const timeFormat = new Intl.DateTimeFormat('de-CH', {
	hour: 'numeric', minute: 'numeric', second: 'numeric',
})
export const dateTimeFormat = new Intl.DateTimeFormat('de-CH', {
	year: 'numeric', month: 'numeric', day: 'numeric',
	hour: 'numeric', minute: 'numeric', second: 'numeric',
})
export const f = {
	num: numFormat.format,
	date: dateFormat.format,
	time: timeFormat.format,
	dateTime: dateTimeFormat.format,
	duration: (startOrMilliseconds: Date | number, end?: Date | number) => {
		if (typeof startOrMilliseconds !== 'number') {
			if (!startOrMilliseconds) return ''
			if (!end) end = new Date()
			startOrMilliseconds = (typeof end === 'number' ? end : end.getTime()) -
				startOrMilliseconds.getTime()
		}
		let str = Math.floor(startOrMilliseconds / 1000 % 60) + 's'
		if (startOrMilliseconds > 60 * 1000)
			str = Math.floor(startOrMilliseconds / 1000 / 60 % 60) + 'min ' + str
		if (startOrMilliseconds > 60 * 60 * 1000)
			str = Math.floor(startOrMilliseconds / 1000 / 60 / 60) + 'h ' + str
		return str
	},
	isoDate: (date: Date) => date.toISOString().substring(0, 10),
}

export function css(...args: ({} | string)[]) {
	return args.filter(a => !!a)
		.map(a => typeof a === 'string' ? a :
			Object.keys(a).filter(c => !!a[c]).join(' '))
		.filter(c => !!c).join(' ')
}

export function autoGrow(elem: HTMLElement, addHeight = 0) {
	if (!elem) return
	elem.style.height = '1em'
	elem.style.height = (elem.scrollHeight + addHeight) + 'px'
}

export class Dom {

	static top(elem: HTMLElement) {
		let v = 0
		while (elem) {
			v += elem.offsetTop
			elem = elem.offsetParent as HTMLElement
		}
		return v
	}

	static left(elem: HTMLElement) {
		let v = 0
		while (elem) {
			v += elem.offsetLeft
			elem = elem.offsetParent as HTMLElement
		}
		return v
	}

	static findTargetChildIndex(evn: React.MouseEvent<HTMLImageElement>) {
		const { children } = evn.currentTarget
		const { target } = evn
		let idx = 0
		for (; idx < children.length; ++idx)
			if (children[idx] === target || children[idx].firstChild === target) break
		return idx < children.length ? idx : -1
	}

}

export function equalsColor(data: Uint8ClampedArray, offset: number,
	r: number, g: number, b: number, tolerance = 10) {
	return data[offset + 0] > r - tolerance && data[offset + 0] < r + tolerance &&
		data[offset + 1] > g - tolerance && data[offset + 1] < g + tolerance &&
		data[offset + 2] > b - tolerance && data[offset + 2] < b + tolerance
}

export function setColor(data: Uint8ClampedArray, offset: number,
	r: number, g: number, b: number, a = 255) {
	data[offset + 0] = r
	data[offset + 1] = g
	data[offset + 2] = b
	data[offset + 3] = a
}
