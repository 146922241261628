import { setup as actions } from './actions'
import { setup as boxes } from './boxes'
import { setup as generatedItems } from './generatedItems'
import { setup as Item } from './Item'
import { setup as Installation } from './Installation'
import { setup as ItemId } from './ItemId'
import { setup as ItemIdAliases } from './itemIdAliases'
import { setup as ItemLogs } from './ItemLogs'
import { setup as views } from './views'

export const setup = {
	boxes, generatedItems, Item, ItemId, ItemIdAliases, ItemLogs, Installation,
	views, actions
}

