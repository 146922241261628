import { Item } from '../Item'
import { Link } from '../Link'
import { NavData } from '../Navigation'
import { action, computed, observable } from './common'
import { ItemView } from './ItemView'

export class ViewItemView extends ItemView {
	readonly key = 'view'
	// mode
	@observable mode: 'view' | 'move' = 'view'
	@action configure(loc: NavData) {
		this.mode = loc.key === 'move' ? 'move' : 'view'
	}
	// boxMarks
	@observable boxMarks = false
	// selection
	@observable selected: Link[] = []
	@computed get hasSelected() { return this.selected.length > 0 }
	@computed get allSelected() {
		return this.selected.length === this.item.links.available.length
	}
	/** Is item link currently selected? */
	isSelected(link: Link) { return this.selected.indexOf(link) >= 0 }
	/** Select/unselect item link. */
	@action select(link: Link) {
		const idx = this.selected.indexOf(link)
		if (idx < 0) this.selected.push(link)
		else this.selected.splice(idx, 1)
	}

	@observable allFromLinks: Item[]
	@computed get fromLinks() {
		const ids =
			this.item && this.item.links ? this.item.links.map(l => l.refId) : []
		const items = this.allFromLinks && this.allFromLinks
			.filter(r => ids.indexOf(r.id) < 0)
		return items && items.length > 0 ? items : null
	}

	@action addFromLinks(items: Item[]) {
		for (const item of items) {
			if (this.allFromLinks?.find(ln => ln.id === item.id))
				return
			if (this.allFromLinks) this.allFromLinks.push(item)
			else this.allFromLinks = [item]
		}
	}

}
