import { observer } from 'mobx-react'
import * as React from 'react'
import * as mdl from '../model'
import * as logo from '../style/logo.svg'
import { Component, dateTimeFormat, Icon, Link, Menu } from './common'
import { ItemContent, ItemIcon, ItemLabel } from './LayoutViews'
import { ViewItemLinks } from './ViewItemLinks'

interface Props {
	view: mdl.ViewItemView
	context: {
		config: mdl.Config
		nav: mdl.Navigation
		ui: mdl.UserInterface
	}
}

@observer
export class ViewItem extends Component<Props> {

	render() {
		const { view, view: { item, mode },
			context, context: { nav, ui: { clipboard } } } = this.props
		return <div className="view">
			<header>
				{nav.hasHistory ?
					<button onClick={this.onBack} onDoubleClick={this.onSearch}
						onContextMenu={this.onSearch}>
						<Icon uri={view.hasSelected ?
							view.allSelected ? 'checked' : 'unchecked' : 'navBack'} />
					</button> :
					<img className="logo icon" src={logo} />}
				{view.hasSelected ? <>
					<div><span>{view.selected.length} selected</span></div>
					{!item.isReadOnly && <button onClick={this.onRename}>
						<Icon uri="label" title="Rename selected items." />
					</button>}
					<button onClick={this.onCopy}>
						<Icon uri="copy" title="Copy selected items to clipboard." />
					</button>
					{!item.isReadOnly && <button onClick={this.onCut}>
						<Icon uri="cut" title="Move selected items into clipboard." />
					</button>}
					{!item.isReadOnly && <Menu>
						{mdl.previewLevels.map(l =>
							<span key={l} onClick={this['on_' + l]}
								title={`Show selected links in a ${l} preview.`}>
								<Icon uri={l + '_preview'} />
							</span>
						)}
						<div onClick={this.onPlace}
							title="Place selected items into boxes.">
							<Icon uri="place" /> place</div>
						<div onClick={this.onDelete}
							title={'Remove selected links from this item.' +
								' The items themselves will not be deleted.'}>
							<Icon uri="delete" /> delete</div>
					</Menu>}
				</> : mode === 'move' ? <>
					<Icon uri="import_export" /><span>reorder...</span>
				</> : <>
					<div onDoubleClick={this.onEdit}>
						<ItemIcon item={item} context={context} />
						<span><ItemLabel item={item} context={context} /></span>
					</div>
					{!item.isReadOnly && clipboard?.length > 0 &&
						<button onClick={this.onPaste} className="pinned">
							<Icon uri="paste" title="Link to all items from clipboard." />
						</button>
					}
					<button onClick={this.onSearch} className="pinned">
						<Icon uri="search" title="Search item with text." />
					</button>
					<Menu>
						<div onClick={this.onHome}
							title="Navigate to the home item.">
							<Icon uri="home" /> home</div>
						<div onClick={this.onSearch}
							title="Search item with text.">
							<Icon uri="search" />search</div>
						{!item.isReadOnly && <div onClick={this.onLink}>
							<Icon uri="add" />add/link</div>}
						<div onClick={this.onBoxMarks}
							title="Toggle Box marks next to links.">
							<Icon uri="box" /> box marks</div>
						<div onClick={this.onDetails}>
							<Icon uri="info" />details</div>
						{!item.isReadOnly && <div onClick={this.onEdit}>
							<Icon uri="edit" /> edit</div>}
						<div onClick={this.onCopy}
							title="Copy current item to be linked to.">
							<Icon uri="copy" /> copy</div>
						{!item.isReadOnly && <div onClick={this.onMove}
							title="Move or select links.">
							<Icon uri="move" /> move, select</div>}
						{!item.isReadOnly && <div onClick={this.onPlace}>
							<Icon uri="place" /> place</div>}
						{!item.isReadOnly && <div onClick={item.research}>
							<Icon uri="biotech" /> research</div>}
						{!item.isGenerated && <div onClick={item.refresh}>
							<Icon uri="refresh" /> refresh</div>}
						{!item.isReadOnly && <div onClick={this.onDelete}>
							<Icon uri="delete" /> delete</div>}
					</Menu>
				</>
				}
			</header>
			<section>
				{item.isMissing ?
					<div className="missing">
						<div>
							Cannot find this item in any of your active <Link
								itemId="boxes.sys" nav={nav}>boxes</Link>!
						</div>
						<div>
							<button onClick={this.showBoxes}>Show Boxes</button>
							<button onClick={this.showStorages}>Show Storages</button>
							<button onClick={this.onBack}>Back</button>
						</div>
					</div> : <>
						<div className="content">
							<ItemContent item={item} context={context} />
						</div>
						<ViewItemLinks {...this.props} />
					</>}
			</section>
			<footer>
				<span>Item: {item.id}
					{item.create.date &&
						`, created at ${dateTimeFormat.format(item.create.date)}`}
					{item.conflicts && ', has conflicts!'}
				</span>
				{mode !== 'move' && !item.isReadOnly &&
					<div className="buttons">
						<button className="plus" onClick={this.onLink}>+</button>
					</div>
				}
			</footer>
		</div>
	}

	showBoxes = () => { this.props.context.nav.go('view', 'boxes.sys') }
	showStorages = () => { this.props.context.nav.go('view', 'storages.sys') }
	onHome = () => { this.props.context.nav.go('view', 'home') }
	onBack = () => {
		const { view, context: { nav } } = this.props
		if (!view.hasSelected)
			nav.back()
		view.selected = view.hasSelected && !view.allSelected ?
			[...view.item.links.available] : []
	}
	onDetails = () => { this.props.context.nav.go('details') }
	onBoxMarks = () => { this.props.view.boxMarks = !this.props.view.boxMarks }
	onEdit = (evn?: React.MouseEvent) => {
		evn?.preventDefault()
		if (!this.props.view.item.isGenerated)
			this.props.context.nav.go('edit')
	}
	onPlace = () => {
		const { view, context: { nav } } = this.props
		nav.go('place',
			view.hasSelected ? view.selected.map(l => l.item.id).join(',') : void 0)
	}
	onLink = () => {
		// delay for the click to be handled and a possible later autoFocus works
		setTimeout(() => { this.props.context.nav.go('add') })
	}
	onSearch = (evn: React.MouseEvent) => {
		evn.preventDefault()
		// delay for the click to be handled and a possible later autoFocus works
		setTimeout(() => { this.props.context.nav.go('search') })
	}
	onDelete = () => {
		const { view, context: { nav } } = this.props
		if (view.hasSelected) {
			view.item.links.remove(view.selected)
			this.reset()
		}
		else {
			view.item.delete()
			nav.back()
		}
	}
	onMove = () => {
		this.props.context.nav.go(this.props.view.mode === 'move' ? 'view' : 'move')
	}
	onRename = () => {
		const { view } = this.props
		const name = prompt('Rename', view.selected[0].name)
		if (name === null) return
		for (const ln of view.selected) ln.name = name
		this.reset()
	}
	onCopy = () => {
		const { context: { ui }, view } = this.props
		ui.clipboard = view.hasSelected ? view.selected : [view.item]
		this.reset()
	}
	onCut = () => {
		const { context: { ui }, view } = this.props
		ui.clipboard = view.selected
		view.item.links.remove(view.selected)
		this.reset()
	}
	onPaste = () => {
		const { context: { ui }, view } = this.props
		view.item.links.add(ui.clipboard, 0)
		ui.clipboard = []
	}
	on_small = () => { preview(this.props.view.selected, 'small') }
	on_compact = () => { preview(this.props.view.selected, 'compact') }
	on_normal = () => { preview(this.props.view.selected, 'normal') }
	on_extended = () => { preview(this.props.view.selected, 'extended') }
	on_full = () => { preview(this.props.view.selected, 'full') }
	onDocumentKey = (evn: React.KeyboardEvent<HTMLBodyElement>) => {
		const { context: { nav } } = this.props
		switch (evn.key) {
			case 'h': this.onHome(); break
			case '+': nav.go('add'); break
			case 'a': nav.go('add'); break
			case 's': nav.go('search', '/'); break
			case 'e': this.onEdit(); break
			case 'p': this.onPlace(); break
			case 'm': this.onMove(); break
			case 'b': this.onBoxMarks(); break
			case 'r': this.props.view.item.research(); break
			case 'd': this.onDetails(); break
		}
	}


	private reset() {
		const { view, context: { nav } } = this.props
		if (view.mode !== 'view') nav.back()
		view.selected = []
	}
}

function preview(links: mdl.Link[], level: mdl.PreviewLevel) {
	for (const ln of links) ln.preview = level
}
