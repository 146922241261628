import * as React from 'react'
import { ItemContent, ItemIcon } from '../LayoutViews'
import { Layout } from './common'

export const text: Layout = {
	links: {
		small: (item, context) => <>
			{item.props.icon && <ItemIcon item={item} context={context} />}
			<div>
				{item.props.title && <h6>{item.props.title.value}</h6>}
				<ItemContent item={item} context={context} />
			</div>
		</>,
	},
}
