import { observable } from 'mobx'
import * as React from 'react'
import { computed, Utils } from '../../common'
import { css, observer } from './base'
import { Icon } from './icons'

interface Props {
	startMonth: Date
	renderDate: (date: Date) => React.ReactNode
	onSelect: (date: Date) => void
}

@observer
export class Calendar extends React.Component<Props> {

	@observable selectedMonth: Date
	@computed get month() {
		return this.selectedMonth ||
			getMonth(this.props.startMonth ?? new Date(Date.now()))
	}
	set month(v: Date) {
		this.selectedMonth = v
	}

	render() {
		if (!this.month)
			return null
		const days = [...Array(Utils.daysInMonth(this.month)).keys()]
			.map(i => new Date(this.month.getFullYear(),
				this.month.getMonth(), i + 1))
		return <div className="calendar">
			<div className="header">
				<YearMonthSelector target={this} member="month" />
				<button onClick={this.inc}><Icon uri="chevron_right" /></button>
				<button onClick={this.dec}><Icon uri="chevron_left" /></button>
			</div>
			<div className="days">
				{weekdays.map(s => <div key={s} className="weekday">{s}</div>)}
				{days.map(d => <Day key={d.getDate()} date={d} {...this.props} />)}
			</div>
		</div>
	}

	inc = () => {
		const d = new Date(this.month)
		d.setMonth(d.getMonth() + 1)
		this.selectedMonth = d
	}

	dec = () => {
		const d = new Date(this.month)
		d.setMonth(d.getMonth() - 1)
		this.selectedMonth = d
	}

}

interface SelectorProps<T> {
	target: T
	member: keyof T
}

@observer
export class YearMonthSelector<T> extends React.Component<SelectorProps<T>> {

	@observable show = false

	render() {
		const { target, member } = this.props
		const d: Date = target[member] as any
		const month = d.getMonth()
		const year = d.getFullYear()
		return <div className="selector" onClick={this.toggle}>
			<div>{`${months[month]} ${year}`}</div>
			{this.show && <div onClick={this.select}>
				<div>
					<button onClick={this.dec}><Icon uri="chevron_left" /></button>
					<span>{year}</span>
					<button onClick={this.inc}><Icon uri="chevron_right" /></button>
				</div>
				{months.map((m, i) =>
					<span key={m} className={i === month ? 'selected' : ''}>{m}</span>)}
			</div>}
		</div>
	}

	toggle = () => { this.show = !this.show }

	select = (evn: React.MouseEvent) => {
		const elem = (evn.target as Element)
		if (elem.localName === 'span') {
			const v = [...elem.parentNode.children].indexOf(elem)
			const { target, member } = this.props
			const d: Date = target[member] as any
			target[member] = new Date(d.getFullYear(), v - 1) as any
		}
	}

	inc = (evn: React.MouseEvent) => {
		evn.stopPropagation()
		const { target, member } = this.props
		const d: Date = target[member] as any
		target[member] = new Date(d.getFullYear() + 1, d.getMonth()) as any
	}

	dec = (evn: React.MouseEvent) => {
		evn.stopPropagation()
		const { target, member } = this.props
		const d: Date = target[member] as any
		target[member] = new Date(d.getFullYear() - 1, d.getMonth()) as any
	}
}

interface DayProps {
	date: Date
	renderDate: (date: Date) => React.ReactNode
	onSelect?: (date: Date) => void
}

@observer
export class Day extends React.Component<DayProps> {

	render() {
		const { date, renderDate } = this.props
		const weekday = Utils.weekday(date)
		return <div onClick={this.navigate}
			className={css('day' + weekday,
				Utils.sameDate(today.get(), date) && 'today')}
			style={date.getDate() === 1 ? { gridColumnStart: weekday } : {}}>
			<h6>{date.getDate()}</h6>
			{renderDate(date)}
		</div>
	}

	navigate = () => {
		this.props.onSelect?.(this.props.date)
	}

}

const oneDayMillis = 1000 * 60 * 60 * 24

// observable today
const now = new Date(Date.now())
const today = observable.box(
	new Date(now.getFullYear(), now.getMonth(), now.getDate()))
setTimeout(() => {
	setInterval(() => { today.set(new Date(Date.now())) }, oneDayMillis)
}, now.getTime() - today.get().getTime())

const weekdayFormat = new Intl.DateTimeFormat('de-CH', { weekday: 'short' })
const weekdays = [...Array(7).keys()]
	.map(d => weekdayFormat.format((d + 4) * oneDayMillis))

const monthFormat = new Intl.DateTimeFormat('de-CH', { month: 'long' })
const months = [...Array(12).keys()]
	.map(m => monthFormat.format(m * oneDayMillis * 31))

function getMonth(date: Date) {
	if (date instanceof Date)
		return new Date(date.getFullYear(), date.getMonth(), 1)
}
