import { O } from '../../common'
import { newItemsFromText } from '../../data/import'
import * as mdl from '../../model'
import { newItemWithTmpl } from '../newItemWithTmpl'

export const setup = {

	addNewItem: ({ items, log }:
		{ items: mdl.ItemManager, log: mdl.SystemLogger }) => {
		O.onInit(mdl.AddItemView, view => {
			view.addNewItem.start.react(async (tmplId, file) => {
				try {
					if (!tmplId && file)
						tmplId = 'image.tmpl'
					else if (!tmplId && !view.searchText)
						tmplId = 'list.tmpl'
					if (tmplId) {
						const tmpl = await items.requireItem(tmplId)
						const newItem = newItemWithTmpl(items, tmpl,
							{ file, text: view.searchText })
						await view.item.links.addNew(newItem)
						view.addNewItem.end(newItem.id)
					} else {
						const newItems = newItemsFromText(view.searchText, items)
						await view.item.links.addNew(newItems)
						view.addNewItem.end(newItems.length === 1 ? newItems[0].id : null)
					}
				} catch (err) {
					log.error(err)
				}
			})
		})
	},

}
