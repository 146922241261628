import { reaction, when } from '../common'
import * as mdl from '../model'

export const setup = {

	complete: ({ inst, boxes }:
		{ inst: mdl.Installation, boxes: mdl.BoxManager }) => {
		when(() => inst.item && boxes.userBoxItem?.isReady,
			() => { inst.item.complete() })
	},

	fixProp: ({ app, inst }: { app: mdl.AppBoot, inst: mdl.Installation }) => {
		app.init.react(() => {
			reaction(() => inst.item?.isCompleted &&
				inst.item?.props.get('idGen')?.value,
				v => {
					if (inst.item && (v < inst.idGen || !v))
						inst.item.props.set('idGen', inst.idGen)
					else if (v && v > inst.idGen)
						inst.idGen = v
				})
		})
	},

}
